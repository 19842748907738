
import { Route, Routes, useLocation } from "react-router-dom";
import Home from './pages/Home';
import Invoices from './pages/Invoices';
import SelectPlan from './pages/SelectPlan';
import CategoryWriter from "./pages/CategoryWriter";
import BrandWriter from "./pages/BrandWriter";
import AppNavigation from "./components/AppNavigation";
import { useEffect, useState } from "react";
import QueuedJobs from "./pages/QueuedJobs";
import BlogWriter from "./pages/BlogWriter";
import SocialMediaWriter from "./pages/SocialMediaWriter";
import FAQWriter from "./pages/FAQWriter";
import BlogDetail from "./pages/BlogDetail";

const AppRoutes = ({ store }) => {
    const [appNavVisible, setAppNavVisible] = useState(true);
    const location = useLocation();
    useEffect(() => {
        setAppNavVisible(location.pathname === "/" || location.pathname.indexOf("-writer") >= 0);
    }, [location]);

    return (
        <>
            {appNavVisible &&
                <AppNavigation store={store}></AppNavigation>
            }
            <Routes>
                <Route exact path="/" element={<Home store={store} />} />
                <Route exact path="/description-writer" element={<Home store={store} />} />
                <Route exact path="/category-writer" element={<CategoryWriter store={store} />} />
                <Route exact path="/brand-writer" element={<BrandWriter store={store} />} />
                <Route exact path="/upgrade-plan" element={<SelectPlan />} />
                <Route exact path="/invoices" element={<Invoices />} />
                <Route exact path="/queued-jobs" element={<QueuedJobs store={store} />} />
                <Route exact path="/blog-writer" element={<BlogWriter store={store} />} />
                <Route exact path="/social-media-writer" element={<SocialMediaWriter store={store} />} />
                <Route exact path="/faq-writer" element={<FAQWriter store={store} />} />
                <Route exact path="/blog-post/:id" element={<BlogDetail store={store} />} />
            </Routes>
        </>

    );
}
export default AppRoutes
