import { Button, Fieldset, Flex, FlexItem, FormGroup, Link, Modal, Radio, Text } from "@bigcommerce/big-design";
import { useEffect, useState } from "react";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { Utils } from "../services/Utils";
import { ADDITIONAL_QUOTA_PRODUCTS } from "../common/Constants";
import { SharedState } from "../services/SharedState";

const Quota = (props) => {
    const [store, setStore] = useState(props.store);
    const [subscription, setSubscription] = useState();
    const [quota, setQuota] = useState();
    const [refuelDialogOpen, setRefuelDialogOpen] = useState(false);
    const [refuelProduct, setRefuelProduct] = useState("828588");
    const Paddle = window.Paddle;

    const navigate = useNavigate();

    useEffect(() => {
        SharedState.subscribe("store", (key, value) => {
            setStore(value);
        });
    }, []);

    useEffect(() => {
        const subscription = store.subscription;
        setSubscription(subscription);
        setQuota(store.quota);
    }, [store]);

    const handleUpgradeClicked = () => {
        navigate("/upgrade-plan");
    }

    const refuel = () => {
        let email = "";
        if (store.users && store.users.length > 0) {
            email = store.users[0].email;
        }

        Paddle.Checkout.open({
            product: refuelProduct,
            email: email,
            passthrough: store.storeHash,
            successCallback: (data) => {
                Utils.success("Your account will be refueled in a few seconds.")
            }
        });
    }

    return (
        <>
            {store && quota &&
                <>
                    {quota &&
                        <div>
                            <Flex alignItems={"center"} flexGap="8px">
                                <FlexItem>
                                    <Text margin="none">You have used <strong>{Math.floor(quota.used * 100 / quota.limit)}%</strong> of your {subscription.planId !== "FREE" ? "monthly" : "lifetime"} quota <strong>({quota.used}/{quota.limit})</strong>
                                    </Text>
                                    {store.jobCount > 0 &&
                                        <Text as="small"><Link onClick={() => navigate("/queued-jobs")}>{store.jobCount} queued jobs</Link>.</Text>
                                    }
                                </FlexItem>
                                {subscription && subscription.planId !== "FREE" && subscription.status === "active" &&
                                    <FlexItem>
                                        <Button onClick={() => {
                                            setRefuelDialogOpen(true);
                                        }}>Refuel</Button>
                                    </FlexItem>
                                }
                            </Flex>
                            {subscription && subscription.planId !== "FREE" && subscription.status !== "active" &&
                                <>
                                    {subscription.status === "past_due" &&
                                    <>
                                        <Text as="small">Your subscription payment is overdue. Please <Link href={subscription.updateUrl} target="_blank">update</Link> your payment method.</Text>
                                    </>
                                    }
                                    {subscription.status === "paused" &&
                                        <>
                                            <Text as="small">Your subscription is paused. Please <Link onClick={() => handleUpgradeClicked()} target="_blank">restart</Link> it.</Text>
                                        </>
                                    }
                                </>
                            }
                            {subscription && subscription.planId === "FREE" && quota.used >= quota.limit &&
                                <Text as="small">You have used all your lifetime quota, <Link onClick={handleUpgradeClicked}><strong>upgrade now</strong></Link> to continue optimizing.</Text>
                            }
                        </div>
                    }
                    <Modal
                        actions={[
                            { text: 'Cancel', variant: 'subtle', onClick: () => setRefuelDialogOpen(false) },
                            {
                                text: 'Continue', onClick: () => {
                                    setRefuelDialogOpen(false);
                                    refuel();
                                }
                            },
                        ]}
                        header="Refuel your quota"
                        isOpen={refuelDialogOpen}
                        onClose={() => setRefuelDialogOpen(false)}
                        closeOnEscKey={true}
                        closeOnClickOutside={false}
                    >
                        <>
                            {store.subscription.nextBillDate &&
                                <Text>
                                    By refueling you will get extra AI-Generation quota that will be available until your next billing cycle ({format(parse(store.subscription.nextBillDate, "yyyy-MM-dd", new Date()), "d MMMM, yyyy")}).
                                </Text>
                            }
                            {!store.subscription.nextBillDate &&
                                <Text>
                                    By refueling you will get extra AI-Generation quota that will be available until your next billing cycle.
                                </Text>
                            }
                            <Fieldset legend="Choose the pricing option that best suits your need">
                                <FormGroup>
                                    {ADDITIONAL_QUOTA_PRODUCTS.map(product =>
                                        <Radio
                                            key={product.id}
                                            checked={refuelProduct === product.id}
                                            label={product.name}
                                            onChange={() => {
                                                setRefuelProduct(product.id);
                                            }}
                                            value={product.id}
                                        />
                                    )}
                                </FormGroup>
                            </Fieldset>
                            <Text>You will be able to select the quantity in the next step</Text>
                        </>
                    </Modal>
                </>
            }
        </>
    )
}
export default Quota;
