import { Button, Checkbox, Form, FormGroup, Input, Modal, Panel, Select, Text } from "@bigcommerce/big-design";
import { useEffect, useRef, useState } from "react";
import { CheckIcon, CloseIcon, BaselineHelpIcon } from '@bigcommerce/big-design-icons';
import "./CommonSettings.css";
import { Api } from "../../services/Api";

const CommonSettings = ({ store }) => {
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    const originalSettings = useRef(null);

    const getSetting = (setting, defaultValue) => {
        const returnVal = store.settings && store.settings[setting] !== undefined ? store.settings[setting] : defaultValue;
        return returnVal;
    };

    const [includeStoreNameInMeta, setIncludeStoreNameInMeta] = useState(getSetting("includeStoreNameInMeta", false));
    const [storeName, setStoreName] = useState(getSetting("storeName", store && store.info ? store.info.name : ""));
    const [language, setLanguage] = useState(getSetting("language", "English"));
    const [descriptionLength, setDescriptionLength] = useState(getSetting("descriptionLength", 300));
    const [categoryDescriptionLength, setCategoryDescriptionLength] = useState(getSetting("categoryDescriptionLength", 300));
    const [metaDescriptionLength, setMetaDescriptionLength] = useState(getSetting("metaDescriptionLength", 25));
    const [toneOfVoice, setToneOfVoice] = useState(getSetting("toneOfVoice", ""));
    const [keepOriginalOnTop, setKeepOriginalOnTop] = useState(getSetting("keepOriginalOnTop", false));
    const [keepOriginalAtBottom, setKeepOriginalAtBottom] = useState(getSetting("keepOriginalAtBottom", false));


    useEffect(() => {
        originalSettings.current = getCurrentSettings();
    }, []);

    const getCurrentSettings = () => {
        return {
            includeStoreNameInMeta: includeStoreNameInMeta,
            storeName: storeName,
            language: language,
            descriptionLength: descriptionLength,
            categoryDescriptionLength: categoryDescriptionLength,
            metaDescriptionLength: metaDescriptionLength,
            toneOfVoice: toneOfVoice,
            keepOriginalOnTop: keepOriginalOnTop,
            keepOriginalAtBottom: keepOriginalAtBottom
        };
    }

    useEffect(() => {
        setChanged(
            originalSettings.current.includeStoreNameInMeta !== includeStoreNameInMeta ||
            originalSettings.current.storeName !== storeName ||
            originalSettings.current.language !== language ||
            originalSettings.current.descriptionLength !== descriptionLength ||
            originalSettings.current.categoryDescriptionLength !== categoryDescriptionLength ||
            originalSettings.current.metaDescriptionLength !== metaDescriptionLength ||
            originalSettings.current.toneOfVoice !== toneOfVoice ||
            originalSettings.current.keepOriginalOnTop !== keepOriginalOnTop ||
            originalSettings.current.keepOriginalAtBottom !== keepOriginalAtBottom
        );
    }, [
        includeStoreNameInMeta,
        storeName,
        language,
        descriptionLength,
        categoryDescriptionLength,
        metaDescriptionLength,
        toneOfVoice,
        keepOriginalOnTop,
        keepOriginalAtBottom
    ]);

    const handleIncludeStoreNameInMetaChange = async (e) => {
        const checked = !includeStoreNameInMeta;
        setIncludeStoreNameInMeta(checked);
    }

    const canSubmit = () => {
        if (storeName) {
            return true;
        }
        else {
            return false;
        }
    }

    const submitChanges = async () => {
        setUpdating(true);
        try {
            await Api.post("/storeApi-updateCommonSettings", {
                includeStoreNameInMeta: includeStoreNameInMeta,
                storeName: storeName,
                language: language,
                descriptionLength: descriptionLength,
                categoryDescriptionLength: categoryDescriptionLength,
                metaDescriptionLength: metaDescriptionLength,
                toneOfVoice: toneOfVoice,
                keepOriginalOnTop: keepOriginalOnTop,
                keepOriginalAtBottom: keepOriginalAtBottom
            });
            originalSettings.current = getCurrentSettings();
            setChanged(false);
        }
        finally {
            setUpdating(false);
        }
    }

    const cancelChanges = async () => {
        // rollback changes
        setIncludeStoreNameInMeta(originalSettings.current.includeStoreNameInMeta);
        setStoreName(originalSettings.current.storeName);
        setLanguage(originalSettings.current.language);
        setDescriptionLength(originalSettings.current.descriptionLength);
        setCategoryDescriptionLength(originalSettings.current.categoryDescriptionLength);
        setMetaDescriptionLength(originalSettings.current.metaDescriptionLength);
        setToneOfVoice(originalSettings.current.toneOfVoice);
        setKeepOriginalOnTop(originalSettings.current.keepOriginalOnTop);
        setKeepOriginalAtBottom(originalSettings.current.keepOriginalAtBottom);
        setChanged(false);
    }

    return (
        <Panel
            header="Common Settings"
            action={
                {
                    variant: 'subtle',
                    iconOnly: <BaselineHelpIcon color="secondary"></BaselineHelpIcon>,
                    onClick: () => {
                        setHelpDialogOpen(true);
                    },
                }
            }
        >
            <div className="product-settings">
                <Form>
                    <FormGroup>
                        <Checkbox checked={includeStoreNameInMeta} onChange={(e) => handleIncludeStoreNameInMetaChange(e)} label="Include Store Name in Meta Descriptions" />
                    </FormGroup>
                    <div className="indented">
                        <FormGroup>
                            <Input disabled={!includeStoreNameInMeta} required type="text" onChange={(e) => setStoreName(e.target.value)} label="Store Name" value={storeName}></Input>
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Select
                            required
                            label="Content Language"
                            onOptionChange={(val) => setLanguage(val)}
                            value={language}
                            options={
                                [
                                    { value: "English", content: "English" },
                                    { value: "French", content: "French" },
                                    { value: "German", content: "German" },
                                    { value: "Italian", content: "Italian" },
                                    { value: "Portuguese", content: "Portuguese" },
                                    { value: "Spanish", content: "Spanish" },
                                ]
                            }
                        ></Select>
                    </FormGroup>
                    <FormGroup>
                        <Input label="Product Description Length (words)" value={descriptionLength} onChange={(e) => setDescriptionLength(e.target.value)} required type="text"></Input>
                    </FormGroup>
                    <FormGroup>
                        <Input label="Meta Description Length (words)" value={metaDescriptionLength} onChange={(e) => setMetaDescriptionLength(e.target.value)} required type="text"></Input>
                    </FormGroup>
                    <FormGroup>
                        <Input label="Category Description Length (words)" value={categoryDescriptionLength} onChange={(e) => setCategoryDescriptionLength(e.target.value)} required type="text"></Input>
                    </FormGroup>
                    <FormGroup>
                        <Input label="Tone of Voice" value={toneOfVoice} onChange={(e) => setToneOfVoice(e.target.value)} required type="text" description="You can leave this empty if you are not sure." placeholder="Engaging, Friendly, Witty, etc."></Input>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            checked={keepOriginalOnTop}
                            onChange={(e) => {
                                if (!keepOriginalOnTop) {
                                    setKeepOriginalAtBottom(false);
                                }
                                setKeepOriginalOnTop(!keepOriginalOnTop);
                            }
                            }
                            label="Keep Original Description At The Top" />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            checked={keepOriginalAtBottom}
                            onChange={(e) => {
                                if (!keepOriginalAtBottom) {
                                    setKeepOriginalOnTop(false);
                                }
                                setKeepOriginalAtBottom(!keepOriginalAtBottom);
                            }}
                            label="Keep Original Description At The Bottom" />
                    </FormGroup>
                    <div className="buttons">
                        <Button onClick={cancelChanges} disabled={!changed || updating} type="button" actionType="destructive" variant="secondary" iconRight={<CloseIcon></CloseIcon>}>Cancel</Button>
                        <Button isLoading={updating} onClick={submitChanges} disabled={!changed} type="button" variant="secondary" iconRight={<CheckIcon></CheckIcon>}>Save</Button>
                    </div>
                </Form>
            </div>
            <Modal
                actions={[
                    { text: 'Ok', variant: 'subtle', onClick: () => setHelpDialogOpen(false) }
                ]}
                header="Help"
                isOpen={helpDialogOpen}
                onClose={() => setHelpDialogOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <>
                    <Text>
                        <strong>Include Store Name in Meta Descriptions:</strong> Turn this setting on if you want AI to include your store name in the generated meta descriptions.
                    </Text>
                    <Text>
                        <strong>Store Name:</strong> You can edit the store name using this field. The setting will only affect AI content generation, it won't update your BigCommerce store settings.
                    </Text>
                    <Text>
                        <strong>Product Description Length:</strong> Recommended length of AI-generated text for product descriptions. The input is a recommendation rather than a constraint. AI may not follow the setting exactly
                    </Text>
                    <Text>
                        <strong>category Description Length:</strong> Recommended length of AI-generated text for category descriptions. The input is a recommendation rather than a constraint. AI may not follow the setting exactly
                    </Text>
                    <Text>
                        <strong>Meta Description Length:</strong> Recommended length of AI-generated text for meta descriptions. The input is a recommendation rather than a constraint. AI may not follow the setting exactly
                    </Text>
                    <Text>
                        <strong>Keep Original Description On Top:</strong> If checked, the app will append AI-generated text to the original description. Otherwise, the original text will be overwritten.
                    </Text>
                </>
            </Modal>
        </Panel>
    )
}
export default CommonSettings;
