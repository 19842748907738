import { Button, Dropdown, Flex, FlexItem, Text } from "@bigcommerce/big-design";
import { ArrowDropDownIcon } from '@bigcommerce/big-design-icons';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AVAILABLE_PLANS } from "../common/Constants";
import { SharedState } from "../services/SharedState";

const HeaderMenu = (props) => {
    const [store, setStore] = useState(props.store)
    const [currentPlan, setCurrentPlan] = useState(null);
    const [subscription, setSubscription] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        SharedState.subscribe("store", (key, value) => {
            setStore(value);
        });
    }, []);

    useEffect(() => {
        const subscription = store.subscription;
        const plan = AVAILABLE_PLANS.find(p => p.id === subscription.planId);
        setSubscription(subscription);
        setCurrentPlan(plan);
    }, [store]);

    const handleUpgradeClicked = () => {
        navigate("/upgrade-plan");
    }
    return (
        <>
            {store && currentPlan &&
                <Flex flexGap={"8px"}>
                    <FlexItem paddingRight="medium">
                        <Text as="span" bold={true} marginRight="medium">{currentPlan.name} Plan</Text><Button onClick={() => handleUpgradeClicked()}>Upgrade</Button>
                    </FlexItem>
                    {subscription && subscription.planId !== "FREE" &&
                        <FlexItem borderLeft="box">
                            <Dropdown
                                items={[
                                    {
                                        content: 'Update Payment Info',
                                        type: "link",
                                        url: subscription.updateUrl,
                                        target: "_blank"
                                    },
                                    {
                                        content: 'View Invoices',
                                        onItemClick: (item) => navigate("/invoices")
                                    },
                                ]}
                                toggle={<Button variant="subtle" iconRight={<ArrowDropDownIcon />}>{store.info.name}</Button>}
                            />
                        </FlexItem>
                    }
                </Flex>
            }
        </>
    )
}
export default HeaderMenu;
