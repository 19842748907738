import { Button, Flex, FlexItem, Link, Modal, Text } from "@bigcommerce/big-design";
import { DeleteIcon } from '@bigcommerce/big-design-icons';
import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../services/Api";
import ComponentLoading from "../ComponentLoading";
import MemoizedTable from "../MemoizedTable";
import "./SocialMediaPosts.css";
import CreateSocialMediaPost from "./CreateSocialMediaPost";
import SocialMediaPost from "./SocialMediaPost";

const SocialMediaPosts = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const [selectedSocialMediaPost, setSelectedSocialMediaPost] = useState(null);
    const [createSocialMediaPost, setCreateSocialMediaPost] = useState(false);
    const pageFirstElements = useRef([]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(false);
    const [dialogText, setDialogText] = useState(false);
    const [dialogOkFn, setDialogOkFn] = useState();

    const getData = async (page) => {
        try {
            let startAt = null;
            let startAfter = null;

            if (page === 1) {
                // loading first page
                pageFirstElements.current = [];
            }
            if (page > currentPage) {
                // going forward
                pageFirstElements.current.push(items[0].docId);
                startAfter = items[items.length - 1].docId;
            }
            else if (page < currentPage) {
                // going backwards
                startAt = pageFirstElements.current.pop();
            }

            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);
            const data = await Api.get("/socialMediaApi-getAIGeneratedSocialMediaPosts", {
                startAt: startAt,
                startAfter: startAfter,
                pageSize: pageSize
            });
            if (data.socialMediaPosts) {
                setItems(data.socialMediaPosts);
                setItemsTimestamp(new Date().getTime());
                if (pagingEnabled()) {
                    if (data.socialMediaPosts.length === pageSize) {
                        setTotalItems(totalItems + (page + 1) * pageSize);
                    }
                    else {
                        setTotalItems(totalItems + data.socialMediaPosts.length);
                    }
                }
                else {
                    // paging is disabled when search input is submitted
                    setTotalItems(data.socialMediaPosts.length);
                }
                setCurrentPage(page);
            }
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getData(1);
    }, [pageSize]);

    const pagingEnabled = () => {
        return true;
    }

    const renderBody = (socialMediaPost) => {
        return (
            <>
                <div className="post-body">
                    <Link ellipsis={true} onClick={() => setSelectedSocialMediaPost(socialMediaPost)}>{socialMediaPost.body}</Link>
                </div>
            </>
        )
    }

    const renderCreated = (socialMediaPost) => {
        const created = new Date(socialMediaPost.created._seconds * 1000)
        return created.toLocaleString();
    }

    const deleteSocialMediaPost = async (post, confirmed) => {
        if (!confirmed) {
            setDialogTitle("Delete?");
            setDialogText("Are you sure you want to delete the post?");
            setIsDialogOpen(true);
            setDialogOkFn({
                fn: () => {
                    deleteSocialMediaPost(post, true);
                }
            });
        }
        else {
            setLoading(true);
            const data = await Api.delete("/socialMediaApi-deleteSocialMediaPost", {
                id: post.docId
            });
            getData(currentPage);
        }
    }

    const renderActions = (socialMediaPost) => {
        return (
            <>
                <div className="post-actions">
                    <Button actionType="destructive" iconOnly={<DeleteIcon title="delete" />} onClick={() => deleteSocialMediaPost(socialMediaPost, false)}></Button>
                    {/* <Link ellipsis={true} onClick={() => setSelectedSocialMediaPost(socialMediaPost)}>{socialMediaPost.body}</Link> */}
                </div>
            </>
        )
    }

    useEffect(() => {
        if (selectedSocialMediaPost !== null) {
            const socialMediaDetailElement = document.querySelector(".social-media-post-detail");
            socialMediaDetailElement.parentElement.parentElement.style.maxWidth = "1024px";
        }
    }, [selectedSocialMediaPost]);

    return (
        <>
            <div className="social-media-posts">
                <Flex marginBottom="medium" justifyContent="end">
                    <FlexItem>
                        <Button onClick={() => setCreateSocialMediaPost(true)}>Create New Social Media Post</Button>
                    </FlexItem>
                </Flex>
                <MemoizedTable
                    columns={[
                        { header: '', hash: 'body', width: "500px", render: (socialMediaPost) => renderBody(socialMediaPost) },
                        { header: 'Created', hash: 'created', width: "100px", render: (socialMediaPost) => renderCreated(socialMediaPost) },
                        { header: '', hash: 'actiıns', width: "200px", render: (socialMediaPost) => renderActions(socialMediaPost) }
                    ]}
                    items={items}
                    timestamp={itemsTimestamp}
                    pagination={{
                        itemsPerPageOptions: [20, 50, 100],
                        currentPage: currentPage,
                        itemsPerPage: pageSize,
                        totalItems: totalItems,
                        onPageChange: (page) => getData(page),
                        onItemsPerPageChange: (range) => { setPageSize(range); },
                        getRangeLabel: (start, end, totalItems) => { return pageSize }

                    }}
                    stickyHeader
                    loading={loading}
                />
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedSocialMediaPost}
                onClose={() => setSelectedSocialMediaPost(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <div className="social-media-post-detail">
                    <SocialMediaPost
                        socialMediaPost={selectedSocialMediaPost}
                        onClose={() => setSelectedSocialMediaPost(null)}
                        onChange={() => getData(1)}
                    ></SocialMediaPost>
                </div>
            </Modal>
            <Modal
                isOpen={createSocialMediaPost}
                onClose={() => setCreateSocialMediaPost(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
                header="Create Social Media Post"
            >
                <CreateSocialMediaPost onCreated={() => getData(1)} onClose={() => setCreateSocialMediaPost(false)}></CreateSocialMediaPost>
            </Modal>
            <Modal
                actions={[
                    { text: 'Cancel', variant: 'subtle', onClick: () => setIsDialogOpen(false) },
                    { text: 'Apply', onClick: () => { setIsDialogOpen(false); dialogOkFn.fn() } },
                ]}
                header={dialogTitle}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={false}
            >
                <Text>
                    {dialogText}
                </Text>
            </Modal>
        </>
    );
}
export default SocialMediaPosts;
