import { Badge, Button, Checkbox, Fieldset, Flex, FlexItem, Form, FormGroup, H2, H3, Input, Radio, Stepper, Text, Textarea } from "@bigcommerce/big-design";
import { useEffect, useState } from "react";
import ComponentLoading from "../ComponentLoading";
import { Api } from "../../services/Api";
import "./CreateSocialMediaPost.css";
import SocialMediaPost from "./SocialMediaPost";
import ProductSelect from "../ProductSelect";

const CreateSocialMediaPost = (props) => {
    const steps = ['Start', 'Select Topic', 'Save & Publish'];
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [theme, setTheme] = useState("product-highlight");
    const [promotionDescription, setPromotionDescription] = useState("");
    const [tipMode, setTipMode] = useState("ai-random");
    const [tipDescription, setTipDescription] = useState("");
    const [greetingDescription, setGreetingDescription] = useState("");
    const [selectedProduct, setSelectedProduct] = useState();
    const [socialMediaPost, setSocialMediaPost] = useState();
    const [editingSocialMediaPost, setEditingSocialMediaPost] = useState(false);

    useEffect(() => {
        if (currentStep === 2) {
            setSocialMediaPost(null);
            createSocialMediaPost();
        }
    }, [currentStep])

    const createSocialMediaPost = async () => {
        try {
            setLoading(true);
            const data = await Api.post("/socialMediaAiApi-createSocialMediaPost", {
                theme: theme,
                promotionDescription: promotionDescription,
                greetingDescription: greetingDescription,
                tipDescription: tipDescription,
                tipMode: tipMode,
                productId: selectedProduct ? selectedProduct.id : null,
            });
            setSocialMediaPost(data);
            if (props.onCreated) {
                props.onCreated();
            }
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className="create-social-media-post">
            <Stepper currentStep={currentStep} steps={steps} />
            {currentStep === 0 &&
                <Form>
                    <Fieldset legend="Select a theme">
                        <FormGroup>
                            <Radio
                                checked={theme === "product-highlight"}
                                label="Product Highlight"
                                onChange={(e) => setTheme(e.target.value)}
                                value="product-highlight"
                            />
                            <Radio
                                checked={theme === "discount-promotion"}
                                label="Discount/Promotion"
                                onChange={(e) => setTheme(e.target.value)}
                                value="discount-promotion"
                            />
                            <Radio
                                checked={theme === "greeting-celebration"}
                                label="Greeting/Celebration"
                                onChange={(e) => setTheme(e.target.value)}
                                value="greeting-celebration"
                            />
                            <Radio
                                checked={theme === "tip-knowledge"}
                                label="Tip/Knowledge"
                                onChange={(e) => setTheme(e.target.value)}
                                value="tip-knowledge"
                            />
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 1 && theme === "product-highlight" &&
                <Form>
                    <Fieldset legend="Select a product">
                        <FormGroup>
                            <ProductSelect
                                onChange={(val) => setSelectedProduct(val)}>
                            </ProductSelect>
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="subtle" onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                            <Button disabled={!selectedProduct} variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 1 && theme === "discount-promotion" &&
                <Form>
                    <Fieldset legend="Briefly describe the promotion">
                        <FormGroup>
                            <div className="title-input">
                                <Textarea placeholder="e.g. 20% off on all products this weekend" value={promotionDescription} onChange={(e) => setPromotionDescription(e.target.value)}></Textarea>
                            </div>
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="subtle" onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                            <Button disabled={!promotionDescription} variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 1 && theme === "greeting-celebration" &&
                <Form>
                    <Fieldset legend="Name of the event to greet/celebrate">
                        <FormGroup>
                            <div className="title-input">
                                <Input placeholder="e.g. Thanksgiving, New Year etc." value={greetingDescription} onChange={(e) => setGreetingDescription(e.target.value)}></Input>
                            </div>
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="subtle" onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                            <Button disabled={!greetingDescription} variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 1 && theme === "tip-knowledge" &&
                <Form>
                    <Fieldset legend="Tip/Knowledge Topic">
                        <FormGroup>
                            <Radio
                                checked={tipMode === "ai-random"}
                                label="Let AI decide the topic"
                                onChange={(e) => setTipMode(e.target.value)}
                                value="ai-random"
                            />
                            <Radio
                                checked={tipMode === "manual-entry"}
                                label="No, thanks. I'll manually enter the topic"
                                onChange={(e) => setTipMode(e.target.value)}
                                value="manual-entry"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Textarea disabled={tipMode !== "manual-entry"} placeholder="Briefly enter the topic." value={tipDescription} onChange={(e) => setTipDescription(e.target.value)}></Textarea>
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="subtle" onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                            <Button disabled={tipMode === "manual-entry" && !tipDescription} variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 2 &&
                <Form>
                    {!socialMediaPost && loading &&
                        <>
                            <ComponentLoading title="AI is creating your social media post, the post will be visible in the list once it is ready. You can close the dialog or wait for the result."></ComponentLoading>
                        </>
                    }
                    {socialMediaPost &&
                        <SocialMediaPost
                            socialMediaPost={socialMediaPost}
                            onEditingChange={(value) => setEditingSocialMediaPost(value)}
                            onChange={(value) => {
                                setSocialMediaPost(value);
                                if (props.onCreated) {
                                    props.onCreated();
                                }
                            }}
                        ></SocialMediaPost>
                    }
                </Form>
            }
        </div>
    )
}
export default CreateSocialMediaPost;
