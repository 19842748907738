import { Input, Button, Flex, FlexItem} from "@bigcommerce/big-design";
import { SearchIcon } from '@bigcommerce/big-design-icons';
import { useEffect, useState } from "react";
import "./SearchForm.css";

const SearchForm = (props) => {
    const [keyword, setKeyword] = useState("");

    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(keyword);
        }
    }

    return (
        <div className="search-form">
            <Flex>
                <FlexItem flexGrow={1} marginRight="small">
                    <Input onChange={(e) => setKeyword(e.target.value)} iconLeft={<SearchIcon color="secondary"></SearchIcon>}></Input>
                </FlexItem>
                <Button type="button" variant="secondary" onClick={onSubmit}>Search</Button>
            </Flex>
        </div>
    )
}

export default SearchForm;
