import { Badge, Box, Button, Link, Modal, Small } from "@bigcommerce/big-design";
import React, { useEffect, useState, useRef } from "react";
import { Api } from "../../services/Api";
import { SharedState } from "../../services/SharedState";
import ComponentLoading from "../ComponentLoading";
import MemoizedTable from "../MemoizedTable";
import FAQDetail from "./FAQDetail";
import "./Products.css";
import ProductSearchForm from "../description-writer/product/ProductSearchForm";
import SafeImg from "../SafeImg";

const ProductsWithFAQ = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [categories, setCategories] = useState(SharedState.getValue("categories"));
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchCategories, setSearchCategories] = useState([]);
    const [searchBrand, setSearchBrand] = useState();
    const [searchTimestamp, setSearchTimestamp] = useState(0);
    const pageFirstElements = useRef([]);

    const getData = async (page) => {
        try {
            let startAt = null;
            let startAfter = null;

            if (page === 1) {
                // loading first page
                pageFirstElements.current = [];
            }
            if (page > currentPage) {
                // going forward
                pageFirstElements.current.push(items[0].faq.docId);
                startAfter = items[items.length - 1].faq.docId;
            }
            else if (page < currentPage) {
                // going backwards
                startAt = pageFirstElements.current.pop();
            }

            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);
            const data = await Api.get("/faqApi-getProductsWithFAQ", {
                keyword: searchKeyword,
                categories: searchCategories.join(","),
                brand: searchBrand,
                startAt: startAt,
                startAfter: startAfter,
                pageSize: pageSize
            });
            if (data.products) {
                setItems(data.products);
                setItemsTimestamp(new Date().getTime());
                if (pagingEnabled()) {
                    if (data.products.length === pageSize) {
                        setTotalItems(totalItems + (page + 1) * pageSize);
                    }
                    else {
                        setTotalItems(totalItems + data.products.length);
                    }
                }
                else {
                    // paging is disabled when search input is submitted
                    setTotalItems(data.products.length);
                }
                setCurrentPage(page);
            }
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
        SharedState.subscribe("categories", (key, value) => {
            if (key === "categories") {
                setCategories(value);
            }
        });
    }, [searchCategories, searchBrand, searchKeyword, searchTimestamp]);

    useEffect(() => {
        if (selectedProduct !== null) {
            const productDetailElement = document.querySelector(".faq-detail");
            productDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedProduct])

    const onSearch = (keyword, categories, brand) => {
        setSearchKeyword(keyword);
        setSearchBrand(brand);
        setSearchCategories(categories);
        setSearchTimestamp(new Date().getTime());
    };

    const renderProductImage = (product) => {
        try {
            const thumb = product.images.find(i => i.is_thumbnail === true);
            return <SafeImg className="thumbnail" src={thumb.url_tiny} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const renderStatus = (product) => {
        let variant = "secondary";
        let status = "not-generated";
        const faqData = product.faq;
        if (faqData) {
            if (faqData.status === "published") {
                variant = "success";
            }
            else if (faqData.status === "not-published") {
                variant = "secondary";
            }
            else if (faqData.status === "outdated") {
                variant = "warning";
            }
            status = faqData.status;
        }

        return (
            <>
                <Badge label={status} variant={variant}></Badge>
            </>
        );
    }

    const pagingEnabled = () => {
        if (searchKeyword || searchCategories.length > 0 || searchBrand) {
            return false;
        }

        return true;
    }

    const renderName = (product) => {
        const productCategories = categories ? categories.filter(c => product.categories.indexOf(c.id) >= 0).map(c => c.name) : [];
        return (
            <>
                <div className="product-name">
                    <Link ellipsis={true} onClick={() => setSelectedProduct(product)}>{product.name}</Link>
                </div>
                <div className="product-name">
                    <Small ellipsis={true}>{productCategories.join('/')}</Small>
                </div>
            </>
        )
    }



    return (
        <>
            <div className="products">
                <Box marginBottom="medium">
                    <ProductSearchForm onSubmit={onSearch}></ProductSearchForm>
                </Box>
                {itemsTimestamp &&
                    <MemoizedTable
                        columns={[
                            { header: '', hash: 'thumbnail', width: "100px", render: (product) => renderProductImage(product) },
                            { header: 'Name', hash: 'name', width: "500px", render: (product) => renderName(product) },
                            { header: 'Q&A', width: "100px", hash: 'faq', render: (product) => renderStatus(product) },
                        ]}
                        items={items}
                        timestamp={itemsTimestamp}
                        pagination={pagingEnabled() && {
                            itemsPerPageOptions: [10, 20, 50],
                            currentPage: currentPage,
                            itemsPerPage: pageSize,
                            totalItems: totalItems,
                            onPageChange: (page) => getData(page),
                            onItemsPerPageChange: (range) => {
                                setPageSize(range);
                                setTimeout(() => {
                                    getData(currentPage);
                                }, 500);
                            },
                            getRangeLabel: (start, end, totalItems) => { return pageSize }

                        }}
                        stickyHeader
                        loading={loading}
                    />
                }
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedProduct}
                onClose={() => setSelectedProduct(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <FAQDetail product={selectedProduct}></FAQDetail>
            </Modal>
        </>
    );
}
export default ProductsWithFAQ;
