import React, { useEffect, useState } from 'react';
import { ArrowBackIcon } from '@bigcommerce/big-design-icons';
import { H1, Panel, Text, Table, H2, H3 } from '@bigcommerce/big-design';
import { Link as RouterLink } from 'react-router-dom';
import PageLoading from '../components/PageLoading';
import { Api } from '../services/Api';

const QueuedJobs = (props) => {

    const [jobs, setJobs] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const contentGenerationJobs = ["createDescriptions", "createCategoryDescriptions", "createBrandDescriptions"];

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const data = await Api.get("/jobsApi-getQueuedJobs");
                setJobs(data.data);
                setCount(data.count);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    const renderJobType = (retry) => {
        if (retry.name === "createDescriptions") {
            return "Generate Product Content";
        }
        else if (retry.name === "createCategoryDescriptions") {
            return "Generate Category Content";
        }
        else if (retry.name === "createBrandDescriptions") {
            return "Generate Brand Content";
        }
        else if (retry.name === "createFAQs") {
            return "Generate FAQs";
        }
        else {
            return "System Automation Job"
        }
    }

    const renderDetails = (retry) => {
        const params = [];
        if (contentGenerationJobs.indexOf(retry.name) >= 0){
            for (const key in retry.params) {
                let val = retry.params[key];
                if (val === true) {
                    val = "yes";
                }
                else if (val === false) {
                    val = "no";
                }
                params.push(key + ": " + val);
            }
        }
        return (
            <>
                {params.map(p =>
                    <>
                        {p}<br />
                    </>
                )}
            </>
        )
    }

    const renderCreated = (retry) => {
        const created = new Date(retry.created._seconds * 1000)
        return created.toLocaleString();
    }

    return (
        <>
            {loading &&
                <PageLoading />
            }
            {!loading &&
                <>
                    <RouterLink to="/" className="no-underline"><Text color="secondary60"><ArrowBackIcon color="secondary60" /> Home</Text></RouterLink>
                    <H1>Queued Jobs</H1>
                    <H2>Total: {count}</H2>
                    {jobs.length < count &&
                        <H3>Viewing top {jobs.length} jobs</H3>
                    }
                    <Panel>
                        <Table
                            selectable={false
                            }
                            columns={[
                                { width: '100px', header: 'Job Type', hash: 'jobType', render: renderJobType },
                                { width: '100px', header: 'Details', hash: 'details', render: renderDetails },
                                { width: '100px', header: 'Created', hash: 'created', render: renderCreated },
                            ]}
                            items={jobs}
                        />
                    </Panel>
                </>
            }
        </>
    );
}
export default QueuedJobs;


