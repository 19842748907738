import { Api } from "./Api";
import { SharedState } from "./SharedState";

export class StoreClient {
    static getStore = async () => {
        const store = await Api.get("/storeApi-getStore");
        SharedState.setValue("store", store);
        return store;
    }

    static getStorefronts = async () => {
        const storefronts = await Api.get("/storeApi-getStorefronts");
        return storefronts;
    }
}
