import { Button, Checkbox, Form, FormGroup, Modal, Panel, Switch, Text } from "@bigcommerce/big-design";
import { useEffect, useRef, useState } from "react";
import { Api } from "../../../services/Api";
import { CheckIcon, CloseIcon, BaselineHelpIcon } from '@bigcommerce/big-design-icons';
import "./CategorySettings.css";

const CategorySettings = ({ store }) => {
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    const originalSettings = useRef(null);

    const getSetting = (setting, defaultValue) => {
        const returnVal = store.settings && store.settings.categoryWriter && store.settings.categoryWriter[setting] !== undefined ? store.settings.categoryWriter[setting] : defaultValue;
        return returnVal;
    };

    const [autoPilot, setAutoPilot] = useState(getSetting("autoPilot", false));
    // const [automatedCategoryDescriptions, setAutomatedCategoryDescriptions] = useState(getSetting("automatedCategoryDescriptions", false));
    const [generateCategoryDescriptionIfExistingIsEmpty, setGenerateCategoryDescriptionIfExistingIsEmpty] = useState(getSetting("generateCategoryDescriptionIfExistingIsEmpty", true));
    const [generateCategoryDescriptionIfExistingIsTooShort, setGenerateCategoryDescriptionIfExistingIsTooShort] = useState(getSetting("generateCategoryDescriptionIfExistingIsTooShort", true));
    const [overwriteExistingCategoryDescriptions, setOverwriteExistingCategoryDescriptions] = useState(getSetting("overwriteExistingCategoryDescriptions", false));

    // const [automatedMetaDescriptions, setAutomatedMetaDescriptions] = useState(getSetting("automatedMetaDescriptions", false));
    const [generateMetaDescriptionIfExistingIsEmpty, setGenerateMetaDescriptionIfExistingIsEmpty] = useState(getSetting("generateMetaDescriptionIfExistingIsEmpty", true));
    const [generateMetaDescriptionIfExistingIsTooShort, setGenerateMetaDescriptionIfExistingIsTooShort] = useState(getSetting("generateMetaDescriptionIfExistingIsTooShort", true));
    const [overwriteExistingMetaDescriptions, setOverwriteExistingMetaDescriptions] = useState(getSetting("overwriteExistingMetaDescriptions", false));

    // const [automatedSearchKeywords, setAutomatedSearchKeywords] = useState(getSetting("automatedSearchKeywords", false));
    const [generateSearchKeywordsIfExistingIsEmpty, setGenerateSearchKeywordsIfExistingIsEmpty] = useState(getSetting("generateSearchKeywordsIfExistingIsEmpty", true));
    const [generateSearchKeywordsIfExistingIsTooShort, setGenerateSearchKeywordsIfExistingIsTooShort] = useState(getSetting("generateSearchKeywordsIfExistingIsTooShort", true));
    const [overwriteExistingSearchKeywords, setOverwriteExistingSearchKeywords] = useState(getSetting("overwriteExistingSearchKeywords", false));

    useEffect(() => {
        originalSettings.current = getCurrentSettings();
    }, []);

    const getCurrentSettings = () => {
        return {
            autoPilot: autoPilot,
            // automatedCategoryDescriptions: automatedCategoryDescriptions,
            generateCategoryDescriptionIfExistingIsEmpty: generateCategoryDescriptionIfExistingIsEmpty,
            generateCategoryDescriptionIfExistingIsTooShort: generateCategoryDescriptionIfExistingIsTooShort,
            overwriteExistingCategoryDescriptions: overwriteExistingCategoryDescriptions,
            // automatedMetaDescriptions: automatedMetaDescriptions,
            generateMetaDescriptionIfExistingIsEmpty: generateMetaDescriptionIfExistingIsEmpty,
            generateMetaDescriptionIfExistingIsTooShort: generateMetaDescriptionIfExistingIsTooShort,
            overwriteExistingMetaDescriptions: overwriteExistingMetaDescriptions,
            // automatedSearchKeywords: automatedSearchKeywords,
            generateSearchKeywordsIfExistingIsEmpty: generateSearchKeywordsIfExistingIsEmpty,
            generateSearchKeywordsIfExistingIsTooShort: generateSearchKeywordsIfExistingIsTooShort,
            overwriteExistingSearchKeywords: overwriteExistingSearchKeywords,
        };
    }

    useEffect(() => {
        setChanged(
            originalSettings.current.autoPilot !== autoPilot ||
            // originalSettings.current.automatedCategoryDescriptions !== automatedCategoryDescriptions ||
            originalSettings.current.generateCategoryDescriptionIfExistingIsEmpty !== generateCategoryDescriptionIfExistingIsEmpty ||
            originalSettings.current.generateCategoryDescriptionIfExistingIsTooShort !== generateCategoryDescriptionIfExistingIsTooShort ||
            originalSettings.current.overwriteExistingCategoryDescriptions !== overwriteExistingCategoryDescriptions ||
            // originalSettings.current.automatedMetaDescriptions !== automatedMetaDescriptions ||
            originalSettings.current.generateMetaDescriptionIfExistingIsEmpty !== generateMetaDescriptionIfExistingIsEmpty ||
            originalSettings.current.generateMetaDescriptionIfExistingIsTooShort !== generateMetaDescriptionIfExistingIsTooShort ||
            originalSettings.current.overwriteExistingMetaDescriptions !== overwriteExistingMetaDescriptions ||
            // originalSettings.current.automatedSearchKeywords !== automatedSearchKeywords ||
            originalSettings.current.generateSearchKeywordsIfExistingIsEmpty !== generateSearchKeywordsIfExistingIsEmpty ||
            originalSettings.current.generateSearchKeywordsIfExistingIsTooShort !== generateSearchKeywordsIfExistingIsTooShort ||
            originalSettings.current.overwriteExistingSearchKeywords !== overwriteExistingSearchKeywords

        );
    }, [
        autoPilot,
        // automatedCategoryDescriptions,
        generateCategoryDescriptionIfExistingIsEmpty,
        generateCategoryDescriptionIfExistingIsTooShort,
        overwriteExistingCategoryDescriptions,
        // automatedMetaDescriptions,
        generateMetaDescriptionIfExistingIsEmpty,
        generateMetaDescriptionIfExistingIsTooShort,
        overwriteExistingMetaDescriptions,
        // automatedSearchKeywords,
        generateSearchKeywordsIfExistingIsEmpty,
        generateSearchKeywordsIfExistingIsTooShort,
        overwriteExistingSearchKeywords,
    ]);

    const handleAutoPilotChange = async (e) => {
        const checked = !autoPilot;
        setAutoPilot(checked);
    }

    // const handleAutomatedCategoryDescriptionsChange = async (e) => {
    //     const checked = !automatedCategoryDescriptions;
    //     setAutomatedCategoryDescriptions(checked);
    // }

    const handleGenerateCategoryDescriptionIfExistingIsEmptyChange = async (e) => {
        const checked = !generateCategoryDescriptionIfExistingIsEmpty;
        setGenerateCategoryDescriptionIfExistingIsEmpty(checked);
    }

    const handleGenerateCategoryDescriptionIfExistingIsTooShortChange = async (e) => {
        const checked = !generateCategoryDescriptionIfExistingIsTooShort;
        setGenerateCategoryDescriptionIfExistingIsTooShort(checked);
    }

    const handleGenerateCategoryDescriptionAlwaysChange = async (e) => {
        const checked = !overwriteExistingCategoryDescriptions;
        setOverwriteExistingCategoryDescriptions(checked);
    }

    // const handleAutomatedMetaDescriptionsChange = async (e) => {
    //     const checked = !automatedMetaDescriptions;
    //     setAutomatedMetaDescriptions(checked);
    // }

    const handleGenerateMetaDescriptionIfExistingIsEmptyChange = async (e) => {
        const checked = !generateMetaDescriptionIfExistingIsEmpty;
        setGenerateMetaDescriptionIfExistingIsEmpty(checked);
    }

    const handleGenerateMetaDescriptionIfExistingIsTooShortChange = async (e) => {
        const checked = !generateMetaDescriptionIfExistingIsTooShort;
        setGenerateMetaDescriptionIfExistingIsTooShort(checked);
    }

    const handleGenerateMetaDescriptionAlwaysChange = async (e) => {
        const checked = !overwriteExistingMetaDescriptions;
        setOverwriteExistingMetaDescriptions(checked);
    }

    // const handleAutomatedSearchKeywordsChange = async (e) => {
    //     const checked = !automatedSearchKeywords;
    //     setAutomatedSearchKeywords(checked);
    // }

    const handleGenerateSearchKeywordsIfExistingIsEmptyChange = async (e) => {
        const checked = !generateSearchKeywordsIfExistingIsEmpty;
        setGenerateSearchKeywordsIfExistingIsEmpty(checked);
    }

    const handleGenerateSearchKeywordsIfExistingIsTooShortChange = async (e) => {
        const checked = !generateSearchKeywordsIfExistingIsTooShort;
        setGenerateSearchKeywordsIfExistingIsTooShort(checked);
    }

    const handleGenerateSearchKeywordsAlwaysChange = async (e) => {
        const checked = !overwriteExistingSearchKeywords;
        setOverwriteExistingSearchKeywords(checked);
    }

    const submitChanges = async () => {
        setUpdating(true);
        try {
            await Api.post("/storeApi-updateCategoryWriterSettings", {
                autoPilot: autoPilot,
                // automatedCategoryDescriptions: automatedCategoryDescriptions,
                generateCategoryDescriptionIfExistingIsEmpty: generateCategoryDescriptionIfExistingIsEmpty,
                generateCategoryDescriptionIfExistingIsTooShort: generateCategoryDescriptionIfExistingIsTooShort,
                overwriteExistingCategoryDescriptions: overwriteExistingCategoryDescriptions,
                // automatedMetaDescriptions: automatedMetaDescriptions,
                generateMetaDescriptionIfExistingIsEmpty: generateMetaDescriptionIfExistingIsEmpty,
                generateMetaDescriptionIfExistingIsTooShort: generateMetaDescriptionIfExistingIsTooShort,
                overwriteExistingMetaDescriptions: overwriteExistingMetaDescriptions,
                // automatedSearchKeywords: automatedSearchKeywords,
                generateSearchKeywordsIfExistingIsEmpty: generateSearchKeywordsIfExistingIsEmpty,
                generateSearchKeywordsIfExistingIsTooShort: generateSearchKeywordsIfExistingIsTooShort,
                overwriteExistingSearchKeywords: overwriteExistingSearchKeywords
            });
            originalSettings.current = getCurrentSettings();
            setChanged(false);
        }
        finally {
            setUpdating(false);
        }
    }

    const cancelChanges = async () => {
        // rollback changes
        setAutoPilot(originalSettings.current.autoPilot);
        // setAutomatedCategoryDescriptions(originalSettings.current.automatedCategoryDescriptions);
        setGenerateCategoryDescriptionIfExistingIsEmpty(originalSettings.current.generateCategoryDescriptionIfExistingIsEmpty);
        setGenerateCategoryDescriptionIfExistingIsTooShort(originalSettings.current.generateCategoryDescriptionIfExistingIsTooShort);
        setOverwriteExistingCategoryDescriptions(originalSettings.current.overwriteExistingCategoryDescriptions);

        // setAutomatedMetaDescriptions(originalSettings.current.automatedMetaDescriptions);
        setGenerateMetaDescriptionIfExistingIsEmpty(originalSettings.current.generateMetaDescriptionIfExistingIsEmpty);
        setGenerateMetaDescriptionIfExistingIsTooShort(originalSettings.current.generateMetaDescriptionIfExistingIsTooShort);
        setOverwriteExistingMetaDescriptions(originalSettings.current.overwriteExistingMetaDescriptions);

        // setAutomatedSearchKeywords(originalSettings.current.automatedSearchKeywords);
        setGenerateSearchKeywordsIfExistingIsEmpty(originalSettings.current.generateSearchKeywordsIfExistingIsEmpty);
        setGenerateSearchKeywordsIfExistingIsTooShort(originalSettings.current.generateSearchKeywordsIfExistingIsTooShort);
        setOverwriteExistingSearchKeywords(originalSettings.current.overwriteExistingSearchKeywords);

        setChanged(false);
    }

    return (
        <Panel
            header="Category Writer Auto-Pilot Settings"
            action={
                {
                    variant: 'subtle',
                    iconOnly: <BaselineHelpIcon color="secondary"></BaselineHelpIcon>,
                    onClick: () => {
                        setHelpDialogOpen(true);
                    },
                }
            }
        >
            <div className="category-settings">
                <Form>
                    <FormGroup>
                        <div className="chk-group">
                            <Switch checked={autoPilot} onChange={(e) => handleAutoPilotChange(e)} />
                            <Text>Auto-Pilot</Text>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="group-label chk-group">
                            {/* <Switch checked={automatedCategoryDescriptions} onChange={(e) => handleAutomatedCategoryDescriptionsChange(e)} /> */}
                            <Text><strong>Category Descriptions</strong></Text>
                        </div>
                    </FormGroup>
                    <div className="indented">
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateCategoryDescriptionIfExistingIsEmpty} onChange={(e) => handleGenerateCategoryDescriptionIfExistingIsEmptyChange()} label="Generate description if empty" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateCategoryDescriptionIfExistingIsTooShort} onChange={(e) => handleGenerateCategoryDescriptionIfExistingIsTooShortChange()} label="Generate description if too short" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={overwriteExistingCategoryDescriptions} onChange={(e) => handleGenerateCategoryDescriptionAlwaysChange()} label="Overwrite existing descriptions" />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <div className="group-label chk-group">
                            {/* <Switch checked={automatedMetaDescriptions} onChange={(e) => handleAutomatedMetaDescriptionsChange(e)} /> */}
                            <Text><strong>Meta Descriptions</strong></Text>
                        </div>
                    </FormGroup>
                    <div className="indented">
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateMetaDescriptionIfExistingIsEmpty} onChange={(e) => handleGenerateMetaDescriptionIfExistingIsEmptyChange()} label="Generate meta description if empty" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateMetaDescriptionIfExistingIsTooShort} onChange={(e) => handleGenerateMetaDescriptionIfExistingIsTooShortChange()} label="Generate meta description if too short" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={overwriteExistingMetaDescriptions} onChange={(e) => handleGenerateMetaDescriptionAlwaysChange()} label="Overwrite existing meta descriptions" />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <div className="group-label chk-group">
                            {/* <Switch checked={automatedSearchKeywords} onChange={(e) => handleAutomatedSearchKeywordsChange(e)} /> */}
                            <Text><strong>Search Keywords</strong></Text>
                        </div>
                    </FormGroup>
                    <div className="indented">
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateSearchKeywordsIfExistingIsEmpty} onChange={(e) => handleGenerateSearchKeywordsIfExistingIsEmptyChange()} label="Generate search keywords if empty" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateSearchKeywordsIfExistingIsTooShort} onChange={(e) => handleGenerateSearchKeywordsIfExistingIsTooShortChange()} label="Generate search keywords if too short" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={overwriteExistingSearchKeywords} onChange={(e) => handleGenerateSearchKeywordsAlwaysChange()} label="Overwrite existing search keywords" />
                        </FormGroup>
                    </div>
                    <div className="buttons">
                        <Button onClick={cancelChanges} disabled={!changed || updating} type="button" actionType="destructive" variant="secondary" iconRight={<CloseIcon></CloseIcon>}>Cancel</Button>
                        <Button isLoading={updating} onClick={submitChanges} disabled={!changed} type="button" variant="secondary" iconRight={<CheckIcon></CheckIcon>}>Save</Button>
                    </div>
                </Form>
            </div>
            <Modal
                actions={[
                    { text: 'Ok', variant: 'subtle', onClick: () => setHelpDialogOpen(false) }
                ]}
                header="Help"
                isOpen={helpDialogOpen}
                onClose={() => setHelpDialogOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <>
                    <Text>If you switch the <strong>Auto-Pilot</strong> on, the app will automatically select categories and start generating content based on your <strong>Auto-Pilot settings</strong>.</Text>
                    <Text>The alternative to Auto-Pilot is  <strong>"manual selection"</strong>:
                        <ul>
                            <li>You can manually trigger batch content generation by selecting multiple categories and then clicking <strong>"Batch Generate Content"</strong> button.</li>
                            <li>You can also click on a single category name to open a dialog that gives you more <i>granular control</i> over content generation.</li>
                        </ul>
                    </Text>
                </>
            </Modal>
        </Panel>
    )
}
export default CategorySettings;
