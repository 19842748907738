import { Badge, Box, Button, Checkbox, Flex, FlexItem, Form, Link, Modal, Small, Text } from "@bigcommerce/big-design";
import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../services/Api";
import { SharedState } from "../../../services/SharedState";
import ComponentLoading from "../../ComponentLoading";
import MemoizedTable from "../../MemoizedTable";
import CategortDetail from "./BrandDetail";
import "./Brands.css";
import SafeImg from "../../SafeImg";
import SearchForm from "../../SearchForm";
import { StoreClient } from "../../../services/StoreClient";

const Brands = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    //const [allBrands, setAllBrands] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [selectedBrand, setSelectedBrand] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchTimestamp, setSearchTimestamp] = useState(0);

    const [isBatchGenerateModalOpen, setIsBatchGenerateModalOpen] = useState(false);
    const [batchGenerateMetaDescriptions, setBatchGenerateMetaDescriptions] = useState(false);
    const [batchGenerateSearchKeywords, setBatchGenerateSearchKeywords] = useState(false);
    const [isGeneratingBatchDescriptions, setIsGeneratingBatchDescriptions] = useState(false);

    const brandDetailModal = useRef();

    const getData = async (page) => {
        try {
            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);
            const allBrands = await getAllBrands();
            const listItems = [];
            const offset = (page - 1) * pageSize;
            let brandsFiltered = allBrands;
            if (searchKeyword) {
                brandsFiltered = brandsFiltered.filter((c) => {
                    return (c.name).toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
                });
            }
            const data = brandsFiltered.slice(offset, offset + pageSize);
            data.forEach(p => {
                listItems.push({
                    ...p,
                    checked: false
                });
            });

            setItems(listItems);
            setItemsTimestamp(new Date().getTime());
            setTotalItems(brandsFiltered.length);
            setCurrentPage(page);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
    }, [searchKeyword, searchTimestamp, pageSize]);

    // useEffect(() => {
    //     const brands = SharedState.getValue("brands");
    //     if (brands) {
    //         prepareBrands(brands);
    //     }
    //     SharedState.subscribe("brands", (key, value) => {
    //         if (key === "brands") {
    //             prepareBrands(value);
    //         }
    //     });
    // }, []);

    const getBrands = async (page) => {
        let brandsInDb = [];
        const data = await Api.get("/brandApi-getBrands", { page: page, pageSize: 250 });
        if (data && data.brands) {
            brandsInDb = [...data.brands];
        }

        if (data && data.hasNextPage) {
            brandsInDb = [...brandsInDb, ...(await getBrands(page + 1))];
        }

        return brandsInDb;
    }

    const getAllBrands = async () => {
        const allBrands = await getBrands(1);
        return allBrands;
    }

    const onSearch = (keyword) => {
        setSearchKeyword(keyword);
        setSearchTimestamp(new Date().getTime());
    };

    const renderBrandImage = (brand) => {
        try {
            return <SafeImg className="thumbnail" src={brand.image_url} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const renderName = (brand) => {
        return (
            <>
                <div className="brand-name">
                    <Link ellipsis={true} onClick={() => setSelectedBrand(brand)}>{brand.name}</Link>
                </div>
                <div className="brand-name">
                    <Small ellipsis={true}>{brand.path}</Small>
                </div>
            </>
        )
    }

    useEffect(() => {
        if (selectedBrand !== null) {
            const brandDetailElement = document.querySelector(".brand-detail");
            brandDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedBrand]);

    const renderContentStatus = (field, brand) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = brand.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // brandDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "metaDescription") {
            if (!brand.meta_description) {
                quality = "empty"
            }
            else if (brand.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!brand.search_keywords) {
                quality = "empty"
            }
            else if (brand.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    const batchGenerateDescriptions = async () => {
        setIsGeneratingBatchDescriptions(true);
        try {
            const ids = selectedItems.map((i) => {
                return i.id;
            });
            await Api.post("/brandAiApi-batchGenerateDescriptions", {
                brandIds: ids,
                createMetaDescriptions: batchGenerateMetaDescriptions,
                createSearchKeywords: batchGenerateSearchKeywords
            });
            setIsBatchGenerateModalOpen(false);
            StoreClient.getStore();
        }
        finally {
            setIsGeneratingBatchDescriptions(false);
        }
    }

    return (
        <>
            <div className="brands">
                <Box marginBottom="medium">
                    <SearchForm onSubmit={onSearch}></SearchForm>
                </Box>
                <Flex marginBottom="medium" justifyContent="end">
                    <FlexItem>
                        <Button onClick={() => setIsBatchGenerateModalOpen(true)} disabled={selectedItems.length === 0}>Batch Generate Content</Button>
                    </FlexItem>
                </Flex>
                {itemsTimestamp &&
                    <MemoizedTable
                        columns={[
                            { header: '', hash: 'thumbnail', width: "100px", render: (brand) => renderBrandImage(brand) },
                            { header: 'Name', hash: 'name', width: "500px", render: (brand) => renderName(brand) },
                            { header: 'Meta Description', width: "100px", hash: 'metaDescription', render: (brand) => renderContentStatus("metaDescription", brand) },
                            { header: 'Search Keywords', width: "100px", hash: 'searchKeywords', render: (brand) => renderContentStatus("searchKeywords", brand) },
                        ]}
                        onSelectionChange={(e) => {
                            setSelectedItems(e);
                        }}
                        items={items}
                        timestamp={itemsTimestamp}
                        pagination={{
                            itemsPerPageOptions: [20, 50, 100],
                            currentPage: currentPage,
                            itemsPerPage: pageSize,
                            totalItems: totalItems,
                            onPageChange: (page) => getData(page),
                            onItemsPerPageChange: (range) => { setPageSize(range); },
                            getRangeLabel: (start, end, totalItems) => { return pageSize }

                        }}
                        stickyHeader
                        loading={loading}
                    />
                }
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedBrand}
                onClose={() => setSelectedBrand(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <CategortDetail brand={selectedBrand}></CategortDetail>
            </Modal>
            <Modal
                isOpen={isBatchGenerateModalOpen}
                onClose={() => setIsBatchGenerateModalOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
                header="Batch Generate Content"
                actions={[
                    {
                        text: 'Cancel',
                        variant: 'subtle',
                        onClick: () => setIsBatchGenerateModalOpen(false),
                    },
                    {
                        isLoading: isGeneratingBatchDescriptions,
                        text: 'Apply',
                        onClick: batchGenerateDescriptions
                    },
                ]}
            >
                <Text>You selected <strong>{selectedItems.length} brands</strong></Text>
                <Form margin="large">
                    <Checkbox
                        label="Generate Meta Descriptions"
                        checked={batchGenerateMetaDescriptions}
                        onChange={() => setBatchGenerateMetaDescriptions(!batchGenerateMetaDescriptions)}
                    ></Checkbox>
                    <Checkbox
                        label="Generate Search Keywords"
                        checked={batchGenerateSearchKeywords}
                        onChange={() => setBatchGenerateSearchKeywords(!batchGenerateSearchKeywords)}
                    ></Checkbox>
                </Form>
            </Modal>
        </>
    );
}
export default Brands;
