import { Badge, Box, Button, Checkbox, Flex, FlexItem, Form, Link, Modal, Small, Text } from "@bigcommerce/big-design";
import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../services/Api";
import { SharedState } from "../../../services/SharedState";
import ComponentLoading from "../../ComponentLoading";
import MemoizedTable from "../../MemoizedTable";
import CategortDetail from "./CategoryDetail";
import "./Categories.css";
import SafeImg from "../../SafeImg";
import { CategoryHelper } from "../../../services/CategoryHelper";
import SearchForm from "../../SearchForm";
import { StoreClient } from "../../../services/StoreClient";

const Categories = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    //const [allCategories, setAllCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchTimestamp, setSearchTimestamp] = useState(0);

    const [isBatchGenerateModalOpen, setIsBatchGenerateModalOpen] = useState(false);
    const [batchGenerateMetaDescriptions, setBatchGenerateMetaDescriptions] = useState(false);
    const [batchGenerateCategoryDescriptions, setBatchGenerateCategoryDescriptions] = useState(false);
    const [batchGenerateSearchKeywords, setBatchGenerateSearchKeywords] = useState(false);
    const [isGeneratingBatchDescriptions, setIsGeneratingBatchDescriptions] = useState(false);

    const categoryDetailModal = useRef();

    const getData = async (page) => {
        try {
            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);
            const allCategories = await getAllCategories();
            const listItems = [];
            const offset = (page - 1) * pageSize;
            let categoriesFiltered = allCategories;
            if (searchKeyword) {
                categoriesFiltered = categoriesFiltered.filter((c) => {
                    return (c.name + " " + c.path).toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
                });
            }
            const data = categoriesFiltered.slice(offset, offset + pageSize);
            data.forEach(p => {
                listItems.push({
                    ...p,
                    checked: false
                });
            });

            setItems(listItems);
            setItemsTimestamp(new Date().getTime());
            setTotalItems(categoriesFiltered.length);
            setCurrentPage(page);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
    }, [searchKeyword, searchTimestamp, pageSize]);

    // useEffect(() => {
    //     const categories = SharedState.getValue("categories");
    //     if (categories) {
    //         prepareCategories(categories);
    //     }
    //     SharedState.subscribe("categories", (key, value) => {
    //         if (key === "categories") {
    //             prepareCategories(value);
    //         }
    //     });
    // }, []);

    const getCategories = async (page) => {
        let categoriesInDb = [];
        const data = await Api.get("/categoryApi-getCategories", { page: page, pageSize: 250 });
        if (data && data.categories) {
            categoriesInDb = [...data.categories];
        }

        if (data && data.hasNextPage) {
            categoriesInDb = [...categoriesInDb, ...(await getCategories(page + 1))];
        }

        return categoriesInDb;
    }

    const getAllCategories = async () => {
        const categoriesRaw = await getCategories(1);
        const categoriesFlatenned = CategoryHelper.prepareCategories(categoriesRaw);
        return categoriesFlatenned;
    }

    const onSearch = (keyword) => {
        setSearchKeyword(keyword);
        setSearchTimestamp(new Date().getTime());
    };

    const renderCategoryImage = (category) => {
        try {
            return <SafeImg className="thumbnail" src={category.image_url} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const renderName = (category) => {
        return (
            <>
                <div className="category-name">
                    <Link ellipsis={true} onClick={() => setSelectedCategory(category)}>{category.name}</Link>
                </div>
                <div className="category-name">
                    <Small ellipsis={true}>{category.path}</Small>
                </div>
            </>
        )
    }

    useEffect(() => {
        if (selectedCategory !== null) {
            const categoryDetailElement = document.querySelector(".category-detail");
            categoryDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedCategory]);

    const renderContentStatus = (field, category) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = category.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // categoryDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "categoryDescription") {
            if (!category.description) {
                quality = "empty"
            }
            else if (category.description.split(' ').length < 50) {
                quality = "too short";
            }
        }
        else if (field === "metaDescription") {
            if (!category.meta_description) {
                quality = "empty"
            }
            else if (category.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!category.search_keywords) {
                quality = "empty"
            }
            else if (category.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    const batchGenerateDescriptions = async () => {
        setIsGeneratingBatchDescriptions(true);
        try {
            const ids = selectedItems.map((i) => {
                return i.id;
            });
            await Api.post("/categoryAiApi-batchGenerateDescriptions", {
                categoryIds: ids,
                createMetaDescriptions: batchGenerateMetaDescriptions,
                createCategoryDescriptions: batchGenerateCategoryDescriptions,
                createSearchKeywords: batchGenerateSearchKeywords
            });
            setIsBatchGenerateModalOpen(false);
            StoreClient.getStore();
        }
        finally {
            setIsGeneratingBatchDescriptions(false);
        }
    }

    return (
        <>
            <div className="categories">
                <Box marginBottom="medium">
                    <SearchForm onSubmit={onSearch}></SearchForm>
                </Box>
                <Flex marginBottom="medium" justifyContent="end">
                    <FlexItem>
                        <Button onClick={() => setIsBatchGenerateModalOpen(true)} disabled={selectedItems.length === 0}>Batch Generate Content</Button>
                    </FlexItem>
                </Flex>
                {itemsTimestamp &&
                    <MemoizedTable
                        columns={[
                            { header: '', hash: 'thumbnail', width: "100px", render: (category) => renderCategoryImage(category) },
                            { header: 'Name', hash: 'name', width: "500px", render: (category) => renderName(category) },
                            { header: 'Category Description', width: "100px", hash: 'categoryDescription', render: (category) => renderContentStatus("categoryDescription", category) },
                            { header: 'Meta Description', width: "100px", hash: 'metaDescription', render: (category) => renderContentStatus("metaDescription", category) },
                            { header: 'Search Keywords', width: "100px", hash: 'searchKeywords', render: (category) => renderContentStatus("searchKeywords", category) },
                        ]}
                        onSelectionChange={(e) => {
                            setSelectedItems(e);
                        }}
                        items={items}
                        timestamp={itemsTimestamp}
                        pagination={{
                            itemsPerPageOptions: [50, 100, 250, 500],
                            currentPage: currentPage,
                            itemsPerPage: pageSize,
                            totalItems: totalItems,
                            onPageChange: (page) => getData(page),
                            onItemsPerPageChange: (range) => { setPageSize(range); },
                            getRangeLabel: (start, end, totalItems) => { return pageSize }

                        }}
                        stickyHeader
                        loading={loading}
                    />
                }
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedCategory}
                onClose={() => setSelectedCategory(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <CategortDetail category={selectedCategory}></CategortDetail>
            </Modal>
            <Modal
                isOpen={isBatchGenerateModalOpen}
                onClose={() => setIsBatchGenerateModalOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
                header="Batch Generate Content"
                actions={[
                    {
                        text: 'Cancel',
                        variant: 'subtle',
                        onClick: () => setIsBatchGenerateModalOpen(false),
                    },
                    {
                        isLoading: isGeneratingBatchDescriptions,
                        text: 'Apply',
                        onClick: batchGenerateDescriptions
                    },
                ]}
            >
                <Text>You selected <strong>{selectedItems.length} categories</strong></Text>
                <Form margin="large">
                    <Checkbox
                        label="Generate Meta Descriptions"
                        checked={batchGenerateMetaDescriptions}
                        onChange={() => setBatchGenerateMetaDescriptions(!batchGenerateMetaDescriptions)}
                    ></Checkbox>
                    <Checkbox
                        label="Generate Category Descriptions"
                        checked={batchGenerateCategoryDescriptions}
                        onChange={() => setBatchGenerateCategoryDescriptions(!batchGenerateCategoryDescriptions)}
                    ></Checkbox>
                    <Checkbox
                        label="Generate Search Keywords"
                        checked={batchGenerateSearchKeywords}
                        onChange={() => setBatchGenerateSearchKeywords(!batchGenerateSearchKeywords)}
                    ></Checkbox>
                </Form>
            </Modal>
        </>
    );
}
export default Categories;
