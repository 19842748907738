import { Flex, FlexItem, Input, Small, Text } from "@bigcommerce/big-design";
import { useEffect, useRef, useState } from "react";
import ReactAutocomplete from "react-autocomplete";
import { Api } from "../services/Api";
import SafeImg from "./SafeImg";
import "./ProductSelect.css";
import ComponentLoading from "./ComponentLoading";

const ProductSelect = (props) => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [selectedItem, setSelectedItem] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const throttleTimeout = useRef();

    useEffect(() => {
        if (searchKeyword.length >= 4) {
            if (throttleTimeout.current) {
                clearTimeout(throttleTimeout.current);
            };
            throttleTimeout.current = setTimeout(() => {
                getData()
            }, 500);
        }
        else if (searchKeyword.length < 4) {
            setItems([]);
        }
    }, [searchKeyword]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(selectedProduct);
        }
    }, [selectedProduct])

    const getData = async () => {
        try {
            setLoading(true);
            setItems([]);
            const data = await Api.get("/catalogApi-getProducts", { keyword: searchKeyword, categories: "", page: 1, pageSize: 250 });
            setItems(data.products);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className="product-select">
            <ReactAutocomplete
                getItemValue={(item) => item.name}
                items={loading ? [1] : items}
                renderInput={(props) => <Input
                    {...props}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                    placeholder="Search by product name or SKU..."
                ></Input>}
                shouldItemRender={(item, value) => {
                    if (loading) {
                        return true;
                    }
                    else {
                        if (item) {
                            return item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1;
                        }
                        else {
                            return false;
                        }
                    }
                }}
                renderItem={(item, isHighlighted) => {
                    if (!loading) {
                        const thumb = item.images.find(i => i.is_thumbnail === true);
                        return (
                            <div className="menu-item">
                                <Flex flexGap="8px" borderBottom="box">
                                    <FlexItem marginLeft="small" marginRight="small">
                                        <SafeImg className="thumbnail" src={thumb.url_tiny}></SafeImg>
                                    </FlexItem>
                                    <FlexItem alignSelf="center">
                                        <Text>{item.name}</Text>
                                        <Small>{item.sku}</Small>
                                    </FlexItem>
                                </Flex>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div style={{ fontSize: "initial" }}>
                                <ComponentLoading></ComponentLoading>
                            </div>
                        )
                    }
                }
                }
                value={selectedItem}
                onSelect={(val, item) => {                    
                    setSelectedItem(item.name);
                    setSearchKeyword(item.name);
                    setSelectedProduct(item);
                }
                }
            />
        </div>
    )
}
export default ProductSelect;
