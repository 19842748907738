import React, { useState, useEffect, useRef } from 'react';
import { Badge, Box, Button, Flex, FlexItem, H2, H4, Panel, Small, Table, Tabs, Textarea } from '@bigcommerce/big-design';
import { Api } from '../../../services/Api';
import ComponentLoading from '../../ComponentLoading';
import SafeImg from '../../SafeImg';
import "./BrandDetail.css";

const BrandDetail = (props) => {

    const [brand, setBrand] = useState();
    const [loading, setLoading] = useState(true);
    const [disableButtons, setDisableButtons] = useState(false);

    const [generatingMetaDescription, setGeneratingMetaDescription] = useState(false);
    const [publishingMetaDescription, setPublishingMetaDescription] = useState(false);
    const [revertingMetaDescription, setRevertingMetaDescription] = useState(false);
    const [editingMetaDescriptionAIContent, setEditingMetaDescriptionAIContent] = useState(false);
    const [editingMetaDescriptionLiveContent, setEditingMetaDescriptionLiveContent] = useState(false);
    const [updatingMetaDescriptionAIContent, setUpdatingMetaDescriptionAIContent] = useState(false);
    const [updatingMetaDescriptionLiveContent, setUpdatingMetaDescriptionLiveContent] = useState(false);

    const [metaDescriptionAIContentTempVal, setMetaDescriptionAIContentTempVal] = useState("");
    const [metaDescriptionLiveContentTempVal, setMetaDescriptionLiveContentTempVal] = useState("");

    const [generatingSearchKeywords, setGeneratingSearchKeywords] = useState(false);
    const [publishingSearchKeywords, setPublishingSearchKeywords] = useState(false);
    const [revertingSearchKeywords, setRevertingSearchKeywords] = useState(false);
    const [editingSearchKeywordsAIContent, setEditingSearchKeywordsAIContent] = useState(false);
    const [editingSearchKeywordsLiveContent, setEditingSearchKeywordsLiveContent] = useState(false);
    const [updatingSearchKeywordsAIContent, setUpdatingSearchKeywordsAIContent] = useState(false);
    const [updatingSearchKeywordsLiveContent, setUpdatingSearchKeywordsLiveContent] = useState(false);

    const [searchKeywordsAIContentTempVal, setSearchKeywordsAIContentTempVal] = useState("");
    const [searchKeywordsLiveContentTempVal, setSearchKeywordsLiveContentTempVal] = useState("");

    const [activeTab, setActiveTab] = useState("tab-meta-description");

    useEffect(() => {
        const getBrand = async () => {
            try {
                setLoading(true);
                const data = await Api.get(`/brandApi-getBrand?id=${props.brand.id}`);
                setBrand(data);
            }
            finally {
                setLoading(false);
            }
        }
        getBrand();
    }, []);

    const renderContentStatus = (field) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = brand.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // brandDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "metaDescription") {
            if (!brand.meta_description) {
                quality = "empty"
            }
            else if (brand.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!brand.search_keywords) {
                quality = "empty"
            }
            else if (brand.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    const renderAIContentStatus = (field) => {
        let status = "not generated";
        let statusVariant = "secondary"
        let publishStatus = null;
        let publishStatusVariant = null;
        const appData = brand.appData;
        if (appData) {
            const statusPropertyName = field + "AIContent";
            if (appData[statusPropertyName]) {
                status = "generated";
                statusVariant = "success";
            }

            const publishedPropertyName = field + "AIContentPublishStatus";  // brandDescriptionAIContentPublishStatus, metaDescriptionAIContentPublishStatus
            if (appData[publishedPropertyName]) {
                publishStatus = appData[publishedPropertyName];
                if (publishStatus === "published") {
                    publishStatusVariant = "success";
                }
                else if (publishStatus === "not-published") {
                    publishStatusVariant = "warning";
                }
            }
        }
        return (
            <>
                <Badge label={status} variant={statusVariant}></Badge>
                <br />
                {publishStatus &&
                    <Badge label={publishStatus} variant={publishStatusVariant}></Badge>
                }
            </>
        )
    }    

    const generateMetaDescription = async () => {
        try {
            setGeneratingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/brandAiApi-generateMetaDescription`, { brandId: brand.id });
            brand.appData = data;
            setBrand(brand);
        }
        finally {
            setDisableButtons(false);
            setGeneratingMetaDescription(false);
        }
    }

    const publishMetaDescription = async () => {
        try {
            setPublishingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/brandApi-publishAIMetaDescription`, { brandId: brand.id });
            setBrand(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingMetaDescription(false);
        }
    }

    const revertMetaDescription = async () => {
        try {
            setRevertingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/brandApi-revertAIMetaDescription`, { brandId: brand.id });
            setBrand(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingMetaDescription(false);
        }
    }

    const updateMetaDescriptionAIContent = async () => {
        try {
            setUpdatingMetaDescriptionAIContent(true);
            setDisableButtons(true);
            const description = metaDescriptionAIContentTempVal;
            await Api.post(`/brandApi-updateAIMetaDescription`, { brandId: brand.id, description: description });
            brand.appData.metaDescriptionAIContent = description;
            setBrand(brand);
            setEditingMetaDescriptionAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingMetaDescriptionAIContent(false);
        }
    }

    const updateMetaDescriptionLiveContent = async () => {
        try {
            setUpdatingMetaDescriptionLiveContent(true);
            setDisableButtons(true);
            const description = metaDescriptionLiveContentTempVal;
            await Api.post(`/brandApi-updateMetaDescription`, { brandId: brand.id, description: description });
            brand.meta_description = description;
            setBrand(brand);
            setEditingMetaDescriptionLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingMetaDescriptionLiveContent(false);
        }
    }


    const generateSearchKeywords = async () => {
        try {
            setGeneratingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/brandAiApi-generateSearchKeywords`, { brandId: brand.id });
            brand.appData = data;
            setBrand(brand);
        }
        finally {
            setDisableButtons(false);
            setGeneratingSearchKeywords(false);
        }
    }

    const publishSearchKeywords = async () => {
        try {
            setPublishingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/brandApi-publishAISearchKeywords`, { brandId: brand.id });
            setBrand(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingSearchKeywords(false);
        }
    }

    const revertSearchKeywords = async () => {
        try {
            setRevertingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/brandApi-revertAISearchKeywords`, { brandId: brand.id });
            setBrand(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingSearchKeywords(false);
        }
    }

    const updateSearchKeywordsAIContent = async () => {
        try {
            setUpdatingSearchKeywordsAIContent(true);
            setDisableButtons(true);
            const searchKeywords = searchKeywordsAIContentTempVal;
            await Api.post(`/brandApi-updateAISearchKeywords`, { brandId: brand.id, searchKeywords: searchKeywords });
            brand.appData.searchKeywordsAIContent = searchKeywords;
            setBrand(brand);
            setEditingSearchKeywordsAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingSearchKeywordsAIContent(false);
        }
    }

    const updateSearchKeywordsLiveContent = async () => {
        try {
            setUpdatingSearchKeywordsLiveContent(true);
            setDisableButtons(true);
            const searchKeywords = searchKeywordsLiveContentTempVal;
            await Api.post(`/brandApi-updateSearchKeywords`, { brandId: brand.id, searchKeywords: searchKeywords });
            brand.search_keywords = searchKeywords;
            setBrand(brand);
            setEditingSearchKeywordsLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingSearchKeywordsLiveContent(false);
        }
    }

    return (
        <div className="brand-detail">
            {!loading &&
                <>
                    <Box marginTop="medium" display="flex">
                        <Flex flexGap={"24px"}>
                            <FlexItem>
                                <div className="img-container">
                                    <SafeImg src={brand.image_url} />
                                </div>
                            </FlexItem>
                            <FlexItem>
                                <H2 marginBottom="medium">{brand.name}</H2>
                                <Box marginTop="large">
                                    <Table
                                        id="content-status"
                                        columns={[
                                            { header: '', hash: 'item', render: ({ name }) => name },
                                            { header: 'Live Content', hash: 'status', render: ({ field }) => renderContentStatus(field) },
                                            { header: 'AI Content', hash: 'name', render: ({ field }) => renderAIContentStatus(field) },
                                        ]}
                                        items={[
                                            { field: "metaDescription", name: "Meta Description" },
                                            { field: "searchKeywords", name: "Search Keywords" },
                                        ]}                                     
                                    >
                                    </Table>
                                </Box>
                            </FlexItem>
                        </Flex>
                    </Box>
                    <Box marginTop="medium">
                        <Tabs
                            activeTab={activeTab}
                            aria-label="Brand Tab Content"
                            id="tab"
                            items={[
                                { id: "tab-meta-description", ariaControls: "meta-description", title: "Meta Description" },
                                { id: "tab-search-keywords", ariaControls: "search-keywords", title: "Search Keywords" },
                            ]}
                            onTabClick={setActiveTab}
                        />
                        {activeTab === "tab-meta-description" &&
                            <Flex id="meta-description">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {brand.meta_description &&
                                            <>
                                                {!editingMetaDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">{brand.meta_description}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingMetaDescriptionLiveContent(true);
                                                                setMetaDescriptionLiveContentTempVal(brand.meta_description);
                                                            }
                                                            }>Edit</Button>
                                                    </>
                                                }
                                                {editingMetaDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={metaDescriptionLiveContentTempVal}
                                                                onChange={(e) => setMetaDescriptionLiveContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingMetaDescriptionLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingMetaDescriptionLiveContent}
                                                            onClick={updateMetaDescriptionLiveContent}>Apply</Button>
                                                    </>
                                                }
                                                {!editingMetaDescriptionLiveContent && brand.appData && brand.appData.metaDescriptionAIContentPublishStatus === "published" &&
                                                    <Button
                                                        actionType="destructive"
                                                        type="button"
                                                        isLoading={revertingMetaDescription}
                                                        disabled={disableButtons}
                                                        onClick={revertMetaDescription}>Revert</Button>
                                                }
                                            </>
                                        }
                                        {!brand.meta_description &&
                                            <div className="description center">N/A</div>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {brand.appData && brand.appData.metaDescriptionAIContent &&
                                            <>
                                                {!editingMetaDescriptionAIContent &&
                                                    <>
                                                        <div className="description">{brand.appData.metaDescriptionAIContent}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingMetaDescriptionAIContent(true);
                                                                setMetaDescriptionAIContentTempVal(brand.appData.metaDescriptionAIContent);
                                                            }
                                                            }>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingMetaDescription}
                                                            disabled={disableButtons}
                                                            onClick={generateMetaDescription}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingMetaDescription}
                                                            disabled={disableButtons}
                                                            onClick={publishMetaDescription}>Publish</Button>
                                                    </>
                                                }
                                                {editingMetaDescriptionAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={metaDescriptionAIContentTempVal}
                                                                onChange={(e) => setMetaDescriptionAIContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingMetaDescriptionAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingMetaDescriptionAIContent}
                                                            onClick={updateMetaDescriptionAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!brand.appData || !brand.appData.metaDescriptionAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingMetaDescription} onClick={generateMetaDescription}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                        {activeTab === "tab-search-keywords" &&
                            <Flex id="search-keywords">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {brand.search_keywords &&
                                            <>
                                                {!editingSearchKeywordsLiveContent &&
                                                    <>
                                                        <div className="description">{brand.search_keywords}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingSearchKeywordsLiveContent(true);
                                                                setSearchKeywordsLiveContentTempVal(brand.search_keywords);
                                                            }
                                                            }>Edit</Button>
                                                    </>
                                                }
                                                {editingSearchKeywordsLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={searchKeywordsLiveContentTempVal}
                                                                onChange={(e) => setSearchKeywordsLiveContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingSearchKeywordsLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingSearchKeywordsLiveContent}
                                                            onClick={updateSearchKeywordsLiveContent}>Apply</Button>
                                                    </>
                                                }
                                                {!editingSearchKeywordsLiveContent && brand.appData && brand.appData.searchKeywordsAIContentPublishStatus === "published" &&
                                                    <Button
                                                        actionType="destructive"
                                                        type="button"
                                                        isLoading={revertingSearchKeywords}
                                                        disabled={disableButtons}
                                                        onClick={revertSearchKeywords}>Revert</Button>
                                                }
                                            </>
                                        }
                                        {!brand.search_keywords &&
                                            <div className="description center">N/A</div>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {brand.appData && brand.appData.searchKeywordsAIContent &&
                                            <>
                                                {!editingSearchKeywordsAIContent &&
                                                    <>
                                                        <div className="description">{brand.appData.searchKeywordsAIContent}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingSearchKeywordsAIContent(true);
                                                                setSearchKeywordsAIContentTempVal(brand.appData.searchKeywordsAIContent);
                                                            }
                                                            }>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingSearchKeywords}
                                                            disabled={disableButtons}
                                                            onClick={generateSearchKeywords}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingSearchKeywords}
                                                            disabled={disableButtons}
                                                            onClick={publishSearchKeywords}>Publish</Button>
                                                    </>
                                                }
                                                {editingSearchKeywordsAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={searchKeywordsAIContentTempVal}
                                                                onChange={(e) => setSearchKeywordsAIContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingSearchKeywordsAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingSearchKeywordsAIContent}
                                                            onClick={updateSearchKeywordsAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!brand.appData || !brand.appData.searchKeywordsAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingSearchKeywords} onClick={generateSearchKeywords}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                    </Box>
                </>
            }
            {
                loading &&
                <ComponentLoading></ComponentLoading>
            }
        </div >
    );
}

export default BrandDetail;
