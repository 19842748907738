import React, { memo, useState } from 'react';

const SafeImg = memo((props) => {   
    const [src, setSrc] = useState(props.src ? props.src : process.env.PUBLIC_URL + "/assets/img/image-placeholder.png");

    const onError = () => {
        const defaultImg = props.defaultImg ? props.defaultImg : process.env.PUBLIC_URL + "/assets/img/image-placeholder.png";
        setSrc(defaultImg);
    }

    return (
        <img src={src} className={props.className} onError={onError}></img>
    );
});

export default SafeImg;
