import { Button, Checkbox, Flex, FlexItem, Form, FormControlLabel, FormGroup, Modal, MultiSelect, Panel, Radio, Select, Switch, Text, Timepicker } from "@bigcommerce/big-design";
import { useEffect, useRef, useState } from "react";
import { Api } from "../../services/Api";
import { CheckIcon, CloseIcon, BaselineHelpIcon } from '@bigcommerce/big-design-icons';
import "./BlogSettings.css";

const BlogSettings = ({ store }) => {
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    const days = Array.from(Array(29).keys());

    const originalSettings = useRef(null);

    const getSetting = (setting, defaultValue) => {
        const returnVal = store.settings && store.settings.blogWriter && store.settings.blogWriter[setting] !== undefined ? store.settings.blogWriter[setting] : defaultValue;
        return returnVal;
    };

    const [autoPilot, setAutoPilot] = useState(getSetting("autoPilot", false));
    const [cronMode, setCronMode] = useState(getSetting("cronMode", "every-day"));

    const [daysFrequency, setDaysFrequency] = useState(getSetting("daysFrequency", 2));
    const [everyWeekOn, setEveryWeekOn] = useState(getSetting("everyWeekOn", ["monday"]));    
    const [writingTime, setWritingTime] = useState(getSetting("writingTime", "13:00"));
    const [timeZone, setTimeZone] = useState(getSetting("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone));

    const [generateMetaDescription, setGenerateMetaDescription] = useState(getSetting("generateMetaDescription", true));
    const [generateImage, setGenerateImage] = useState(getSetting("generateImage", true));

    useEffect(() => {
        originalSettings.current = getCurrentSettings();
    }, []);

    const getCurrentSettings = () => {
        return {
            autoPilot: autoPilot,
            cronMode: cronMode,
            daysFrequency: daysFrequency,
            everyWeekOn: everyWeekOn,
            writingTime: writingTime,
            timeZone: timeZone,
            generateMetaDescription: generateMetaDescription,
            generateImage: generateImage,
        };
    }

    useEffect(() => {
        setChanged(
            originalSettings.current.autoPilot !== autoPilot ||
            originalSettings.current.cronMode !== cronMode ||
            originalSettings.current.daysFrequency !== daysFrequency ||
            originalSettings.current.everyWeekOn !== everyWeekOn ||
            originalSettings.current.writingTime !== writingTime ||
            originalSettings.current.timeZone !== timeZone ||
            originalSettings.current.generateMetaDescription !== generateMetaDescription ||
            originalSettings.current.generateImage !== generateImage
        );
    }, [
        autoPilot,
        cronMode,
        daysFrequency,
        everyWeekOn,
        writingTime,
        timeZone,
        generateMetaDescription,
        generateImage
    ]);

    const submitChanges = async () => {
        setUpdating(true);
        try {
            await Api.post("/storeApi-updateBlogWriterSettings", {
                autoPilot: autoPilot,
                cronMode: cronMode,
                daysFrequency: daysFrequency,
                everyWeekOn: everyWeekOn,
                writingTime: writingTime,
                timeZone: timeZone,
                generateMetaDescription: generateMetaDescription,
                generateImage: generateImage,
            });
            originalSettings.current = getCurrentSettings();
            setChanged(false);
        }
        finally {
            setUpdating(false);
        }
    }

    const cancelChanges = async () => {
        // rollback changes
        setAutoPilot(originalSettings.current.autoPilot);
        setCronMode(originalSettings.current.cronMode);
        setDaysFrequency(originalSettings.current.daysFrequency);
        setEveryWeekOn(originalSettings.current.everyWeekOn);       
        setWritingTime(originalSettings.current.writingTime);
        setTimeZone(originalSettings.current.timeZone);
        setGenerateMetaDescription(originalSettings.current.generateMetaDescription);
        setGenerateImage(originalSettings.current.generateImage);
        setChanged(false);
    }

    return (
        <Panel
            header="Blog Writer Auto-Pilot Settings"
            action={
                {
                    variant: 'subtle',
                    iconOnly: <BaselineHelpIcon color="secondary"></BaselineHelpIcon>,
                    onClick: () => {
                        setHelpDialogOpen(true);
                    },
                }
            }
        >
            <div className="blog-settings">
                <Form>
                    <FormGroup>
                        <div className="chk-group">
                            <Switch checked={autoPilot} onChange={(e) => setAutoPilot(!autoPilot)} />
                            <Text>Auto-Pilot</Text>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="group-label chk-group">
                            <Text><strong>Writing Frequency</strong></Text>
                        </div>
                    </FormGroup>
                    <div className="indented">
                        <FormGroup>
                            <Radio
                                checked={cronMode === "every-day"}
                                label="Every day"
                                onChange={(e) => setCronMode(e.target.value)}
                                value="every-day"
                                disabled={!autoPilot}
                            />
                            <Flex flexDirection="row" alignItems="center" justifyContent="start" flexGap="8px">
                                <FlexItem>
                                    <Radio
                                        checked={cronMode === "every-n-days"}
                                        label="Every"
                                        onChange={(e) => setCronMode(e.target.value)}
                                        value="every-n-days"
                                        disabled={!autoPilot}
                                    />
                                </FlexItem>
                                <FlexItem flexBasis={"80px"}>
                                    <Select
                                        filterable={true}
                                        maxHeight={300}
                                        onOptionChange={(val) => setDaysFrequency(val)}
                                        options={days.map(d => { return { value: d + 2, content: (d + 2).toString() } })}
                                        placement="bottom-start"
                                        value={daysFrequency}
                                        disabled={!autoPilot || cronMode !== "every-n-days"}
                                    />
                                </FlexItem>
                                <FlexItem>
                                    <Text>
                                        days
                                    </Text>
                                </FlexItem>
                            </Flex>
                            <Flex flexDirection="row" alignItems="center" justifyContent="start" flexGap="8px">
                                <FlexItem>
                                    <Radio
                                        checked={cronMode === "every-week"}
                                        label="Every week on"
                                        onChange={(e) => setCronMode(e.target.value)}
                                        value="every-week"
                                        disabled={!autoPilot}
                                    />
                                </FlexItem>
                            </Flex>
                            <FormGroup>
                                <div className="multi-select indented">
                                    <MultiSelect
                                        placeholder="Select days..."
                                        filterable={false}
                                        maxHeight={300}
                                        onOptionsChange={(val) => setEveryWeekOn(val)}
                                        options={[
                                            { value: 'monday', content: 'Monday' },
                                            { value: 'tuesday', content: 'Tuesday' },
                                            { value: 'wednesday', content: 'Wednesday' },
                                            { value: 'thursday', content: 'Thursday' },
                                            { value: 'friday', content: 'Friday' },
                                            { value: 'saturday', content: 'Saturday' },
                                            { value: 'sunday', content: 'Sunday' }
                                        ]}
                                        placement="bottom-start"
                                        value={everyWeekOn}
                                        disabled={!autoPilot || cronMode !== "every-week"}
                                    />
                                </div>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup>
                            <Flex alignItems="flex-end" flexGap="8px">
                                <FlexItem flexBasis={"120px"}>
                                    <Timepicker
                                        locale="en-US"
                                        onTimeChange={(value) => setWritingTime(value)}
                                        value={writingTime}
                                        required={true}
                                        label="Writing Time"
                                        disabled={!autoPilot} />
                                </FlexItem>
                                <FlexItem flexBasis={"200px"}>
                                    <Select
                                        filterable={true}
                                        maxHeight={300}
                                        onOptionChange={(val) => setTimeZone(val)}
                                        options={Intl.supportedValuesOf('timeZone').map(zone => { return { value: zone, content: zone } })}
                                        placement="bottom-start"
                                        value={timeZone}
                                        disabled={!autoPilot}
                                    />
                                </FlexItem>
                            </Flex>
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <div className="group-label chk-group">
                            <Text><strong>Preferences</strong></Text>
                        </div>
                    </FormGroup>
                    <div className="indented">
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateMetaDescription} onChange={(e) => setGenerateMetaDescription(!generateMetaDescription)} label="Generate meta description" />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled={!autoPilot} checked={generateImage} onChange={(e) => setGenerateImage(!generateImage)} label="Generate article image" />
                        </FormGroup>
                    </div>
                    <div className="buttons">
                        <Button onClick={cancelChanges} disabled={!changed || updating} type="button" actionType="destructive" variant="secondary" iconRight={<CloseIcon></CloseIcon>}>Cancel</Button>
                        <Button isLoading={updating} onClick={submitChanges} disabled={!changed} type="button" variant="secondary" iconRight={<CheckIcon></CheckIcon>}>Save</Button>
                    </div>
                </Form>
            </div>
            <Modal
                actions={[
                    { text: 'Ok', variant: 'subtle', onClick: () => setHelpDialogOpen(false) }
                ]}
                header="Help"
                isOpen={helpDialogOpen}
                onClose={() => setHelpDialogOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <>
                    <Text>If you switch the <strong>Auto-Pilot</strong> on, the app will automatically generate and publish blog posts based on a schedule.</Text>
                    <Text>The alternative to Auto-Pilot is  <strong>"manual generation"</strong>:
                        <ul>
                            <li>You can manually trigger blog article generation by clicking <strong>"Create New Blog Post"</strong> button.</li>
                        </ul>
                    </Text>
                </>
            </Modal>
        </Panel>
    )
}
export default BlogSettings;
