import { Box, Button, Flex, FlexItem, H2, Textarea } from "@bigcommerce/big-design";
import { ContentCopyIcon } from '@bigcommerce/big-design-icons';
import "./SocialMediaPost.css";
import { useEffect, useState } from "react";
import { Api } from "../../services/Api";
import { Utils } from "../../services/Utils";
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, WhatsappShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, PinterestIcon } from "react-share";

const SocialMediaPost = (props) => {
    const [socialMediaPost, setSocialMediaPost] = useState();
    const [editedText, setEditedText] = useState();
    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [copyButtonText, setCopyButtonText] = useState("Copy");

    useEffect(() => {
        setSocialMediaPost(props.socialMediaPost);
    }, []);

    useEffect(() => {
        if (props.onEditingChange) {
            props.onEditingChange(editing);
        }
    }, [editing]);

    useEffect(() => {
        if (updating && props.onUpdating) {
            props.onUpdating();
        }
    }, [updating]);

    const updateSocialMediaPost = async () => {
        try {
            setUpdating(true);
            const body = editedText;
            const data = await Api.post(`/socialMediaApi-updateSocialMediaPost`, { id: socialMediaPost.docId, body: body });
            setSocialMediaPost(data);
            setEditing(false);
            if (props.onChange) {
                props.onChange(socialMediaPost);
            }
        }
        finally {
            setUpdating(false);
        }
    }

    return (
        <div className="social-media-post">
            {socialMediaPost &&
                <>
                    {!editing &&
                        <>
                            <Box
                                border="box"
                                marginTop="medium"
                                padding="medium"
                                backgroundColor="secondary20"
                                action={
                                    {
                                        variant: 'secondary',
                                        text: 'Edit',
                                        onClick: () => {
                                            setEditing(true);
                                            setEditedText(socialMediaPost.body);
                                        },
                                    }
                                }
                            >
                                <H2>{socialMediaPost.title}</H2>
                                <div className="body" dangerouslySetInnerHTML={{ __html: Utils.sanitize(socialMediaPost.body) }}></div>
                                {socialMediaPost.status !== "not-published" &&
                                    <div className="dates">
                                        {socialMediaPost.updated &&
                                            <>
                                                <small>Updated: {new Date(socialMediaPost.updated._seconds * 1000).toLocaleString()}</small><br />
                                            </>
                                        }
                                        {socialMediaPost.published &&
                                            <>
                                                <small>Published: {new Date(socialMediaPost.published._seconds * 1000).toLocaleString()}</small><br />
                                            </>
                                        }
                                    </div>
                                }
                                <Flex flexDirection="column">
                                    <FlexItem alignSelf="center">
                                    </FlexItem>
                                    <FlexItem alignSelf="center">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            disabled={updating}
                                            onClick={() => {
                                                setEditing(true);
                                                setEditedText(socialMediaPost.body);
                                            }}>Edit</Button>
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            disabled={updating}
                                            onClick={() => {
                                                navigator.clipboard.writeText(socialMediaPost.body);
                                                setCopyButtonText("Copied!");
                                                setTimeout(() => {
                                                    setCopyButtonText("Copy");
                                                }, 2000)
                                            }}><ContentCopyIcon />{copyButtonText}</Button>
                                    </FlexItem>
                                    {/* <FlexItem>
                                        <TwitterShareButton url={socialMediaPost.url} title={socialMediaPost.body}>
                                            <TwitterIcon size={32} round={true}></TwitterIcon>
                                        </TwitterShareButton>
                                        <FacebookShareButton url={socialMediaPost.url} quote={socialMediaPost.body}>
                                            <FacebookIcon size={32} round={true}></FacebookIcon>
                                        </FacebookShareButton>
                                        <LinkedinShareButton url={socialMediaPost.url} title="title" summary={socialMediaPost.body}>
                                            <LinkedinIcon size={32} round={true}></LinkedinIcon>
                                        </LinkedinShareButton>
                                        <PinterestShareButton url={socialMediaPost.url} media=" " description={socialMediaPost.body}>
                                            <PinterestIcon size={32} round={true}></PinterestIcon>
                                        </PinterestShareButton>
                                    </FlexItem> */}
                                </Flex>
                            </Box>
                        </>
                    }
                    {editing &&
                        <>
                            <Box marginTop="medium" border="box" padding="medium" backgroundColor="secondary20">
                                <Flex flexDirection="column">
                                    <FlexItem>
                                        <div className="description">
                                            <Textarea rows={15}
                                                value={editedText}
                                                onChange={(e) => setEditedText(e.target.value)}
                                            ></Textarea>
                                        </div>
                                    </FlexItem>
                                    <FlexItem alignSelf="center">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            disabled={updating}
                                            onClick={() => setEditing(false)}>Cancel</Button>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            isLoading={updating}
                                            onClick={updateSocialMediaPost}>Apply</Button>
                                    </FlexItem>
                                </Flex>
                            </Box>
                        </>
                    }
                </>
            }
        </div>
    );
}
export default SocialMediaPost;
