import { Box, Button, Flex, FlexItem, H2, Input, Panel, Small, Text } from "@bigcommerce/big-design";
import "./BlogPost.css";
import { Editor } from "@tinymce/tinymce-react";
import { TINY_MCE_KEY } from "../../common/Constants";
import { useEffect, useRef, useState } from "react";
import { Api } from "../../services/Api";
import { Utils } from "../../services/Utils";
import Dialog from "../Dialog";

const BlogPost = (props) => {
    const [blogPost, setBlogPost] = useState();
    const [editedTitle, setEditedTitle] = useState();
    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [publishing, setPublishing] = useState(false);

    const editorRef = useRef(null);

    useEffect(() => {
        setBlogPost(props.blogPost);
    }, []);

    useEffect(() => {
        if (props.onEditingChange) {
            props.onEditingChange(editing);
        }
    }, [editing]);

    useEffect(() => {
        if (updating && props.onUpdating) {
            props.onUpdating();
        }
    }, [updating]);

    const updateBlogPost = async () => {
        try {
            setUpdating(true);
            const body = editorRef.current.getContent();
            const data = await Api.post(`/blogApi-updateBlogPost`, { id: blogPost.docId, body: body, title: editedTitle });
            setBlogPost(data);
            setEditing(false);
            if (props.onChange) {
                props.onChange(blogPost);
            }
        }
        finally {
            setUpdating(false);
        }
    }

    const publishBlogPost = async () => {
        try {
            setUpdating(true);
            const data = await Api.post(`/blogApi-publishBlogPost`, { id: blogPost.docId });
            setBlogPost(data);
            if (props.onChange) {
                props.onChange(data);
            }
        }
        finally {
            setUpdating(false);
        }
    }

    const unpublishBlogPost = async () => {
        try {
            setUpdating(true);
            const data = await Api.post(`/blogApi-unpublishBlogPost`, { id: blogPost.docId });
            setBlogPost(data);
            if (props.onChange) {
                props.onChange(data);
            }
        }
        finally {
            setUpdating(false);
        }
    }

    return (
        <div className="blog-post">
            {blogPost &&
                <>
                    {!editing &&
                        <>
                            <Box
                                border="box"
                                marginTop="medium"
                                padding="medium"
                                backgroundColor="secondary20"
                                action={
                                    {
                                        variant: 'secondary',
                                        text: 'Edit',
                                        onClick: () => {
                                            setEditing(true);
                                            setEditedTitle(blogPost.title);
                                        },
                                    }
                                }
                            >
                                <H2>{blogPost.title}</H2>
                                <div className="body" dangerouslySetInnerHTML={{ __html: Utils.sanitize(blogPost.body) }}></div>
                                {blogPost.status !== "not-published" &&
                                    <div className="dates">
                                        {blogPost.updated &&
                                            <>
                                                <small>Updated: {new Date(blogPost.updated._seconds * 1000).toLocaleString()}</small><br />
                                            </>
                                        }
                                        {blogPost.published &&
                                            <>
                                                <small>Published: {new Date(blogPost.published._seconds * 1000).toLocaleString()}</small><br />
                                            </>
                                        }
                                    </div>
                                }
                                <Flex flexDirection="column">
                                    <FlexItem alignSelf="center">
                                    </FlexItem>
                                    <FlexItem alignSelf="center">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            disabled={updating}
                                            onClick={() => {
                                                setEditing(true);
                                                setEditedTitle(blogPost.title);
                                            }}>Edit</Button>
                                        {blogPost.status !== "published" &&
                                            <Button
                                                type="button"
                                                variant="primary"
                                                isLoading={updating}
                                                onClick={() => setPublishing(true)}>{blogPost.status === "outdated" ? "Publish Updates" : "Publish"}</Button>
                                        }
                                        {blogPost.status === "published" &&
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                actionType="destructive"
                                                isLoading={updating}
                                                onClick={unpublishBlogPost}>Unpublish</Button>
                                        }
                                    </FlexItem>
                                </Flex>
                            </Box>
                        </>
                    }
                    {editing &&
                        <>
                            <Box marginTop="medium" border="box" padding="medium" backgroundColor="secondary20">
                                <Flex flexDirection="column">
                                    <FlexItem flexBasis={1} flexGrow={1} marginBottom="medium">
                                        <div className="title-input">
                                            <Input value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)}></Input>
                                        </div>
                                    </FlexItem>
                                    <FlexItem>
                                        <div className="body">
                                            <Editor
                                                tinymceScriptSrc='/tinymce/tinymce.min.js'
                                                apiKey={TINY_MCE_KEY}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={blogPost.body}
                                                init={{
                                                    height: props.height ? props.height : 300,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks |' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | image | code | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
                                                        const xhr = new XMLHttpRequest();
                                                        xhr.withCredentials = false;
                                                        xhr.open('POST', Api.BASE_URL + '/blogApi-uploadImage');
                                                        xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("auth-token"));

                                                        xhr.upload.onprogress = (e) => {
                                                            progress(e.loaded / e.total * 100);
                                                        };

                                                        xhr.onload = () => {
                                                            if (xhr.status === 403) {
                                                                reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                                                                return;
                                                            }

                                                            if (xhr.status === 400 || xhr.status === 500) {
                                                                reject(xhr.responseText);
                                                                return;
                                                            }
                                                            else if (xhr.status < 200 || xhr.status >= 300) {
                                                                reject('HTTP Error: ' + xhr.status);
                                                                return;
                                                            }

                                                            const json = JSON.parse(xhr.responseText);

                                                            if (!json || typeof json.location != 'string') {
                                                                reject('Invalid JSON: ' + xhr.responseText);
                                                                return;
                                                            }

                                                            resolve(json.location);
                                                        };

                                                        xhr.onerror = () => {
                                                            reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                                                        };

                                                        const formData = new FormData();
                                                        formData.append('file', blobInfo.blob(), blobInfo.filename());

                                                        xhr.send(formData);
                                                    })
                                                }}
                                            />
                                        </div>
                                    </FlexItem>
                                    <FlexItem alignSelf="center">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            disabled={updating}
                                            onClick={() => setEditing(false)}>Cancel</Button>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            isLoading={updating}
                                            onClick={updateBlogPost}>Apply</Button>
                                    </FlexItem>
                                </Flex>
                            </Box>
                        </>
                    }
                    <Dialog
                        header="Publish Blog Post"
                        text="Blog post will be published on your BigCommerce Store's blog."
                        onOk={() => {
                            setPublishing(false);
                            publishBlogPost();
                        }}
                        onCancel={() => setPublishing(false)}
                        isOpen={publishing}
                    ></Dialog>
                </>
            }
        </div>
    );
}
export default BlogPost;
