import { Badge, Box, Button, Flex, FlexItem, Link, Modal, Text } from "@bigcommerce/big-design";
import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../services/Api";
import ComponentLoading from "../ComponentLoading";
import MemoizedTable from "../MemoizedTable";
import "./BlogPosts.css";
import SafeImg from "../SafeImg";
import SearchForm from "../SearchForm";
import CreateBlogPost from "./CreateBlogPost";
import BlogPost from "./BlogPost";
import { useNavigate } from "react-router-dom";

const BlogPosts = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchTimestamp, setSearchTimestamp] = useState(0);
    const [selectedBlogPost, setSelectedBlogPost] = useState(null);
    const [createBlogPost, setCreateBlogPost] = useState(false);
    const pageFirstElements = useRef([]);

    const navigate = useNavigate();

    const getData = async (page) => {
        try {
            setLoading(true);
            let startAt = null;
            let startAfter = null;

            if (page === 1) {
                // loading first page
                pageFirstElements.current = [];
            }
            if (page > currentPage) {
                // going forward
                pageFirstElements.current.push(items[0].appData.docId);
                startAfter = items[items.length - 1].appData.docId;
            }
            else if (page < currentPage) {
                // going backwards
                startAt = pageFirstElements.current.pop();
            }

            setItems([]);
            setItemsTimestamp(0);
            const data = await Api.get("/blogApi-getAIGeneratedBlogPosts", {
                keyword: searchKeyword,
                startAt: startAt,
                startAfter: startAfter,
                pageSize: pageSize
            });
            if (data.blogPosts) {
                setItems(data.blogPosts);
                setItemsTimestamp(new Date().getTime());
                if (pagingEnabled()) {
                    if (data.blogPosts.length === pageSize) {
                        setTotalItems(totalItems + (page + 1) * pageSize);
                    }
                    else {
                        setTotalItems(totalItems + data.blogPosts.length);
                    }
                }
                else {
                    // paging is disabled when search input is submitted
                    setTotalItems(data.blogPosts.length);
                }
                setCurrentPage(page);
            }
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getData(1);
    }, [searchKeyword, searchTimestamp, pageSize]);

    const onSearch = (keyword) => {
        setSearchKeyword(keyword);
        setSearchTimestamp(new Date().getTime());
    };

    const pagingEnabled = () => {
        if (searchKeyword) {
            return true;
        }

        return true;
    }

    const renderTitle = (blogPost) => {
        return (
            <>
                <div className="blog-title">
                    <Link ellipsis={true} onClick={() => navigate("/blog-post/" + blogPost.docId)}>{blogPost.title ? blogPost.title : "No Title"}</Link>
                </div>
            </>
        )
    }

    const renderCreated = (blogPost) => {
        const created = new Date(blogPost.created._seconds * 1000)
        return created.toLocaleString();
    }

    useEffect(() => {
        if (selectedBlogPost !== null) {
            const blogDetailElement = document.querySelector(".blog-detail");
            blogDetailElement.parentElement.parentElement.style.maxWidth = "1024px";
        }
    }, [selectedBlogPost]);

    const renderStatus = (blogPost) => {
        let variant = "";
        if (blogPost.status === "published") {
            variant = "success";
        }
        else if (blogPost.status === "not-published") {
            variant = "secondary";
        }
        else if (blogPost.status === "outdated") {
            variant = "warning";
        }

        return (
            <>
                <Badge label={blogPost.status} variant={variant}></Badge>
            </>
        );
    }

    return (
        <>
            <div className="blog-posts">
                <Box marginBottom="medium">
                    <SearchForm onSubmit={onSearch}></SearchForm>
                </Box>
                <Flex marginBottom="medium" justifyContent="end">
                    <FlexItem>
                        <Button onClick={() => setCreateBlogPost(true)}>Create New Blog Post</Button>
                    </FlexItem>
                </Flex>
                <MemoizedTable
                    columns={[
                        { header: 'Title', hash: 'title', width: "500px", render: (blogPost) => renderTitle(blogPost) },
                        { header: 'Status', hash: 'status', width: "100px", render: (blogPost) => renderStatus(blogPost) },
                        { header: 'Created', hash: 'created', width: "100px", render: (blogPost) => renderCreated(blogPost) },
                    ]}
                    items={items}
                    timestamp={itemsTimestamp}
                    pagination={{
                        itemsPerPageOptions: [20, 50, 100],
                        currentPage: currentPage,
                        itemsPerPage: pageSize,
                        totalItems: totalItems,
                        onPageChange: (page) => getData(page),
                        onItemsPerPageChange: (range) => { setPageSize(range); },
                        getRangeLabel: (start, end, totalItems) => { return pageSize }

                    }}
                    stickyHeader
                    loading={loading}
                />
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedBlogPost}
                onClose={() => setSelectedBlogPost(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
                backdrop={false}
            >
                <div className="blog-detail">
                    <BlogPost
                        blogPost={selectedBlogPost}
                        onClose={() => setSelectedBlogPost(null)}
                        onChange={() => getData(1)}
                    ></BlogPost>
                </div>
            </Modal>
            <Modal
                isOpen={createBlogPost}
                onClose={() => setCreateBlogPost(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
                header="Create Blog Post"
            >
                <CreateBlogPost onCreated={() => getData(1)} onClose={() => setCreateBlogPost(false)}></CreateBlogPost>
            </Modal>
        </>
    );
}
export default BlogPosts;
