import DOMPurify from "dompurify";
import { alertManager } from "../App";

export class Utils {
  static error(message) {
    this.alert(message, "error");
  }

  static warning(message) {
    this.alert(message, "warning");
  }

  static success(message) {
    this.alert(message, "success");
  }

  static alert(message, type) {
    let messages = [];
    if (Array.isArray(message)) {
      for (let i = 0; i < message.length; i++) {
        messages.push({ text: message[i] });
      }
    }
    else {
      messages.push({ text: message });
    }
    const alert = {
      messages: messages,
      type: type,
      onClose: () => null,
    };
    alertManager.add(alert);
  }

  static sanitize(val) {
    return DOMPurify.sanitize(val);
  }
}
