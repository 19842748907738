import { Modal, Text } from "@bigcommerce/big-design";
import { useEffect, useState } from "react";

const Dialog = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props])
    return (
        <Modal
            actions={[
                {
                    text: 'Cancel',
                    variant: 'subtle',
                    onClick: () => {
                        setIsOpen(false);
                        if (props.onCancel) {
                            props.onCancel();
                        }
                    }
                },
                {
                    text: 'Ok',
                    onClick: () => {
                        setIsOpen(false);
                        if (props.onOk) {
                            props.onOk();
                        }
                    }
                },
            ]}
            header={props.header}
            isOpen={isOpen}
            onClose={
                () => {
                    setIsOpen(false);
                    if (props.onClose) {
                        props.onClose();
                    }
                }
            }
            closeOnEscKey={true}
            closeOnClickOutside={false}
        >
            <Text>
                {props.text}
            </Text>
        </Modal>
    )
}

export default Dialog;
