import { Badge, Box, Button, Link, Modal, Small } from "@bigcommerce/big-design";
import React, { useEffect, useState, useRef } from "react";
import { Api } from "../../../services/Api";
import { SharedState } from "../../../services/SharedState";
import ComponentLoading from "../../ComponentLoading";
import MemoizedTable from "../../MemoizedTable";
import CategortDetail from "./CategoryDetail";
import "./Categories.css";
import SafeImg from "../../SafeImg";
import { CategoryHelper } from "../../../services/CategoryHelper";
import SearchForm from "../../SearchForm";

const AIModifiedCategories = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchTimestamp, setSearchTimestamp] = useState(0);
    const pageFirstElements = useRef([]);

    const getData = async (page) => {
        try {
            let startAt = null;
            let startAfter = null;

            if (page === 1) {
                // loading first page
                pageFirstElements.current = [];
            }
            if (page > currentPage) {
                // going forward
                pageFirstElements.current.push(items[0].appData.docId);
                startAfter = items[items.length - 1].appData.docId;
            }
            else if (page < currentPage) {
                // going backwards
                startAt = pageFirstElements.current.pop();
            }

            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);

            let categories = "";
            if (searchKeyword) {
                const allCategories = CategoryHelper.prepareCategories(SharedState.getValue("categories"));
                const categoriesFiltered = allCategories.filter((c) => {
                    return (c.name + " " + c.path).toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
                });
                categories = categoriesFiltered.map(c => c.id).join(",");
                if (!categories) {
                    categories = "-1";
                }
            }

            const data = await Api.get("/categoryApi-getAIModifiedCategories", {
                categories: categories,
                startAt: startAt,
                startAfter: startAfter,
                pageSize: pageSize
            });
            if (data.categories) {
                setItems(data.categories);
                setItemsTimestamp(new Date().getTime());
                if (pagingEnabled()) {
                    if (data.categories.length === pageSize) {
                        setTotalItems(totalItems + (page + 1) * pageSize);
                    }
                    else {
                        setTotalItems(totalItems + data.categories.length);
                    }
                }
                else {
                    // paging is disabled when search input is submitted
                    setTotalItems(data.categories.length);
                }
                setCurrentPage(page);
            }
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
    }, [searchKeyword, searchTimestamp]);

    useEffect(() => {
        if (selectedCategory !== null) {
            const categoryDetailElement = document.querySelector(".category-detail");
            categoryDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedCategory]);

    const onSearch = (keyword, categories) => {
        setSearchKeyword(keyword);
        setSearchTimestamp(new Date().getTime());
    };

    const renderCategoryImage = (category) => {
        try {
            const thumb = category.images.find(i => i.is_thumbnail === true);
            return <SafeImg className="thumbnail" src={thumb.url_tiny} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const pagingEnabled = () => {
        if (searchKeyword) {
            return false;
        }

        return true;
    }

    const renderName = (category) => {
        return (
            <>
                <div className="category-name">
                    <Link ellipsis={true} onClick={() => setSelectedCategory(category)}>{category.name}</Link>
                </div>
                <div className="category-name">
                    <Small ellipsis={true}>{category.path}</Small>
                </div>
            </>
        )
    }

    const renderContentStatus = (field, category) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = category.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // categoryDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "categoryDescription") {
            if (!category.description) {
                quality = "empty"
            }
            else if (category.description.split(' ').length < 50) {
                quality = "too short";
            }
        }
        else if (field === "metaDescription") {
            if (!category.meta_description) {
                quality = "empty"
            }
            else if (category.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!category.search_keywords) {
                quality = "empty"
            }
            else if (category.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    return (
        <>
            <div className="categories">
                <Box marginBottom="medium">
                    <SearchForm onSubmit={onSearch}></SearchForm>
                </Box>
                {itemsTimestamp &&
                    <MemoizedTable
                        columns={[
                            { header: '', hash: 'thumbnail', width: "100px", render: (category) => renderCategoryImage(category) },
                            { header: 'Name', hash: 'name', width: "500px", render: (category) => renderName(category) },
                            { header: 'Category Description', width: "150px", hash: 'prodDescription', render: (category) => renderContentStatus("categoryDescription", category) },
                            { header: 'Meta Description', width: "150px", hash: 'metaDescription', render: (category) => renderContentStatus("metaDescription", category) },
                            { header: 'Search Keywords', width: "150px", hash: 'searchKeywords', render: (category) => renderContentStatus("searchKeywords", category) },
                        ]}
                        items={items}
                        timestamp={itemsTimestamp}
                        pagination={pagingEnabled() && {
                            itemsPerPageOptions: [10, 20, 50],
                            currentPage: currentPage,
                            itemsPerPage: pageSize,
                            totalItems: totalItems,
                            onPageChange: (page) => getData(page),
                            onItemsPerPageChange: (range) => {
                                setPageSize(range);
                                setTimeout(() => {
                                    getData(currentPage);
                                }, 500);
                            },
                            getRangeLabel: (start, end, totalItems) => { return pageSize }

                        }}
                        stickyHeader
                        loading={loading}
                    />
                }
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedCategory}
                onClose={() => setSelectedCategory(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <CategortDetail category={selectedCategory}></CategortDetail>
            </Modal>
        </>
    );
}
export default AIModifiedCategories;
