import React from 'react';
import { ProgressCircle } from '@bigcommerce/big-design';

const PageLoading = () => {

    return (
        <div className="vertical-horizontal-center">
            <ProgressCircle size="medium" />
        </div>
    );
}

export default PageLoading;
