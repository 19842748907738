export class CategoryHelper {
    static prepareCategories = (categories) => {
        const allCategoriesSorted = categories.sort((c1, c2) => { return c1.sort_order - c2.sort_order });
        const categoryTree = this.prepareCategoryTree(0, allCategoriesSorted);
        const categoriesFlatenned = this.flattenCategoryTree(categoryTree, 0, "");
        return categoriesFlatenned;
    }

    static prepareCategoryTree = (parentId, categories) => {
        const topLevelCategories = categories.filter(c => c.parent_id === parentId);
        const nodes = [];
        topLevelCategories.forEach((c) => {
            nodes.push({
                id: c.id,
                name: c.name,
                parent_id: c.parent_id,
                sort_order: c.sort_order,
                description: c.description,
                meta_description: c.meta_description,
                search_keywords: c.search_keywords,
                image_url: c.image_url,
                appData: c.appData,
                children: this.prepareCategoryTree(c.id, categories)
            });
        });

        return nodes.length > 0 ? nodes : null;
    }

    static flattenCategoryTree = (categoryTree, level, path) => {
        let flattened = [];
        for (const category of categoryTree) {
            flattened.push({
                id: category.id,
                name: category.name,
                parent_id: category.parent_id,
                sort_order: category.sort_order,
                description: category.description,
                meta_description: category.meta_description,
                search_keywords: category.search_keywords,
                image_url: category.image_url,
                appData: category.appData,
                level: level,
                path: path
            });
            if (category.children && category.children.length > 0) {
                flattened = [...flattened, ...this.flattenCategoryTree(category.children, level + 1, "/" + category.name)];
            }
        }

        return flattened;
    }
}
