import React, { useState, useEffect, useRef } from 'react';
import { Badge, Box, Button, Flex, FlexItem, H2, H4, Panel, Small, Table, Tabs, Textarea } from '@bigcommerce/big-design';
import { Api } from '../../../services/Api';
import ComponentLoading from '../../ComponentLoading';
import SafeImg from '../../SafeImg';
import "./CategoryDetail.css";
import { Utils } from '../../../services/Utils';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_KEY } from '../../../common/Constants';

const CategortDetail = (props) => {

    const [category, setCategory] = useState();
    const [loading, setLoading] = useState(true);
    const [disableButtons, setDisableButtons] = useState(false);

    const [generatingCategoryDescription, setGeneratingCategoryDescription] = useState(false);
    const [publishingCategoryDescription, setPublishingCategoryDescription] = useState(false);
    const [revertingCategoryDescription, setRevertingCategoryDescription] = useState(false);
    const [editingCategoryDescriptionAIContent, setEditingCategoryDescriptionAIContent] = useState(false);
    const [editingCategoryDescriptionLiveContent, setEditingCategoryDescriptionLiveContent] = useState(false);
    const [updatingCategoryDescriptionAIContent, setUpdatingCategoryDescriptionAIContent] = useState(false);
    const [updatingCategoryDescriptionLiveContent, setUpdatingCategoryDescriptionLiveContent] = useState(false);

    const categoryDescriptionEditorRef = useRef(null);
    const aiCategoryDescriptionEditorRef = useRef(null);

    const [generatingMetaDescription, setGeneratingMetaDescription] = useState(false);
    const [publishingMetaDescription, setPublishingMetaDescription] = useState(false);
    const [revertingMetaDescription, setRevertingMetaDescription] = useState(false);
    const [editingMetaDescriptionAIContent, setEditingMetaDescriptionAIContent] = useState(false);
    const [editingMetaDescriptionLiveContent, setEditingMetaDescriptionLiveContent] = useState(false);
    const [updatingMetaDescriptionAIContent, setUpdatingMetaDescriptionAIContent] = useState(false);
    const [updatingMetaDescriptionLiveContent, setUpdatingMetaDescriptionLiveContent] = useState(false);

    const [metaDescriptionAIContentTempVal, setMetaDescriptionAIContentTempVal] = useState("");
    const [metaDescriptionLiveContentTempVal, setMetaDescriptionLiveContentTempVal] = useState("");

    const [generatingSearchKeywords, setGeneratingSearchKeywords] = useState(false);
    const [publishingSearchKeywords, setPublishingSearchKeywords] = useState(false);
    const [revertingSearchKeywords, setRevertingSearchKeywords] = useState(false);
    const [editingSearchKeywordsAIContent, setEditingSearchKeywordsAIContent] = useState(false);
    const [editingSearchKeywordsLiveContent, setEditingSearchKeywordsLiveContent] = useState(false);
    const [updatingSearchKeywordsAIContent, setUpdatingSearchKeywordsAIContent] = useState(false);
    const [updatingSearchKeywordsLiveContent, setUpdatingSearchKeywordsLiveContent] = useState(false);

    const [searchKeywordsAIContentTempVal, setSearchKeywordsAIContentTempVal] = useState("");
    const [searchKeywordsLiveContentTempVal, setSearchKeywordsLiveContentTempVal] = useState("");

    const [activeTab, setActiveTab] = useState("tab-category-description");

    useEffect(() => {
        const getCategory = async () => {
            try {
                setLoading(true);
                const data = await Api.get(`/categoryApi-getCategory?id=${props.category.id}`);
                setCategory(data);
            }
            finally {
                setLoading(false);
            }
        }
        getCategory();
    }, []);

    const renderContentStatus = (field) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = category.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // categoryDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "categoryDescription") {
            if (!category.description) {
                quality = "empty"
            }
            else if (category.description.split(' ').length < 50) {
                quality = "too short";
            }
        }
        else if (field === "metaDescription") {
            if (!category.meta_description) {
                quality = "empty"
            }
            else if (category.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!category.search_keywords) {
                quality = "empty"
            }
            else if (category.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    const renderAIContentStatus = (field) => {
        let status = "not generated";
        let statusVariant = "secondary"
        let publishStatus = null;
        let publishStatusVariant = null;
        const appData = category.appData;
        if (appData) {
            const statusPropertyName = field + "AIContent";
            if (appData[statusPropertyName]) {
                status = "generated";
                statusVariant = "success";
            }

            const publishedPropertyName = field + "AIContentPublishStatus";  // categoryDescriptionAIContentPublishStatus, metaDescriptionAIContentPublishStatus
            if (appData[publishedPropertyName]) {
                publishStatus = appData[publishedPropertyName];
                if (publishStatus === "published") {
                    publishStatusVariant = "success";
                }
                else if (publishStatus === "not-published") {
                    publishStatusVariant = "warning";
                }
            }
        }
        return (
            <>
                <Badge label={status} variant={statusVariant}></Badge>
                <br />
                {publishStatus &&
                    <Badge label={publishStatus} variant={publishStatusVariant}></Badge>
                }
            </>
        )
    }

    const generateCategoryDescription = async () => {
        try {
            setGeneratingCategoryDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryAiApi-generateCategoryDescription`, { categoryId: category.id });
            category.appData = data;
            setCategory(category);
        }
        finally {
            setDisableButtons(false);
            setGeneratingCategoryDescription(false);
        }
    }

    const publishCategoryDescription = async () => {
        try {
            setPublishingCategoryDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryApi-publishAICategoryDescription`, { categoryId: category.id });
            setCategory(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingCategoryDescription(false);
        }
    }

    const revertCategoryDescription = async () => {
        try {
            setRevertingCategoryDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryApi-revertAICategoryDescription`, { categoryId: category.id });
            setCategory(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingCategoryDescription(false);
        }
    }

    const updateCategoryDescriptionAIContent = async () => {
        try {
            setUpdatingCategoryDescriptionAIContent(true);
            setDisableButtons(true);
            const description = aiCategoryDescriptionEditorRef.current.getContent()
            await Api.post(`/categoryApi-updateAICategoryDescription`, { categoryId: category.id, description: description });
            category.appData.categoryDescriptionAIContent = description;
            setCategory(category);
            setEditingCategoryDescriptionAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingCategoryDescriptionAIContent(false);
        }
    }

    const updateCategoryDescriptionLiveContent = async () => {
        try {
            setUpdatingCategoryDescriptionLiveContent(true);
            setDisableButtons(true);
            const description = categoryDescriptionEditorRef.current.getContent()
            await Api.post(`/categoryApi-updateCategoryDescription`, { categoryId: category.id, description: description });
            category.description = description;
            setCategory(category);
            setEditingCategoryDescriptionLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingCategoryDescriptionLiveContent(false);
        }
    }

    const generateMetaDescription = async () => {
        try {
            setGeneratingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryAiApi-generateMetaDescription`, { categoryId: category.id });
            category.appData = data;
            setCategory(category);
        }
        finally {
            setDisableButtons(false);
            setGeneratingMetaDescription(false);
        }
    }

    const publishMetaDescription = async () => {
        try {
            setPublishingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryApi-publishAIMetaDescription`, { categoryId: category.id });
            setCategory(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingMetaDescription(false);
        }
    }

    const revertMetaDescription = async () => {
        try {
            setRevertingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryApi-revertAIMetaDescription`, { categoryId: category.id });
            setCategory(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingMetaDescription(false);
        }
    }

    const updateMetaDescriptionAIContent = async () => {
        try {
            setUpdatingMetaDescriptionAIContent(true);
            setDisableButtons(true);
            const description = metaDescriptionAIContentTempVal;
            await Api.post(`/categoryApi-updateAIMetaDescription`, { categoryId: category.id, description: description });
            category.appData.metaDescriptionAIContent = description;
            setCategory(category);
            setEditingMetaDescriptionAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingMetaDescriptionAIContent(false);
        }
    }

    const updateMetaDescriptionLiveContent = async () => {
        try {
            setUpdatingMetaDescriptionLiveContent(true);
            setDisableButtons(true);
            const description = metaDescriptionLiveContentTempVal;
            await Api.post(`/categoryApi-updateMetaDescription`, { categoryId: category.id, description: description });
            category.meta_description = description;
            setCategory(category);
            setEditingMetaDescriptionLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingMetaDescriptionLiveContent(false);
        }
    }


    const generateSearchKeywords = async () => {
        try {
            setGeneratingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryAiApi-generateSearchKeywords`, { categoryId: category.id });
            category.appData = data;
            setCategory(category);
        }
        finally {
            setDisableButtons(false);
            setGeneratingSearchKeywords(false);
        }
    }

    const publishSearchKeywords = async () => {
        try {
            setPublishingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryApi-publishAISearchKeywords`, { categoryId: category.id });
            setCategory(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingSearchKeywords(false);
        }
    }

    const revertSearchKeywords = async () => {
        try {
            setRevertingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/categoryApi-revertAISearchKeywords`, { categoryId: category.id });
            setCategory(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingSearchKeywords(false);
        }
    }

    const updateSearchKeywordsAIContent = async () => {
        try {
            setUpdatingSearchKeywordsAIContent(true);
            setDisableButtons(true);
            const searchKeywords = searchKeywordsAIContentTempVal;
            await Api.post(`/categoryApi-updateAISearchKeywords`, { categoryId: category.id, searchKeywords: searchKeywords });
            category.appData.searchKeywordsAIContent = searchKeywords;
            setCategory(category);
            setEditingSearchKeywordsAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingSearchKeywordsAIContent(false);
        }
    }

    const updateSearchKeywordsLiveContent = async () => {
        try {
            setUpdatingSearchKeywordsLiveContent(true);
            setDisableButtons(true);
            const searchKeywords = searchKeywordsLiveContentTempVal;
            await Api.post(`/categoryApi-updateSearchKeywords`, { categoryId: category.id, searchKeywords: searchKeywords });
            category.search_keywords = searchKeywords;
            setCategory(category);
            setEditingSearchKeywordsLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingSearchKeywordsLiveContent(false);
        }
    }

    return (
        <div className="category-detail">
            {!loading &&
                <>
                    <Box marginTop="medium" display="flex">
                        <Flex flexGap={"24px"}>
                            <FlexItem>
                                <div className="img-container">
                                    <SafeImg src={category.image_url} />
                                </div>
                            </FlexItem>
                            <FlexItem>
                                <H2 marginBottom="medium">{category.name}</H2>
                                <Box marginTop="large">
                                    <Table
                                        id="content-status"
                                        columns={[
                                            { header: '', hash: 'item', render: ({ name }) => name },
                                            { header: 'Live Content', hash: 'status', render: ({ field }) => renderContentStatus(field) },
                                            { header: 'AI Content', hash: 'name', render: ({ field }) => renderAIContentStatus(field) },
                                        ]}
                                        items={[
                                            { field: "categoryDescription", name: "Category Description" },
                                            { field: "metaDescription", name: "Meta Description" },
                                            { field: "searchKeywords", name: "Search Keywords" },
                                        ]}
                                    >
                                    </Table>
                                </Box>
                            </FlexItem>
                        </Flex>
                    </Box>
                    <Box marginTop="medium">
                        <Tabs
                            activeTab={activeTab}
                            aria-label="Category Tab Content"
                            id="tab"
                            items={[
                                { id: "tab-category-description", ariaControls: "category-description", title: "Category Description" },
                                { id: "tab-meta-description", ariaControls: "meta-description", title: "Meta Description" },
                                { id: "tab-search-keywords", ariaControls: "search-keywords", title: "Search Keywords" },
                            ]}
                            onTabClick={setActiveTab}
                        />
                        {activeTab === "tab-category-description" &&
                            <Flex id="category-description">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {category.description &&
                                            <>
                                                {!editingCategoryDescriptionLiveContent &&
                                                    <>
                                                        <div className="description" dangerouslySetInnerHTML={{ __html: Utils.sanitize(category.description) }}></div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={() => setEditingCategoryDescriptionLiveContent(true)}>Edit</Button>
                                                    </>
                                                }
                                                {editingCategoryDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Editor
                                                                tinymceScriptSrc='/tinymce/tinymce.min.js'
                                                                apiKey={TINY_MCE_KEY}
                                                                onInit={(evt, editor) => categoryDescriptionEditorRef.current = editor}
                                                                initialValue={category.description}
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | blocks | ' +
                                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingCategoryDescriptionLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingCategoryDescriptionLiveContent}
                                                            onClick={updateCategoryDescriptionLiveContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {!category.description &&
                                            <div className="description center">N/A</div>
                                        }
                                        {!editingCategoryDescriptionLiveContent && category.appData && category.appData.categoryDescriptionLiveContentStatus && category.appData.categoryDescriptionLiveContentStatus !== "original" &&
                                            <Button
                                                actionType="destructive"
                                                type="button"
                                                isLoading={revertingCategoryDescription}
                                                disabled={disableButtons}
                                                onClick={revertCategoryDescription}>Revert</Button>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {category.appData && category.appData.categoryDescriptionAIContent &&
                                            <>
                                                {!editingCategoryDescriptionAIContent &&
                                                    <>
                                                        <div className="description" dangerouslySetInnerHTML={{ __html: Utils.sanitize(category.appData.categoryDescriptionAIContent) }}></div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={() => setEditingCategoryDescriptionAIContent(true)}>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingCategoryDescription}
                                                            disabled={disableButtons}
                                                            onClick={generateCategoryDescription}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingCategoryDescription}
                                                            disabled={disableButtons}
                                                            onClick={publishCategoryDescription}>Publish</Button>
                                                    </>
                                                }
                                                {editingCategoryDescriptionAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Editor
                                                                tinymceScriptSrc='/tinymce/tinymce.min.js'
                                                                apiKey={TINY_MCE_KEY}
                                                                onInit={(evt, editor) => aiCategoryDescriptionEditorRef.current = editor}
                                                                initialValue={category.appData.categoryDescriptionAIContent}
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | blocks | ' +
                                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingCategoryDescriptionAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingCategoryDescriptionAIContent}
                                                            onClick={updateCategoryDescriptionAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!category.appData || !category.appData.categoryDescriptionAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingCategoryDescription} onClick={generateCategoryDescription}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                        {activeTab === "tab-meta-description" &&
                            <Flex id="meta-description">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {category.meta_description &&
                                            <>
                                                {!editingMetaDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">{category.meta_description}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingMetaDescriptionLiveContent(true);
                                                                setMetaDescriptionLiveContentTempVal(category.meta_description);
                                                            }
                                                            }>Edit</Button>
                                                    </>
                                                }
                                                {editingMetaDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={metaDescriptionLiveContentTempVal}
                                                                onChange={(e) => setMetaDescriptionLiveContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingMetaDescriptionLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingMetaDescriptionLiveContent}
                                                            onClick={updateMetaDescriptionLiveContent}>Apply</Button>
                                                    </>
                                                }
                                                {!editingMetaDescriptionLiveContent && category.appData && category.appData.metaDescriptionAIContentPublishStatus === "published" &&
                                                    <Button
                                                        actionType="destructive"
                                                        type="button"
                                                        isLoading={revertingMetaDescription}
                                                        disabled={disableButtons}
                                                        onClick={revertMetaDescription}>Revert</Button>
                                                }
                                            </>
                                        }
                                        {!category.meta_description &&
                                            <div className="description center">N/A</div>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {category.appData && category.appData.metaDescriptionAIContent &&
                                            <>
                                                {!editingMetaDescriptionAIContent &&
                                                    <>
                                                        <div className="description">{category.appData.metaDescriptionAIContent}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingMetaDescriptionAIContent(true);
                                                                setMetaDescriptionAIContentTempVal(category.appData.metaDescriptionAIContent);
                                                            }
                                                            }>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingMetaDescription}
                                                            disabled={disableButtons}
                                                            onClick={generateMetaDescription}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingMetaDescription}
                                                            disabled={disableButtons}
                                                            onClick={publishMetaDescription}>Publish</Button>
                                                    </>
                                                }
                                                {editingMetaDescriptionAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={metaDescriptionAIContentTempVal}
                                                                onChange={(e) => setMetaDescriptionAIContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingMetaDescriptionAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingMetaDescriptionAIContent}
                                                            onClick={updateMetaDescriptionAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!category.appData || !category.appData.metaDescriptionAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingMetaDescription} onClick={generateMetaDescription}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                        {activeTab === "tab-search-keywords" &&
                            <Flex id="search-keywords">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {category.search_keywords &&
                                            <>
                                                {!editingSearchKeywordsLiveContent &&
                                                    <>
                                                        <div className="description">{category.search_keywords}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingSearchKeywordsLiveContent(true);
                                                                setSearchKeywordsLiveContentTempVal(category.search_keywords);
                                                            }
                                                            }>Edit</Button>
                                                    </>
                                                }
                                                {editingSearchKeywordsLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={searchKeywordsLiveContentTempVal}
                                                                onChange={(e) => setSearchKeywordsLiveContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingSearchKeywordsLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingSearchKeywordsLiveContent}
                                                            onClick={updateSearchKeywordsLiveContent}>Apply</Button>
                                                    </>
                                                }
                                                {!editingSearchKeywordsLiveContent && category.appData && category.appData.searchKeywordsAIContentPublishStatus === "published" &&
                                                    <Button
                                                        actionType="destructive"
                                                        type="button"
                                                        isLoading={revertingSearchKeywords}
                                                        disabled={disableButtons}
                                                        onClick={revertSearchKeywords}>Revert</Button>
                                                }
                                            </>
                                        }
                                        {!category.search_keywords &&
                                            <div className="description center">N/A</div>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {category.appData && category.appData.searchKeywordsAIContent &&
                                            <>
                                                {!editingSearchKeywordsAIContent &&
                                                    <>
                                                        <div className="description">{category.appData.searchKeywordsAIContent}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingSearchKeywordsAIContent(true);
                                                                setSearchKeywordsAIContentTempVal(category.appData.searchKeywordsAIContent);
                                                            }
                                                            }>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingSearchKeywords}
                                                            disabled={disableButtons}
                                                            onClick={generateSearchKeywords}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingSearchKeywords}
                                                            disabled={disableButtons}
                                                            onClick={publishSearchKeywords}>Publish</Button>
                                                    </>
                                                }
                                                {editingSearchKeywordsAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={searchKeywordsAIContentTempVal}
                                                                onChange={(e) => setSearchKeywordsAIContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingSearchKeywordsAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingSearchKeywordsAIContent}
                                                            onClick={updateSearchKeywordsAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!category.appData || !category.appData.searchKeywordsAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingSearchKeywords} onClick={generateSearchKeywords}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                    </Box>
                </>
            }
            {
                loading &&
                <ComponentLoading></ComponentLoading>
            }
        </div >
    );
}

export default CategortDetail;
