import React, { useEffect, useState } from 'react';
import { ArrowBackIcon } from '@bigcommerce/big-design-icons';
import { Link, H1, Panel, Text, Table } from '@bigcommerce/big-design';
import { Link as RouterLink } from 'react-router-dom';
import PageLoading from '../components/PageLoading';
import { Api } from '../services/Api';

const Invoices = (props) => {

    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const payments = await Api.get("/subscriptionApi-getPayments");
                setPayments(payments.sort((p1, p2) => {return p2.id - p1.id}));
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    return (
        <>
            {loading &&
                <PageLoading />
            }
            {!loading &&
                <>
                    <RouterLink to="/" className="no-underline"><Text color="secondary60"><ArrowBackIcon color="secondary60" /> Home</Text></RouterLink>
                    <H1>Invoices</H1>
                    <Panel>
                        <Table
                            selectable={false
                            }
                            columns={[
                                { width: '200px',  header: 'Payment Date', hash: 'paymentDate', render: (payment) => { return payment.payout_date } },
                                { width: '200px', header: 'Amount', hash: 'amount', render: (payment) => { return payment.amount + " " + payment.currency } },
                                { header: '', hash: 'url', render: (payment) => {return <Link href={payment.receipt_url} target="_blank">View</Link>} }
                            ]}
                            items={payments}
                        />
                    </Panel>
                </>
            }
        </>
    );
}
export default Invoices;


