import { Button, Checkbox, Fieldset, Flex, FlexItem, Form, FormGroup, H2, H3, Input, Radio, Stepper, Text } from "@bigcommerce/big-design";
import { useEffect, useState } from "react";
import ComponentLoading from "../ComponentLoading";
import { Api } from "../../services/Api";
import "./CreateBlogPost.css";
import BlogPost from "./BlogPost";

const CreateBlogPost = (props) => {
    const steps = ['Start', 'Select Topic', 'Save & Publish'];
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [topicMode, setTopicMode] = useState("ai-suggested");
    const [topic, setTopic] = useState("");
    const [selectedTitle, setSelectedTitle] = useState("");
    const [titleIdeas, setTitleIdeas] = useState([]);
    const [blogPost, setBlogPost] = useState();
    const [editingBlogPost, setEditingBlogPost] = useState(false);
    const [generateImage, setGenerateImage] = useState(true);
    const [generateMetaDescription, setGenerateMetaDescription] = useState(true);

    useEffect(() => {
        if (currentStep === 1) {
            if (topicMode === "ai-suggested" && titleIdeas.length === 0) {
                getSuggestions();
            }
        }
        else if (currentStep === 2) {
            setBlogPost(null);
            createBlogPost();
        }
    }, [currentStep])

    const getSuggestions = async () => {
        try {
            setLoading(true);
            const data = await Api.get("/blogAiApi-getTitleIdeas");
            setTitleIdeas(data);
        }
        finally {
            setLoading(false);
        }
    }

    const createBlogPost = async () => {
        try {
            setLoading(true);
            const data = await Api.post("/blogAiApi-createBlogPost", {
                title: topicMode === "ai-suggested" ? selectedTitle : null,
                topic: topicMode === "manual-entry" ? topic : null,
                generateImage: generateImage,
                generateMetaDescription: generateMetaDescription,
            });
            setBlogPost(data);
            if (props.onCreated) {
                props.onCreated();
            }
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className="create-blog-post">
            <Stepper currentStep={currentStep} steps={steps} />
            {currentStep === 0 &&
                <Form>
                    <Fieldset legend="What should be the blog post about?">
                        <FormGroup>
                            <Radio
                                checked={topicMode === "ai-suggested"}
                                label="Let AI suggest some topics (+1 credit)"
                                onChange={(e) => setTopicMode(e.target.value)}
                                value="ai-suggested"
                            />
                            <Radio
                                checked={topicMode === "manual-entry"}
                                label="No, thanks. I'll manually enter the topic"
                                onChange={(e) => setTopicMode(e.target.value)}
                                value="manual-entry"
                            />
                        </FormGroup>
                    </Fieldset>
                    <Fieldset legend="Other preferences">
                        <FormGroup>
                            <Checkbox
                                checked={generateImage}
                                label="Blog article should include an image (+4 credits)"
                                onChange={(e) => setGenerateImage(!generateImage)}
                            />
                            <Checkbox
                                checked={generateMetaDescription}
                                label="Generate meta description for the article (+1 credit)"
                                onChange={(e) => setGenerateMetaDescription(!generateMetaDescription)}
                            />
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 1 && topicMode === "ai-suggested" &&
                <Form>
                    {titleIdeas.length === 0 && loading &&
                        <>
                            <ComponentLoading title="AI is generating some suggestions. Please wait..."></ComponentLoading>
                        </>
                    }
                    {titleIdeas.length > 0 &&
                        <Fieldset legend="Choose one of the topics given below">
                            <FormGroup>
                                {titleIdeas.map(t =>
                                    <Radio
                                        checked={selectedTitle === t}
                                        label={t}
                                        onChange={(e) => setSelectedTitle(e.target.value)}
                                        value={t}
                                    />
                                )}
                            </FormGroup>
                        </Fieldset>
                    }
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="subtle" onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                            <Button disabled={!selectedTitle} variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 1 && topicMode === "manual-entry" &&
                <Form>
                    <Fieldset legend="Briefly enter the blog topic">
                        <FormGroup>
                            <div className="title-input">
                                <Input disabled={topicMode !== "manual-entry"} value={topic} onChange={(e) => setTopic(e.target.value)}></Input>
                            </div>
                        </FormGroup>
                    </Fieldset>
                    <Flex justifyContent="end">
                        <FlexItem>
                            <Button variant="subtle" onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                            <Button disabled={!topic} variant="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                        </FlexItem>
                    </Flex>
                </Form>
            }
            {currentStep === 2 &&
                <Form>
                    {!blogPost && loading &&
                        <>
                            <ComponentLoading title="AI is writing your blog post, the blog post will be visible in the list once it is ready. You can close the dialog or wait for the result. "></ComponentLoading>
                        </>
                    }
                    {blogPost &&
                        <BlogPost
                            blogPost={blogPost}
                            onEditingChange={(value) => setEditingBlogPost(value)}
                            onChange={(value) => {
                                setBlogPost(value);
                                if (props.onCreated) {
                                    props.onCreated();
                                }
                            }}
                        ></BlogPost>
                    }
                </Form>
            }
        </div>
    )
}
export default CreateBlogPost;
