import React from 'react';
import { ProgressCircle, Text } from '@bigcommerce/big-design';

const ComponentLoading = (props) => {

    return (
        <div className="component-loading">
            <ProgressCircle size="medium" />
            {props.title &&
                <Text>{props.title}</Text>
            }
        </div>
    );
}

export default ComponentLoading;
