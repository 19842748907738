import React, { useState, useEffect, useRef } from 'react';
import { Badge, Box, Button, Collapse, Flex, FlexItem, Form, FormGroup, H2, H4, Input, Panel, Small, Table, Tabs, Textarea } from '@bigcommerce/big-design';
import { Api } from '../../../services/Api';
import ComponentLoading from '../../ComponentLoading';
import SafeImg from '../../SafeImg';
import "./ProductDetail.css";
import { Utils } from '../../../services/Utils';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_KEY } from '../../../common/Constants';

const ProductDetail = (props) => {
    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(true);
    const [disableButtons, setDisableButtons] = useState(false);

    const [generatingProductDescription, setGeneratingProductDescription] = useState(false);
    const [publishingProductDescription, setPublishingProductDescription] = useState(false);
    const [revertingProductDescription, setRevertingProductDescription] = useState(false);
    const [editingProductDescriptionAIContent, setEditingProductDescriptionAIContent] = useState(false);
    const [editingProductDescriptionLiveContent, setEditingProductDescriptionLiveContent] = useState(false);
    const [updatingProductDescriptionAIContent, setUpdatingProductDescriptionAIContent] = useState(false);
    const [updatingProductDescriptionLiveContent, setUpdatingProductDescriptionLiveContent] = useState(false);

    const productDescriptionEditorRef = useRef(null);
    const aiProductDescriptionEditorRef = useRef(null);

    const [generatingMetaDescription, setGeneratingMetaDescription] = useState(false);
    const [publishingMetaDescription, setPublishingMetaDescription] = useState(false);
    const [revertingMetaDescription, setRevertingMetaDescription] = useState(false);
    const [editingMetaDescriptionAIContent, setEditingMetaDescriptionAIContent] = useState(false);
    const [editingMetaDescriptionLiveContent, setEditingMetaDescriptionLiveContent] = useState(false);
    const [updatingMetaDescriptionAIContent, setUpdatingMetaDescriptionAIContent] = useState(false);
    const [updatingMetaDescriptionLiveContent, setUpdatingMetaDescriptionLiveContent] = useState(false);

    const [metaDescriptionAIContentTempVal, setMetaDescriptionAIContentTempVal] = useState("");
    const [metaDescriptionLiveContentTempVal, setMetaDescriptionLiveContentTempVal] = useState("");

    const [generatingSearchKeywords, setGeneratingSearchKeywords] = useState(false);
    const [publishingSearchKeywords, setPublishingSearchKeywords] = useState(false);
    const [revertingSearchKeywords, setRevertingSearchKeywords] = useState(false);
    const [editingSearchKeywordsAIContent, setEditingSearchKeywordsAIContent] = useState(false);
    const [editingSearchKeywordsLiveContent, setEditingSearchKeywordsLiveContent] = useState(false);
    const [updatingSearchKeywordsAIContent, setUpdatingSearchKeywordsAIContent] = useState(false);
    const [updatingSearchKeywordsLiveContent, setUpdatingSearchKeywordsLiveContent] = useState(false);

    const [searchKeywordsAIContentTempVal, setSearchKeywordsAIContentTempVal] = useState("");
    const [searchKeywordsLiveContentTempVal, setSearchKeywordsLiveContentTempVal] = useState("");

    const [numberOfWords, setNumberOfWords] = useState();

    const [activeTab, setActiveTab] = useState("tab-product-description");

    useEffect(() => {
        const getProduct = async () => {
            try {
                setLoading(true);
                const data = await Api.get(`/catalogApi-getProduct?id=${props.product.id}`);
                setProduct(data);
            }
            finally {
                setLoading(false);
            }
        }
        getProduct();
    }, []);

    const getMainImage = () => {
        try {
            if (product && product.images) {
                let mainImg = product.images.find(i => i.is_thumbnail === true);
                if (!mainImg) {
                    mainImg = product.images[0];
                }
                return mainImg.url_standard;
            }
            return "";
        }
        catch {
            return "";
        }
    }

    const renderContentStatus = (field) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = product.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // productDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "productDescription") {
            if (!product.description) {
                quality = "empty"
            }
            else if (product.description.split(' ').length < 50) {
                quality = "too short";
            }
        }
        else if (field === "metaDescription") {
            if (!product.meta_description) {
                quality = "empty"
            }
            else if (product.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!product.search_keywords) {
                quality = "empty"
            }
            else if (product.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    const renderAIContentStatus = (field) => {
        let status = "not generated";
        let statusVariant = "secondary"
        let publishStatus = null;
        let publishStatusVariant = null;
        const appData = product.appData;
        if (appData) {
            const statusPropertyName = field + "AIContent";
            if (appData[statusPropertyName]) {
                status = "generated";
                statusVariant = "success";
            }

            const publishedPropertyName = field + "AIContentPublishStatus";  // productDescriptionAIContentPublishStatus, metaDescriptionAIContentPublishStatus
            if (appData[publishedPropertyName]) {
                publishStatus = appData[publishedPropertyName];
                if (publishStatus === "published") {
                    publishStatusVariant = "success";
                }
                else if (publishStatus === "not-published") {
                    publishStatusVariant = "warning";
                }
            }
        }
        return (
            <>
                <Badge label={status} variant={statusVariant}></Badge>
                <br />
                {publishStatus &&
                    <Badge label={publishStatus} variant={publishStatusVariant}></Badge>
                }
            </>
        )
    }

    const generateProductDescription = async () => {
        try {
            setGeneratingProductDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/aiApi-generateProductDescription`, {
                productId: product.id,
                numberOfWords: numberOfWords,
            });
            product.appData = data;
            setProduct(product);
        }
        finally {
            setDisableButtons(false);
            setGeneratingProductDescription(false);
        }
    }

    const publishProductDescription = async () => {
        try {
            setPublishingProductDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/catalogApi-publishAIProductDescription`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingProductDescription(false);
        }
    }

    const revertProductDescription = async () => {
        try {
            setRevertingProductDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/catalogApi-revertAIProductDescription`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingProductDescription(false);
        }
    }

    const updateProductDescriptionAIContent = async () => {
        try {
            setUpdatingProductDescriptionAIContent(true);
            setDisableButtons(true);
            const description = aiProductDescriptionEditorRef.current.getContent()
            await Api.post(`/catalogApi-updateAIProductDescription`, { productId: product.id, description: description });
            product.appData.productDescriptionAIContent = description;
            setProduct(product);
            setEditingProductDescriptionAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingProductDescriptionAIContent(false);
        }
    }

    const updateProductDescriptionLiveContent = async () => {
        try {
            setUpdatingProductDescriptionLiveContent(true);
            setDisableButtons(true);
            const description = productDescriptionEditorRef.current.getContent()
            await Api.post(`/catalogApi-updateProductDescription`, { productId: product.id, description: description });
            product.description = description;
            setProduct(product);
            setEditingProductDescriptionLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingProductDescriptionLiveContent(false);
        }
    }

    const generateMetaDescription = async () => {
        try {
            setGeneratingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/aiApi-generateMetaDescription`, { productId: product.id });
            product.appData = data;
            setProduct(product);
        }
        finally {
            setDisableButtons(false);
            setGeneratingMetaDescription(false);
        }
    }

    const publishMetaDescription = async () => {
        try {
            setPublishingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/catalogApi-publishAIMetaDescription`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingMetaDescription(false);
        }
    }

    const revertMetaDescription = async () => {
        try {
            setRevertingMetaDescription(true);
            setDisableButtons(true);
            const data = await Api.post(`/catalogApi-revertAIMetaDescription`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingMetaDescription(false);
        }
    }

    const updateMetaDescriptionAIContent = async () => {
        try {
            setUpdatingMetaDescriptionAIContent(true);
            setDisableButtons(true);
            const description = metaDescriptionAIContentTempVal;
            await Api.post(`/catalogApi-updateAIMetaDescription`, { productId: product.id, description: description });
            product.appData.metaDescriptionAIContent = description;
            setProduct(product);
            setEditingMetaDescriptionAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingMetaDescriptionAIContent(false);
        }
    }

    const updateMetaDescriptionLiveContent = async () => {
        try {
            setUpdatingMetaDescriptionLiveContent(true);
            setDisableButtons(true);
            const description = metaDescriptionLiveContentTempVal;
            await Api.post(`/catalogApi-updateMetaDescription`, { productId: product.id, description: description });
            product.meta_description = description;
            setProduct(product);
            setEditingMetaDescriptionLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingMetaDescriptionLiveContent(false);
        }
    }


    const generateSearchKeywords = async () => {
        try {
            setGeneratingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/aiApi-generateSearchKeywords`, { productId: product.id });
            product.appData = data;
            setProduct(product);
        }
        finally {
            setDisableButtons(false);
            setGeneratingSearchKeywords(false);
        }
    }

    const publishSearchKeywords = async () => {
        try {
            setPublishingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/catalogApi-publishAISearchKeywords`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setPublishingSearchKeywords(false);
        }
    }

    const revertSearchKeywords = async () => {
        try {
            setRevertingSearchKeywords(true);
            setDisableButtons(true);
            const data = await Api.post(`/catalogApi-revertAISearchKeywords`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setRevertingSearchKeywords(false);
        }
    }

    const updateSearchKeywordsAIContent = async () => {
        try {
            setUpdatingSearchKeywordsAIContent(true);
            setDisableButtons(true);
            const searchKeywords = searchKeywordsAIContentTempVal;
            await Api.post(`/catalogApi-updateAISearchKeywords`, { productId: product.id, searchKeywords: searchKeywords });
            product.appData.searchKeywordsAIContent = searchKeywords;
            setProduct(product);
            setEditingSearchKeywordsAIContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingSearchKeywordsAIContent(false);
        }
    }

    const updateSearchKeywordsLiveContent = async () => {
        try {
            setUpdatingSearchKeywordsLiveContent(true);
            setDisableButtons(true);
            const searchKeywords = searchKeywordsLiveContentTempVal;
            await Api.post(`/catalogApi-updateSearchKeywords`, { productId: product.id, searchKeywords: searchKeywords });
            product.search_keywords = searchKeywords;
            setProduct(product);
            setEditingSearchKeywordsLiveContent(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingSearchKeywordsLiveContent(false);
        }
    }

    return (
        <div className="product-detail">
            {!loading &&
                <>
                    <Box marginTop="medium" display="flex">
                        <Flex flexGap={"24px"}>
                            <FlexItem>
                                <div className="img-container">
                                    <SafeImg src={getMainImage()} />
                                </div>
                            </FlexItem>
                            <FlexItem>
                                <H2 marginBottom="medium">{product.name}</H2>
                                <Box marginTop="large">
                                    <Table
                                        id="content-status"
                                        columns={[
                                            { header: '', hash: 'item', render: ({ name }) => name },
                                            { header: 'Live Content', hash: 'status', render: ({ field }) => renderContentStatus(field) },
                                            { header: 'AI Content', hash: 'name', render: ({ field }) => renderAIContentStatus(field) },
                                        ]}
                                        items={[
                                            { field: "productDescription", name: "Product Description" },
                                            { field: "metaDescription", name: "Meta Description" },
                                            { field: "searchKeywords", name: "Search Keywords" },
                                        ]}
                                    >
                                    </Table>
                                </Box>
                            </FlexItem>
                        </Flex>
                    </Box>
                    <Box marginTop="medium">
                        <Tabs
                            activeTab={activeTab}
                            aria-label="Product Tab Content"
                            id="tab"
                            items={[
                                { id: "tab-product-description", ariaControls: "product-description", title: "Product Description" },
                                { id: "tab-meta-description", ariaControls: "meta-description", title: "Meta Description" },
                                { id: "tab-search-keywords", ariaControls: "search-keywords", title: "Search Keywords" },
                            ]}
                            onTabClick={setActiveTab}
                        />
                        {activeTab === "tab-product-description" &&
                            <Flex id="product-description">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {product.description &&
                                            <>
                                                {!editingProductDescriptionLiveContent &&
                                                    <>
                                                        <div className="description" dangerouslySetInnerHTML={{ __html: Utils.sanitize(product.description) }}></div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={() => setEditingProductDescriptionLiveContent(true)}>Edit</Button>
                                                    </>
                                                }
                                                {editingProductDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Editor
                                                                tinymceScriptSrc='/tinymce/tinymce.min.js'
                                                                apiKey={TINY_MCE_KEY}
                                                                onInit={(evt, editor) => productDescriptionEditorRef.current = editor}
                                                                initialValue={product.description}
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | blocks | ' +
                                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingProductDescriptionLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingProductDescriptionLiveContent}
                                                            onClick={updateProductDescriptionLiveContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {!product.description &&
                                            <div className="description center">N/A</div>
                                        }
                                        {!editingProductDescriptionLiveContent && product.appData && product.appData.productDescriptionLiveContentStatus && product.appData.productDescriptionLiveContentStatus !== "original" &&
                                            <Button
                                                actionType="destructive"
                                                type="button"
                                                isLoading={revertingProductDescription}
                                                disabled={disableButtons}
                                                onClick={revertProductDescription}>Revert</Button>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {product.appData && product.appData.productDescriptionAIContent &&
                                            <>
                                                {!editingProductDescriptionAIContent &&
                                                    <>
                                                        <div className="description" dangerouslySetInnerHTML={{ __html: Utils.sanitize(product.appData.productDescriptionAIContent) }}></div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={() => setEditingProductDescriptionAIContent(true)}>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingProductDescription}
                                                            disabled={disableButtons}
                                                            onClick={generateProductDescription}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            marginRight="medium"
                                                            isLoading={publishingProductDescription}
                                                            disabled={disableButtons}
                                                            onClick={publishProductDescription}>Publish</Button>
                                                    </>
                                                }
                                                {editingProductDescriptionAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Editor
                                                                tinymceScriptSrc='/tinymce/tinymce.min.js'
                                                                apiKey={TINY_MCE_KEY}
                                                                onInit={(evt, editor) => aiProductDescriptionEditorRef.current = editor}
                                                                initialValue={product.appData.productDescriptionAIContent}
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | blocks | ' +
                                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingProductDescriptionAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingProductDescriptionAIContent}
                                                            onClick={updateProductDescriptionAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!product.appData || !product.appData.productDescriptionAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button marginRight={"medium"} type="button" isLoading={generatingProductDescription} onClick={generateProductDescription}>Generate</Button>
                                            </>
                                        }
                                        {props.store.advancedSettings && props.store.advancedSettings.displayProductSpecificSettings &&
                                            <Collapse title={"Settings"}>
                                                <div className="settings">
                                                    <Form fullWidth={true}>
                                                        <FormGroup>
                                                            <Input label="Content Length (words)" value={numberOfWords} onChange={(e) => setNumberOfWords(e.target.value)} required type="text"></Input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Small>*The input is a recommendation rather than a constraint. AI may not follow the setting exactly.</Small>
                                                        </FormGroup>
                                                    </Form>
                                                </div>
                                            </Collapse>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                        {activeTab === "tab-meta-description" &&
                            <Flex id="meta-description">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {product.meta_description &&
                                            <>
                                                {!editingMetaDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">{product.meta_description}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingMetaDescriptionLiveContent(true);
                                                                setMetaDescriptionLiveContentTempVal(product.meta_description);
                                                            }
                                                            }>Edit</Button>
                                                    </>
                                                }
                                                {editingMetaDescriptionLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={metaDescriptionLiveContentTempVal}
                                                                onChange={(e) => setMetaDescriptionLiveContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingMetaDescriptionLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingMetaDescriptionLiveContent}
                                                            onClick={updateMetaDescriptionLiveContent}>Apply</Button>
                                                    </>
                                                }
                                                {!editingMetaDescriptionLiveContent && product.appData && product.appData.metaDescriptionAIContentPublishStatus === "published" &&
                                                    <Button
                                                        actionType="destructive"
                                                        type="button"
                                                        isLoading={revertingMetaDescription}
                                                        disabled={disableButtons}
                                                        onClick={revertMetaDescription}>Revert</Button>
                                                }
                                            </>
                                        }
                                        {!product.meta_description &&
                                            <div className="description center">N/A</div>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {product.appData && product.appData.metaDescriptionAIContent &&
                                            <>
                                                {!editingMetaDescriptionAIContent &&
                                                    <>
                                                        <div className="description">{product.appData.metaDescriptionAIContent}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingMetaDescriptionAIContent(true);
                                                                setMetaDescriptionAIContentTempVal(product.appData.metaDescriptionAIContent);
                                                            }
                                                            }>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingMetaDescription}
                                                            disabled={disableButtons}
                                                            onClick={generateMetaDescription}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingMetaDescription}
                                                            disabled={disableButtons}
                                                            onClick={publishMetaDescription}>Publish</Button>
                                                    </>
                                                }
                                                {editingMetaDescriptionAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={metaDescriptionAIContentTempVal}
                                                                onChange={(e) => setMetaDescriptionAIContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingMetaDescriptionAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingMetaDescriptionAIContent}
                                                            onClick={updateMetaDescriptionAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!product.appData || !product.appData.metaDescriptionAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingMetaDescription} onClick={generateMetaDescription}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                        {activeTab === "tab-search-keywords" &&
                            <Flex id="search-keywords">
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>Live Content</H4>
                                        {product.search_keywords &&
                                            <>
                                                {!editingSearchKeywordsLiveContent &&
                                                    <>
                                                        <div className="description">{product.search_keywords}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingSearchKeywordsLiveContent(true);
                                                                setSearchKeywordsLiveContentTempVal(product.search_keywords);
                                                            }
                                                            }>Edit</Button>
                                                    </>
                                                }
                                                {editingSearchKeywordsLiveContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={searchKeywordsLiveContentTempVal}
                                                                onChange={(e) => setSearchKeywordsLiveContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingSearchKeywordsLiveContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingSearchKeywordsLiveContent}
                                                            onClick={updateSearchKeywordsLiveContent}>Apply</Button>
                                                    </>
                                                }
                                                {!editingSearchKeywordsLiveContent && product.appData && product.appData.searchKeywordsAIContentPublishStatus === "published" &&
                                                    <Button
                                                        actionType="destructive"
                                                        type="button"
                                                        isLoading={revertingSearchKeywords}
                                                        disabled={disableButtons}
                                                        onClick={revertSearchKeywords}>Revert</Button>
                                                }
                                            </>
                                        }
                                        {!product.search_keywords &&
                                            <div className="description center">N/A</div>
                                        }
                                    </Box>
                                </FlexItem>
                                <FlexItem style={{ textAlign: "center" }} flexBasis="50%" flexGrow={1} margin="small">
                                    <Box border="box" padding="medium" backgroundColor="secondary20">
                                        <H4>AI Content</H4>
                                        {product.appData && product.appData.searchKeywordsAIContent &&
                                            <>
                                                {!editingSearchKeywordsAIContent &&
                                                    <>
                                                        <div className="description">{product.appData.searchKeywordsAIContent}</div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => {
                                                                setEditingSearchKeywordsAIContent(true);
                                                                setSearchKeywordsAIContentTempVal(product.appData.searchKeywordsAIContent);
                                                            }
                                                            }>Edit</Button>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            isLoading={generatingSearchKeywords}
                                                            disabled={disableButtons}
                                                            onClick={generateSearchKeywords}>Re-Generate</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={publishingSearchKeywords}
                                                            disabled={disableButtons}
                                                            onClick={publishSearchKeywords}>Publish</Button>
                                                    </>
                                                }
                                                {editingSearchKeywordsAIContent &&
                                                    <>
                                                        <div className="description">
                                                            <Textarea
                                                                value={searchKeywordsAIContentTempVal}
                                                                onChange={(e) => setSearchKeywordsAIContentTempVal(e.target.value)}
                                                            ></Textarea>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            disabled={disableButtons}
                                                            onClick={() => setEditingSearchKeywordsAIContent(false)}>Cancel</Button>
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            isLoading={updatingSearchKeywordsAIContent}
                                                            onClick={updateSearchKeywordsAIContent}>Apply</Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!product.appData || !product.appData.searchKeywordsAIContent) &&
                                            <>
                                                <Small>AI Content not yet generated</Small>
                                                <Button type="button" isLoading={generatingSearchKeywords} onClick={generateSearchKeywords}>Generate</Button>
                                            </>
                                        }
                                    </Box>
                                </FlexItem>
                            </Flex>
                        }
                    </Box>
                </>
            }
            {
                loading &&
                <ComponentLoading></ComponentLoading>
            }
        </div >
    );
}

export default ProductDetail;
