import { Badge, Box, Button, Link, Modal, Small } from "@bigcommerce/big-design";
import React, { useEffect, useState, useRef } from "react";
import { Api } from "../../../services/Api";
import { SharedState } from "../../../services/SharedState";
import ComponentLoading from "../../ComponentLoading";
import MemoizedTable from "../../MemoizedTable";
import "./Brands.css";
import SafeImg from "../../SafeImg";
import BrandDetail from "./BrandDetail";
import SearchForm from "../../SearchForm";

const AIModifiedBrands = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [selectedBrand, setSelectedBrand] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchTimestamp, setSearchTimestamp] = useState(0);
    const pageFirstElements = useRef([]);

    const getData = async (page) => {
        try {
            let startAt = null;
            let startAfter = null;

            if (page === 1) {
                // loading first page
                pageFirstElements.current = [];
            }
            if (page > currentPage) {
                // going forward
                pageFirstElements.current.push(items[0].appData.docId);
                startAfter = items[items.length - 1].appData.docId;
            }
            else if (page < currentPage) {
                // going backwards
                startAt = pageFirstElements.current.pop();
            }

            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);

            let brands = "";
            if (searchKeyword) {
                const allBrands = SharedState.getValue("brands");
                const brandsFiltered = allBrands.filter((c) => {
                    return (c.name).toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
                });
                brands = brandsFiltered.map(c => c.id).join(",");
                if(!brands){
                    brands = "-1";
                }
            }

            const data = await Api.get("/brandApi-getAIModifiedBrands", {
                brands: brands,
                startAt: startAt,
                startAfter: startAfter,
                pageSize: pageSize
            });
            if (data.brands) {
                setItems(data.brands);
                setItemsTimestamp(new Date().getTime());
                if (pagingEnabled()) {
                    if (data.brands.length === pageSize) {
                        setTotalItems(totalItems + (page + 1) * pageSize);
                    }
                    else {
                        setTotalItems(totalItems + data.brands.length);
                    }
                }
                else {
                    // paging is disabled when search input is submitted
                    setTotalItems(data.brands.length);
                }
                setCurrentPage(page);
            }
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
    }, [searchKeyword, searchTimestamp]);

    useEffect(() => {
        if (selectedBrand !== null) {
            const brandDetailElement = document.querySelector(".brand-detail");
            brandDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedBrand]);

    const onSearch = (keyword, brands) => {
        setSearchKeyword(keyword);
        setSearchTimestamp(new Date().getTime());
    };

    const renderBrandImage = (brand) => {
        try {
            const thumb = brand.images.find(i => i.is_thumbnail === true);
            return <SafeImg className="thumbnail" src={thumb.url_tiny} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const pagingEnabled = () => {
        if (searchKeyword) {
            return false;
        }

        return true;
    }

    const renderName = (brand) => {
        return (
            <>
                <div className="brand-name">
                    <Link ellipsis={true} onClick={() => setSelectedBrand(brand)}>{brand.name}</Link>
                </div>
                <div className="brand-name">
                    <Small ellipsis={true}>{brand.path}</Small>
                </div>
            </>
        )
    }

    const renderContentStatus = (field, brand) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = brand.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // brandDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "metaDescription") {
            if (!brand.meta_description) {
                quality = "empty"
            }
            else if (brand.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!brand.search_keywords) {
                quality = "empty"
            }
            else if (brand.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    return (
        <>
            <div className="brands">
                <Box marginBottom="medium">
                    <SearchForm onSubmit={onSearch}></SearchForm>
                </Box>
                <MemoizedTable
                    columns={[
                        { header: '', hash: 'thumbnail', width: "100px", render: (brand) => renderBrandImage(brand) },
                        { header: 'Name', hash: 'name', width: "500px", render: (brand) => renderName(brand) },                        
                        { header: 'Meta Description', width: "150px", hash: 'metaDescription', render: (brand) => renderContentStatus("metaDescription", brand) },
                        { header: 'Search Keywords', width: "150px", hash: 'searchKeywords', render: (brand) => renderContentStatus("searchKeywords", brand) },
                    ]}
                    items={items}
                    timestamp={itemsTimestamp}
                    pagination={pagingEnabled() && {
                        itemsPerPageOptions: [10, 20, 50],
                        currentPage: currentPage,
                        itemsPerPage: pageSize,
                        totalItems: totalItems,
                        onPageChange: (page) => getData(page),
                        onItemsPerPageChange: (range) => {
                            setPageSize(range);
                            setTimeout(() => {
                                getData(currentPage);
                            }, 500);
                        },
                        getRangeLabel: (start, end, totalItems) => { return pageSize }

                    }}
                    stickyHeader
                    loading={loading}
                />
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedBrand}
                onClose={() => setSelectedBrand(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <BrandDetail brand={selectedBrand}></BrandDetail>
            </Modal>
        </>
    );
}
export default AIModifiedBrands;
