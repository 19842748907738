import axios from 'axios';
import { Utils } from './Utils';

export class Api {
  static BASE_URL = "https://us-central1-bc-ai-copywriter.cloudfunctions.net";
  // static BASE_URL = "http://localhost:5001/bc-ai-copywriter/us-central1";

  static get = (url, params) => {
    return this.request(url, "GET", params, null);
  }

  static post = (url, data) => {
    return this.request(url, "POST", null, data);
  }

  static put = (url, data) => {
    return this.request(url, "PUT", null, data);
  }

  static delete = (url, params) => {
    return this.request(url, "DELETE", params, null);
  }

  static request = (url, method, params, data, cancelFnc = null) => {
    return new Promise((resolve, reject) => {
      let config = {
        url: this.BASE_URL + url,
        method: method,
        params: params,
        data: data
      }

      if (cancelFnc) {
        config.cancelToken = new axios.CancelToken(function executor(c) {
          cancelFnc = c;
        });
      }

      if (localStorage.getItem("auth-token") !== null) {
        config.headers = { 'Authorization': 'Bearer ' + localStorage.getItem("auth-token") }
        this.send(config, resolve, reject);
      }
      else {
        this.send(config, resolve, reject);
      }
    });
  }

  static send = (config, resolve, reject) => {
    axios.request(config).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          let messages = [];
          if (!Array.isArray(error.response.data)) {
            messages.push(error.response.data);
          }
          else {
            messages = error.response.data;
          }
          Utils.error(messages);
        }
        else if (error.response.status === 500) {
          Utils.error("An unexpected error has occured.");
        }
        else if (error.response.status === 401) {
          Utils.error("You are not authorized to perform this operation.");
        }
        else if (error.response.status === 403) {
          if(error.response.data === "inactive subscription"){
            Utils.error("Your subscription is not active.");
          }
          else{
            Utils.error("You are not authorized to perform this operation.");
          }
        }
      }
      reject(error);
    });
  }
}
