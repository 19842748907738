import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { useEffect, useState } from 'react';
import { AlertsManager, createAlertsManager, Flex, FlexItem, Message } from '@bigcommerce/big-design';
import ErrorBoundary from './common/ErrorBoundary';
import AppRoutes from './AppRoutes';
import { SharedState } from './services/SharedState';
import { Api } from './services/Api';
import HeaderMenu from './components/HeaderMenu';
import { StoreClient } from './services/StoreClient';

export const alertManager = createAlertsManager();

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [store, setStore] = useState();
  const Paddle = window.Paddle;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const store = await StoreClient.getStore();
        setStore(store);
      }
      catch {
        setError("Unable to authenticate BigCommerce store");
      }
      finally {
        setLoading(false);
      }
    }

    Paddle.Setup({ vendor: 113143 });
    const query = new URLSearchParams(window.location.search)
    let storeHash = query.get("h");
    let accessToken = query.get("t");

    if (storeHash !== null && accessToken !== null) {
      localStorage.setItem("auth-token", storeHash + " " + accessToken);
      localStorage.setItem("store-hash", storeHash);
      window.location.href = "/";
    }
    else {
      if (localStorage.getItem("auth-token") === null || localStorage.getItem("store-hash") === null) {
        setError("Invalid number of parameters");
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    const getCategories = async (page) => {
      const data = await Api.get("/catalogApi-getCategories", { page: page, pageSize: 250 });
      const tmpCategories = SharedState.getValue("tmpCategories");
      if (page === 1 || !tmpCategories) {
        SharedState.setValue("tmpCategories", data.categories);
      }
      else if (tmpCategories) {
        SharedState.setValue("tmpCategories", [...tmpCategories, ...data.categories]);
      }
      if (data && data.hasNextPage) {
        getCategories(page + 1);
      }
      if (data && !data.hasNextPage) {
        SharedState.setValue("categories", SharedState.getValue("tmpCategories"));
      }
    }

    const getBrands = async (page) => {
      const data = await Api.get("/brandApi-getBrands", { page: page, pageSize: 250 });
      const tmpBrands = SharedState.getValue("tmpBrands");
      if (page === 1 || !tmpBrands) {
        SharedState.setValue("tmpBrands", data.brands);
      }
      else if (tmpBrands) {
        SharedState.setValue("tmpBrands", [...tmpBrands, ...data.brands]);
      }
      if (data && data.hasNextPage) {
        getBrands(page + 1);
      }
      if (data && !data.hasNextPage) {
        SharedState.setValue("brands", SharedState.getValue("tmpBrands"));
      }
    }

    if (!loading && error === "") {
      getCategories(1);
      getBrands(1);
    }
  }, [loading, error]);


  return (
    <>
      <AlertsManager manager={alertManager}></AlertsManager>
      {!loading && error === "" &&
        <Router basename="/">
          <>
            <ErrorBoundary>
              <div className="top-bar">
                <Flex backgroundColor="white" borderBottom="box" alignItems="center" alignContent="start" justifyContent="space-between" padding="xSmall">
                  <FlexItem>
                    <img src={process.env.PUBLIC_URL + "/assets/img/logo.png"} alt="AI Copywriter" height={32} />
                  </FlexItem>
                  <FlexItem>
                    <HeaderMenu store={store}></HeaderMenu>
                  </FlexItem>
                </Flex>
              </div>
              <div className="content">
                <AppRoutes store={store}>
                </AppRoutes>
              </div>
            </ErrorBoundary>
          </>
        </Router>
      }
      {
        !loading && error !== "" &&
        <Message type="error" header="Error" messages={[{ text: error }]} marginVertical="medium" />
      }
    </>
  )
}

export default App;
