import { Flex, FlexItem, Message, Panel, PillTabs } from "@bigcommerce/big-design";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Quota from "./Quota";

const AppNavigation = (props) => {
    const [activePills, setActivePills] = useState([]);
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        let pathname = location.pathname.replace("/", "");
        if (pathname === "") {
            pathname = "description-writer";
        }
        setActivePills(pathname);
    }, [location]);

    const pillItems = [
        { title: 'Product Writer', id: 'description-writer' },
        { title: 'Category Writer', id: 'category-writer' },
        { title: 'Brand Writer', id: 'brand-writer' },
        { title: 'Blog Writer', id: 'blog-writer' },
        { title: 'Social Media Writer', id: 'social-media-writer' },
        { title: 'FAQ Writer', id: 'faq-writer' },
    ];

    const onPillClick = (id) => {
        setActivePills([id]);
        navigate("/" + id);
    }
    return (
        <Panel margin="small">
            <Flex justifyContent={"space-between"}>
                <FlexItem flexBasis={"65%"}>
                    <PillTabs activePills={activePills} items={pillItems} onPillClick={onPillClick} />
                </FlexItem>
                <FlexItem flexShrink={3}>
                    <Quota store={props.store}></Quota>
                </FlexItem>
            </Flex>
            {props.store && props.store.storeHash === "hgqe22dcgs" &&
                <Message type="warning" header="We have been trying to reach you!" messages={[{ text: "Our replies to your support messages are probably in your spam/junk folder." }]} marginVertical="medium" />
            }
        </Panel>
    );
}
export default AppNavigation;
