import { Badge, Box, Button, Link, Modal, Small } from "@bigcommerce/big-design";
import React, { useEffect, useState, useRef } from "react";
import { Api } from "../../../services/Api";
import { SharedState } from "../../../services/SharedState";
import ComponentLoading from "../../ComponentLoading";
import MemoizedTable from "../../MemoizedTable";
import ProductDetail from "./ProductDetail";
import "./Products.css";
import ProductSearchForm from "./ProductSearchForm";
import SafeImg from "../../SafeImg";

const AIModifiedProducts = (props) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [categories, setCategories] = useState(SharedState.getValue("categories"));
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchCategories, setSearchCategories] = useState([]);
    const [searchBrand, setSearchBrand] = useState();
    const [searchTimestamp, setSearchTimestamp] = useState(0);
    const pageFirstElements = useRef([]);

    const getData = async (page) => {
        try {
            let startAt = null;
            let startAfter = null;

            if (page === 1) {
                // loading first page
                pageFirstElements.current = [];
            }
            if (page > currentPage) {
                // going forward
                pageFirstElements.current.push(items[0].appData.docId);
                startAfter = items[items.length - 1].appData.docId;
            }
            else if (page < currentPage) {
                // going backwards
                startAt = pageFirstElements.current.pop();
            }

            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);
            const data = await Api.get("/catalogApi-getAIModifiedProducts", {
                keyword: searchKeyword,
                categories: searchCategories.join(","),
                brand: searchBrand,
                startAt: startAt,
                startAfter: startAfter,
                pageSize: pageSize
            });
            if (data.products) {
                setItems(data.products);
                setItemsTimestamp(new Date().getTime());
                if (pagingEnabled()) {
                    if (data.products.length === pageSize) {
                        setTotalItems(totalItems + (page + 1) * pageSize);
                    }
                    else {
                        setTotalItems(totalItems + data.products.length);
                    }
                }
                else {
                    // paging is disabled when search input is submitted
                    setTotalItems(data.products.length);
                }
                setCurrentPage(page);
            }
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
        SharedState.subscribe("categories", (key, value) => {
            if (key === "categories") {
                setCategories(value);
            }
        });
    }, [searchCategories, searchBrand, searchKeyword, searchTimestamp]);

    useEffect(() => {
        if (selectedProduct !== null) {
            const productDetailElement = document.querySelector(".product-detail");
            productDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedProduct])

    const onSearch = (keyword, categories, brand) => {
        setSearchKeyword(keyword);
        setSearchBrand(brand);
        setSearchCategories(categories);
        setSearchTimestamp(new Date().getTime());
    };

    const renderProductImage = (product) => {
        try {
            const thumb = product.images.find(i => i.is_thumbnail === true);
            return <SafeImg className="thumbnail" src={thumb.url_tiny} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const pagingEnabled = () => {
        if (searchKeyword || searchCategories.length > 0 || searchBrand) {
            return false;
        }

        return true;
    }

    const renderName = (product) => {
        const productCategories = categories ? categories.filter(c => product.categories.indexOf(c.id) >= 0).map(c => c.name) : [];
        return (
            <>
                <div className="product-name">
                    <Link ellipsis={true} onClick={() => setSelectedProduct(product)}>{product.name}</Link>
                </div>
                <div className="product-name">
                    <Small ellipsis={true}>{productCategories.join('/')}</Small>
                </div>
            </>
        )
    }

    const renderContentStatus = (field, product) => {
        let status = "original";
        let variant = "secondary"
        let quality = null;
        const appData = product.appData;
        if (appData) {
            const publishedPropertyName = field + "LiveContentStatus"; // productDescriptionLiveContentStatus, metaDescriptionLiveContentStatus           
            if (appData[publishedPropertyName]) {
                status = appData[publishedPropertyName];
            }

            if (status === "ai-generated") {
                variant = "success";
            }
        }

        if (field === "productDescription") {
            if (!product.description) {
                quality = "empty"
            }
            else if (product.description.split(' ').length < 50) {
                quality = "too short";
            }
        }
        else if (field === "metaDescription") {
            if (!product.meta_description) {
                quality = "empty"
            }
            else if (product.meta_description.length < 50) {
                quality = "too short";
            }
        }
        else if (field === "searchKeywords") {
            if (!product.search_keywords) {
                quality = "empty"
            }
            else if (product.search_keywords.split(',').length < 5) {
                quality = "too short";
            }
        }
        return (
            <>
                <Badge label={status} variant={variant}></Badge>
                <br />
                {quality && <Badge label={quality} variant="danger" />}
            </>
        )
    }

    return (
        <>
            <div className="products">
                <Box marginBottom="medium">
                    <ProductSearchForm onSubmit={onSearch}></ProductSearchForm>
                </Box>
                {itemsTimestamp &&
                    <MemoizedTable
                        columns={[
                            { header: '', hash: 'thumbnail', width: "100px", render: (product) => renderProductImage(product) },
                            { header: 'Sku', hash: 'sku', width: "200px", render: ({ sku }) => sku },
                            { header: 'Name', hash: 'name', width: "500px", render: (product) => renderName(product) },
                            { header: 'Product Description', width: "150px", hash: 'prodDescription', render: (product) => renderContentStatus("productDescription", product) },
                            { header: 'Meta Description', width: "150px", hash: 'metaDescription', render: (product) => renderContentStatus("metaDescription", product) },
                            { header: 'Search Keywords', width: "150px", hash: 'searchKeywords', render: (product) => renderContentStatus("searchKeywords", product) },
                        ]}
                        items={items}
                        timestamp={itemsTimestamp}
                        pagination={pagingEnabled() && {
                            itemsPerPageOptions: [10, 20, 50],
                            currentPage: currentPage,
                            itemsPerPage: pageSize,
                            totalItems: totalItems,
                            onPageChange: (page) => getData(page),
                            onItemsPerPageChange: (range) => {
                                setPageSize(range);
                                setTimeout(() => {
                                    getData(currentPage);
                                }, 500);
                            },
                            getRangeLabel: (start, end, totalItems) => { return pageSize }

                        }}
                        stickyHeader
                        loading={loading}
                    />
                }
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedProduct}
                onClose={() => setSelectedProduct(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <ProductDetail store={props.store} product={selectedProduct}></ProductDetail>
            </Modal>
        </>
    );
}
export default AIModifiedProducts;
