import React, { useState, useEffect, useRef } from 'react';
import { Badge, Box, Button, Flex, FlexItem, FormGroup, H2, H4, Small, Input, Textarea, Panel } from '@bigcommerce/big-design';
import { AddIcon, ArrowDownwardIcon, ArrowUpwardIcon, DeleteIcon } from '@bigcommerce/big-design-icons';
import { Api } from '../../services/Api';
import ComponentLoading from '../ComponentLoading';
import SafeImg from '../SafeImg';
import "./FAQDetail.css";
import Dialog from '../Dialog';

const FAQDetail = (props) => {

    const [product, setProduct] = useState();
    const [tempItems, setTempItems] = useState();
    const [loading, setLoading] = useState(true);
    const [disableButtons, setDisableButtons] = useState(false);

    const [generatingFAQ, setGeneratingFAQ] = useState(false);
    const [publishingFAQ, setPublishingFAQ] = useState(false);
    const [unpublishingFAQ, setUnpublishingFAQ] = useState(false);
    const [editingFAQ, setEditingFAQ] = useState(false);
    const [updatingFAQ, setUpdatingFAQ] = useState(false);

    const productDescriptionEditorRef = useRef(null);
    const aiProductDescriptionEditorRef = useRef(null);

    useEffect(() => {
        const getProduct = async () => {
            try {
                setLoading(true);
                const data = await Api.get(`/faqApi-getProduct?id=${props.product.id}`);
                setProduct(data);
            }
            finally {
                setLoading(false);
            }
        }
        getProduct();
    }, []);

    const getMainImage = () => {
        try {
            if (product && product.images) {
                let mainImg = product.images.find(i => i.is_thumbnail === true);
                if (!mainImg) {
                    mainImg = product.images[0];
                }
                return mainImg.url_standard;
            }
            return "";
        }
        catch {
            return "";
        }
    }

    const renderStatus = () => {
        let contentVariant = "secondary";
        let contentStatus = "not-generated";
        let publishVariant = "secondary";
        let publishStatus = "not-published";
        const faqData = product.faq;
        if (faqData) {
            if (faqData.status === "published") {
                publishVariant = "success";
            }
            else if (faqData.status === "not-published") {
                publishVariant = "secondary";
            }
            else if (faqData.status === "outdated") {
                publishVariant = "warning";
            }
            publishStatus = faqData.status;
            contentStatus = "generated";
            contentVariant = "success";
        }

        return (
            <>
                <Badge label={contentStatus} variant={contentVariant} marginRight={"small"}></Badge>
                <Badge label={publishStatus} variant={publishVariant}></Badge>
            </>
        );
    }

    const generateFAQ = async () => {
        try {
            setGeneratingFAQ(true);
            setDisableButtons(true);
            const data = await Api.post(`/aiApi-generateProductFAQ`, { productId: product.id });
            product.faq = data;
            setProduct(product);
        }
        finally {
            setDisableButtons(false);
            setGeneratingFAQ(false);
        }
    }

    const publishFAQ = async () => {
        try {
            setUpdatingFAQ(true);
            setDisableButtons(true);
            const data = await Api.post(`/faqApi-publishFAQ`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setUpdatingFAQ(false);
        }
    }

    const unpublishFAQ = async () => {
        try {
            setUpdatingFAQ(true);
            setDisableButtons(true);
            const data = await Api.post(`/faqApi-unpublishFAQ`, { productId: product.id });
            setProduct(data);
        }
        finally {
            setDisableButtons(false);
            setUpdatingFAQ(false);
        }
    }

    const updateFAQ = async () => {
        try {
            setUpdatingFAQ(true);
            setDisableButtons(true);
            const result = await Api.post(`/faqApi-updateFAQ`, { ...product.faq, items: tempItems });
            product.faq = result;
            setProduct(product);
            setEditingFAQ(false);
        }
        finally {
            setDisableButtons(false);
            setUpdatingFAQ(false);
        }
    }

    return (
        <div className="faq-detail">
            {!loading &&
                <>
                    <Box marginTop="medium" display="flex">
                        <Flex flexGap={"24px"}>
                            <FlexItem>
                                <div className="img-container">
                                    <SafeImg src={getMainImage()} />
                                </div>
                            </FlexItem>
                            <FlexItem>
                                <H2 marginBottom="medium">{product.name}</H2>
                                <Box marginTop="large">
                                    <H2>FAQs</H2>
                                    {renderStatus()}
                                </Box>
                            </FlexItem>
                        </Flex>
                    </Box>
                    <Box marginTop="medium">
                        <Flex id="product-description">
                            <FlexItem flexBasis="100%" flexGrow={1} margin="small">
                                <Box border="box" padding="medium" backgroundColor="secondary20">
                                    {product.faq &&
                                        <>
                                            {!editingFAQ &&
                                                <>
                                                    <div className="body">
                                                        {product.faq.items.map(i =>
                                                            <div>
                                                                <p className="question">Q: {i.question}</p>
                                                                <p className="answer">A: {i.answer}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {product.faq.status !== "not-published" &&
                                                        <div className="dates">
                                                            {product.faq.updated &&
                                                                <>
                                                                    <small>Updated: {new Date(product.faq.updated._seconds * 1000).toLocaleString()}</small><br />
                                                                </>
                                                            }
                                                            {product.faq.published &&
                                                                <>
                                                                    <small>Published: {new Date(product.faq.published._seconds * 1000).toLocaleString()}</small><br />
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                    <Flex flexDirection="column">
                                                        <FlexItem alignSelf="center">
                                                        </FlexItem>
                                                        <FlexItem alignSelf="center">
                                                            <Button
                                                                type="button"
                                                                variant="secondary"
                                                                disabled={updatingFAQ}
                                                                onClick={() => {
                                                                    setEditingFAQ(true);
                                                                    setTempItems([...product.faq.items]);
                                                                }}>Edit</Button>
                                                            {product.faq.status !== "published" &&
                                                                <Button
                                                                    type="button"
                                                                    variant="primary"
                                                                    isLoading={updatingFAQ}
                                                                    onClick={() => publishFAQ(true)}>
                                                                        {product.faq.status === "outdated" ? "Publish Updates" : "Publish"}
                                                                    </Button>
                                                            }
                                                            {product.faq.status === "published" &&
                                                                <Button
                                                                    type="button"
                                                                    variant="secondary"
                                                                    actionType="destructive"
                                                                    isLoading={updatingFAQ}
                                                                    onClick={unpublishFAQ}>Unpublish</Button>
                                                            }
                                                        </FlexItem>
                                                    </Flex>
                                                </>
                                            }
                                            {editingFAQ &&
                                                <>
                                                    <Flex flexDirection="column">
                                                        <FlexItem>
                                                            <div className="body">
                                                                {tempItems.map((item, i) =>
                                                                    <Panel
                                                                        marginBottom="medium">
                                                                        <Flex flexGap={"8px"} flexDirection="column">
                                                                            <FlexItem>
                                                                                <H4>Q{i + 1}.</H4>
                                                                                <Input onChange={(e) => {
                                                                                    tempItems[i] = {
                                                                                        ...tempItems[i],
                                                                                        question: e.target.value,
                                                                                    };
                                                                                    setTempItems([...tempItems]);
                                                                                }}
                                                                                    value={item.question}></Input>
                                                                            </FlexItem>
                                                                            <FlexItem>
                                                                                <Textarea onChange={(e) => {
                                                                                    tempItems[i] = {
                                                                                        ...tempItems[i],
                                                                                        answer: e.target.value,
                                                                                    };
                                                                                    setTempItems([...tempItems]);
                                                                                }}
                                                                                    value={item.answer}></Textarea>
                                                                            </FlexItem>
                                                                            <FlexItem alignSelf="flex-end">
                                                                                {i !== 0 &&
                                                                                    <Button
                                                                                        onClick={(e) => {
                                                                                            const itemUp = tempItems[i - 1];
                                                                                            const item = tempItems[i];
                                                                                            tempItems[i - 1] = item;
                                                                                            tempItems[i] = itemUp;
                                                                                            setTempItems([...tempItems]);
                                                                                        }}
                                                                                        title="move up"
                                                                                        variant="subtle"
                                                                                        iconOnly={<ArrowUpwardIcon></ArrowUpwardIcon>}></Button>
                                                                                }
                                                                                {i !== tempItems.length - 1 &&
                                                                                    <Button
                                                                                        onClick={(e) => {
                                                                                            const itemDown = tempItems[i + 1];
                                                                                            const item = tempItems[i];
                                                                                            tempItems[i] = itemDown;
                                                                                            tempItems[i + 1] = item;
                                                                                            setTempItems([...tempItems]);
                                                                                        }}
                                                                                        title="move down"
                                                                                        variant="subtle"
                                                                                        iconOnly={<ArrowDownwardIcon></ArrowDownwardIcon>}></Button>
                                                                                }
                                                                                <Button
                                                                                    onClick={(e) => {
                                                                                        tempItems.splice(i, 1);
                                                                                        setTempItems([...tempItems]);
                                                                                    }}
                                                                                    title="remove"
                                                                                    variant="subtle"
                                                                                    actionType="destructive"
                                                                                    iconOnly={<DeleteIcon></DeleteIcon>}></Button>
                                                                            </FlexItem>
                                                                        </Flex>
                                                                    </Panel>
                                                                )}
                                                            </div>
                                                        </FlexItem>
                                                        <FlexItem alignSelf="flex-end">
                                                            <Button
                                                                type="button"
                                                                variant="secondary"
                                                                iconLeft={<AddIcon></AddIcon>}
                                                                disabled={updatingFAQ}
                                                                onClick={() => {
                                                                    setTempItems([...tempItems, { question: "", answer: "" }])
                                                                }}>Add New</Button>
                                                        </FlexItem>
                                                        <FlexItem alignSelf="center">
                                                            <Button
                                                                type="button"
                                                                variant="secondary"
                                                                disabled={updatingFAQ}
                                                                onClick={() => setEditingFAQ(false)}>Cancel</Button>
                                                            <Button
                                                                type="button"
                                                                variant="primary"
                                                                isLoading={updatingFAQ}
                                                                onClick={updateFAQ}>Apply</Button>
                                                        </FlexItem>
                                                    </Flex>
                                                </>
                                            }
                                            <Dialog
                                                header="Publish FAQs"
                                                text="FAQs will be published on your BigCommerce Store."
                                                onOk={() => {
                                                    setPublishingFAQ(false);
                                                    publishFAQ();
                                                }}
                                                onCancel={() => setPublishingFAQ(false)}
                                                isOpen={publishingFAQ}
                                            ></Dialog>
                                        </>
                                    }
                                    {(!product.faq) &&
                                        <>
                                            <Small>FAQ not yet generated</Small>
                                            <Button type="button" isLoading={generatingFAQ} onClick={generateFAQ}>Generate</Button>
                                        </>
                                    }
                                    {(product.faq && !editingFAQ) &&
                                        <>
                                            <Button type="button" isLoading={generatingFAQ} onClick={generateFAQ}>Re-Generate</Button>
                                        </>
                                    }
                                </Box>
                            </FlexItem>
                        </Flex>
                    </Box>
                </>
            }
            {
                loading &&
                <ComponentLoading></ComponentLoading>
            }
        </div >
    );
}

export default FAQDetail;
