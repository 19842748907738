export const AVAILABLE_PLANS = [
    {
      id: "FREE",
      name: "Starter",
      cost: "Free",
      quota: "10 descriptions",
      features: [
        "10 descriptions (one-time)",
        "Automated/Manual Trigger",
        "Product Description Generation",
        "Meta Description Generation",
        "Backups of Original Descriptions"
      ]
    },
    {
      id: "811515",
      name: "Basic",
      cost: "$9.99/month",
      quota: "100 descriptions",
      features: [
        "100 descriptions/month",
        "Automated/Manual Trigger",
        "Product Description Generation",
        "Meta Description Generation",
        "Backups of Original Descriptions"
      ]
    },
    {
      id: "811516",
      name: "Advanced",
      cost: "$24.99/month",
      quota: "500 descriptions/month",
      features: [
        "500 descriptions/month",
        "Automated/Manual Trigger",
        "Product Description Generation",
        "Meta Description Generation",
        "Backups of Original Descriptions"
      ]
    },
    {
      id: "811517",
      name: "Premium",
      cost: "$49.99/month",
      quota: "2500 descriptions/month",
      features: [
        "2500 descriptions/month",
        "Automated/Manual Trigger",
        "Product Description Generation",
        "Meta Description Generation",
        "Backups of Original Descriptions"
      ]
    }
  ];
export const ADDITIONAL_QUOTA_PRODUCT = "817118";

export const ADDITIONAL_QUOTA_PRODUCTS = [
  {
    id: "817118",
    name: "$49.99 per 2500 content"
  },
  {
    id: "828585",
    name: "$24.99 per 500 content"
  },
  {
    id: "828588",
    name: "$9.99 per 100 content"
  },
];

//export const TINY_MCE_KEY = "vf4dacsev2qnxohfxfr4g115q1k3d63bxeqkdqmvjf8x31nu";
export const TINY_MCE_KEY = "gpl";
