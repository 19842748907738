import React, { useState, useEffect } from 'react';
import { Api } from '../services/Api';
import { ArrowBackIcon } from '@bigcommerce/big-design-icons';
import { H1, Text, Button, Modal, Flex, FlexItem, Small } from '@bigcommerce/big-design';
import { AVAILABLE_PLANS } from '../common/Constants';
import { useMatch, Link as RouterLink } from 'react-router-dom';
import Plan from '../components/Plan';
import PageLoading from '../components/PageLoading';
import { StoreClient } from '../services/StoreClient';

const SelectPlan = (props) => {
    const [plans, setPlans] = useState([]);
    const [store, setStore] = useState(props.store);
    const [selectedPlan, setSelectedPlan] = useState();
    const [currentPlan, setCurrentPlan] = useState();
    const [subscription, setSubscription] = useState({});
    const [pageLoading, setPageLoading] = useState(true);
    const [upgrading, setUpgrading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(false);
    const [dialogText, setDialogText] = useState(false);
    const [dialogOkFn, setDialogOkFn] = useState();
    const Paddle = window.Paddle;

    // button states
    const [selecting, setSelecting] = useState(false);
    const [pausing, setPausing] = useState(false);

    let match = useMatch("/upgrade-plan");

    useEffect(() => {
        if (match) {
            setUpgrading(true);
            let paidPlans = AVAILABLE_PLANS.filter(p => p.id !== "FREE");
            setPlans(paidPlans);
            async function loadPage() {
                await getStore();
                setPageLoading(false);
            }
            loadPage();
        }
        else {
            setPlans(AVAILABLE_PLANS);
            setPageLoading(false);
        }
    }, [])

    const getStore = async () => {
        const store = await StoreClient.getStore();
        const plan = AVAILABLE_PLANS.find(p => p.id === store.subscription.planId);
        setCurrentPlan(plan);
        setSubscription(store.subscription);
        setStore(store);
    }

    const handlePlanSelection = (e) => {
        const plan = e.plan;
        const action = e.action;

        setSelectedPlan(plan.id);

        if (action === "select") {
            if (!upgrading && plan.id !== "FREE") {
                purchase(plan.id);
            }
            else if (!upgrading && plan.id === "FREE") {
                updatePlan(plan.id, false);
            }
            else if (upgrading) {
                if (currentPlan.id === "FREE") {
                    // Upgrade from free to a paid plan
                    purchase(plan.id);
                }
                else {
                    // Upgrade or downgrade a paid subscription
                    if (plan.id < currentPlan.id) {
                        const newPlan = AVAILABLE_PLANS.find(p => p.id === plan.id);
                        setDialogTitle("Are you sure you want to downgrade?");
                        setDialogText(`You are downgrading from ${currentPlan.name} to ${newPlan.name}. Your quota will drop from ${currentPlan.quota} to ${newPlan.quota}.`);
                        setIsDialogOpen(true);
                        setDialogOkFn({
                            fn: () => {
                                updatePlan(plan.id, true);
                            }
                        }
                        );
                    }
                    else {
                        updatePlan(plan.id, true);
                    }
                }
            }
        }
        else if (action === "restart") {
            restartSubscription();
        }
    }

    const purchase = (planId) => {
        let email = "";
        if (store.users && store.users.length > 0) {
            email = store.users[0].email;
        }
        Paddle.Checkout.open({
            product: planId,
            email: email,
            passthrough: store.storeHash,
            successCallback: (data) => {
                updatePlan(planId, false);
            }
        });
    }

    const updatePlan = async (planId, confirm) => {
        if (confirm) {
            confirmPlanChange(planId);
        }
        else {
            setSelecting(true);
            try {
                await Api.post("/subscriptionApi-setPlan", {
                    planId: planId
                })
                await getStore();
            }
            finally {
                setSelecting(false);
                if (props.onPlanSelected) {
                    props.onPlanSelected(planId);
                }
            }
        }
    }

    const confirmPlanChange = async (planId) => {
        try {
            setSelecting(true);
            const preview = await Api.post("/subscriptionApi-previewPlanChange", {
                planId: planId
            });
            setDialogTitle("Please confirm your plan update");
            setDialogText(preview);
            setIsDialogOpen(true);
            setDialogOkFn({
                fn: () => {
                    updatePlan(planId, false);
                }
            });
        }
        finally {
            setSelecting(false);
        }
    }

    const handlePauseSubscription = () => {
        setDialogTitle("Are you sure you want to pause your subscription?");
        setDialogText(`You will still be able to use your remaining quota until the end of active billing period.`);
        setIsDialogOpen(true);
        setDialogOkFn({
            fn: () => {
                pauseSubscription();
            }
        }
        );
    }

    const pauseSubscription = async () => {
        setPausing(true);
        try {
            await Api.post("/subscriptionApi-pauseSubscription");
            subscription.status = "paused";
            setSubscription(subscription);
        }
        finally {
            setPausing(false);
        }
    }

    const restartSubscription = async () => {
        setSelecting(true);
        try {
            await Api.post("/subscriptionApi-restartSubscription");
            subscription.status = "active";
            setPageLoading(false);
            setSubscription(subscription);
        }
        finally {
            setSelecting(false);
        }
    }

    const isPlanDisabled = (plan) => {
        if (subscription.status === "paused") {
            return !(plan.id === currentPlan.id);
        }
        else if (selecting) {
            return selectedPlan !== plan.id;
        }
        else if (pausing) {
            return true;
        }
        else {
            if (upgrading) {
                return currentPlan === undefined || plan.id === currentPlan.id;
            }
            else {
                return false;
            }
        }
    }

    const getPlanButtonText = (plan) => {
        if (subscription.status === "paused" && plan.id === currentPlan.id) {
            return "Restart Plan";
        }
        else {
            return "Select";
        }
    }

    return (
        <>
            {pageLoading &&
                <PageLoading></PageLoading>
            }
            {!pageLoading &&
                <>
                    {!upgrading &&
                        <H1>Select your plan</H1>
                    }
                    {upgrading &&
                        <>
                            <RouterLink to="/" className="no-underline"><Text color="secondary60"><ArrowBackIcon color="secondary60" /> Home</Text></RouterLink>
                            <H1>Upgrade your plan</H1>
                        </>
                    }
                    <Flex justifyContent="flex-start" flexWrap="wrap">
                        {plans.length > 0 && plans.map((plan) =>
                            <FlexItem key={plan.id} marginRight="medium">
                                <Plan
                                    plan={plan}
                                    paused={subscription.status === "paused" && currentPlan.id === plan.id}
                                    active={currentPlan && subscription.status !== "paused" && currentPlan.id === plan.id}
                                    disabled={isPlanDisabled(plan)}
                                    text={getPlanButtonText(plan)}
                                    processing={selecting && selectedPlan === plan.id}
                                    onSelected={(e) => handlePlanSelection(e)}
                                ></Plan>
                            </FlexItem>
                        )}
                    </Flex>
                    <Small>Prices are in US Dollars and exclude all forms of tax (US Sales, GST, VAT)</Small>
                    {upgrading && currentPlan && currentPlan.id !== "FREE" && subscription.status !== "paused" && false &&
                        <>
                            or <Button variant="subtle" isLoading={pausing} onClick={() => handlePauseSubscription()}>Pause Subscription</Button>
                        </>
                    }
                    <Modal
                        actions={[
                            { text: 'Cancel', variant: 'subtle', onClick: () => setIsDialogOpen(false) },
                            { text: 'Apply', onClick: () => { setIsDialogOpen(false); dialogOkFn.fn() } },
                        ]}
                        header={dialogTitle}
                        isOpen={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        closeOnEscKey={true}
                        closeOnClickOutside={false}
                    >
                        <Text>
                            {dialogText}
                        </Text>
                    </Modal>
                </>
            }
        </>
    );
}

export default SelectPlan;
