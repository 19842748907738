import { Box, Button, Checkbox, Form, FormGroup, Modal, Panel, Select, Switch, Text } from "@bigcommerce/big-design";
import { useEffect, useRef, useState } from "react";
import { Api } from "../../services/Api";
import { CheckIcon, CloseIcon, BaselineHelpIcon } from '@bigcommerce/big-design-icons';
import "./FAQSettings.css";
import { StoreClient } from "../../services/StoreClient";

const FAQSettings = ({ store }) => {
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const [storefronts, setStorefronts] = useState([]);
    const originalSettings = useRef(null);

    const getSetting = (setting, defaultValue) => {
        const returnVal = store.settings && store.settings.faqWriter && store.settings.faqWriter[setting] !== undefined ? store.settings.faqWriter[setting] : defaultValue;
        return returnVal;
    };

    const [autoPilot, setAutoPilot] = useState(getSetting("autoPilot", false));
    // const [visibleOnStorefront, setVisibleOnStorefront] = useState(false);    
    // const [generateRichResultsMarkup, setGenerateRichResultsMarkup] = useState(false);
    const [storefrontSettings, setStorefrontSettings] = useState(getSetting("storefrontSettings", []));
    const [selectedStorefrontSettings, setSelectedStorefrontSettings] = useState();
    const [selectedStorefront, setSelectedStorefront] = useState(1);


    useEffect(() => {
        originalSettings.current = getCurrentSettings();
    }, []);

    const getCurrentSettings = () => {
        const arr = [];
        for (let el of storefrontSettings) {
            arr.push({ ...el });
        }
        return {
            autoPilot: autoPilot,
            storefrontSettings: arr
        };
    }

    useEffect(() => {
        async function fetchData() {
            //setLoading(true);
            try {
                const data = await StoreClient.getStorefronts();
                setStorefronts(data);
                for (const storefront of data) {
                    const storefrontSetting = storefrontSettings.find(s => s.id === storefront.id);
                    if (!storefrontSetting) {
                        storefrontSettings.push({
                            id: storefront.id,
                            visibleOnStorefront: false,
                            generateRichResultsMarkup: false
                        });
                        setStorefrontSettings(storefrontSettings);
                        originalSettings.current = getCurrentSettings();
                        setSelectedStorefront(1);
                        setSelectedStorefrontSettings(originalSettings.current.storefrontSettings[0]);
                    }
                }
            }
            finally {
                //setLoading(false);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        let modified = originalSettings.current.autoPilot !== autoPilot;

        if (!modified) {
            for (let i = 0; i < storefrontSettings.length; i++) {
                const storefrontSetting = storefrontSettings[i];
                modified = modified ||
                    storefrontSetting.visibleOnStorefront !== originalSettings.current.storefrontSettings[i].visibleOnStorefront ||
                    storefrontSetting.generateRichResultsMarkup !== originalSettings.current.storefrontSettings[i].generateRichResultsMarkup;
                if (modified) {
                    break;
                }
            }
        }
        setChanged(modified);
    }, [
        autoPilot, storefrontSettings
    ]);

    useEffect(() => {
        const activeStorefrontSettings = storefrontSettings.find(s => s.id === selectedStorefront);
        setSelectedStorefrontSettings(activeStorefrontSettings);
        // setGenerateRichResultsMarkup(activeStorefrontSettings ? activeStorefrontSettings.generateRichResultsMarkup : false);
        // setVisibleOnStorefront(activeStorefrontSettings ? activeStorefrontSettings.visibleOnStorefront : false);
    }, [selectedStorefront]);

    useEffect(() => {
        if (selectedStorefrontSettings) {
            const i = storefrontSettings.findIndex(s => s.id === selectedStorefront);
            storefrontSettings[i] = selectedStorefrontSettings;
            setStorefrontSettings([...storefrontSettings]);
        }
    }, [selectedStorefrontSettings]);

    // useEffect(() => {
    //     const activeStorefrontSettings = storefrontSettings.find(s => s.id === selectedStorefront);
    //     activeStorefrontSettings.generateRichResultsMarkup = generateRichResultsMarkup;
    //     setStorefrontSettings([...storefrontSettings]);
    // }, [generateRichResultsMarkup]);

    const submitChanges = async () => {
        setUpdating(true);
        try {
            await Api.post("/storeApi-updateFAQWriterSettings2", {
                autoPilot: autoPilot,
                storefrontSettings: storefrontSettings
                // visibleOnStorefront: visibleOnStorefront,
                // generateRichResultsMarkup: generateRichResultsMarkup
            });
            originalSettings.current = getCurrentSettings();
            setChanged(false);
        }
        finally {
            setUpdating(false);
        }
    }

    const cancelChanges = async () => {
        // rollback changes
        setAutoPilot(originalSettings.current.autoPilot);
        setStorefrontSettings(originalSettings.current.storefrontSettings);
        setSelectedStorefront(1);
        setSelectedStorefrontSettings(originalSettings.current.storefrontSettings[0]);
        setChanged(false);
    }

    return (
        <Panel
            header="FAQ Writer Settings"
            action={
                {
                    variant: 'subtle',
                    iconOnly: <BaselineHelpIcon color="secondary"></BaselineHelpIcon>,
                    onClick: () => {
                        setHelpDialogOpen(true);
                    },
                }
            }
        >
            <div className="faq-settings">
                <Form>
                    <FormGroup>
                        <div className="chk-group">
                            <Switch checked={autoPilot} onChange={(e) => setAutoPilot(!autoPilot)} />
                            <Text>Auto-Pilot</Text>
                        </div>
                    </FormGroup>
                    {storefronts && storefronts.length > 1 &&
                        <FormGroup>
                            <Select
                                filterable={false}
                                label="Storefront"
                                maxHeight={300}
                                onOptionChange={(e) => {
                                    setSelectedStorefront(e);
                                }}
                                options={
                                    storefronts.map((s) => {
                                        return {
                                            value: s.id,
                                            content: s.name
                                        };
                                    })}
                                placement="bottom-start"
                                required
                                value={selectedStorefront}
                            />
                        </FormGroup>
                    }
                    {selectedStorefrontSettings &&
                        <>
                            <FormGroup>
                                <div className="chk-group">
                                    <Switch checked={selectedStorefrontSettings.visibleOnStorefront} onChange={(e) => {
                                        setSelectedStorefrontSettings({
                                            ...selectedStorefrontSettings,
                                            visibleOnStorefront: !selectedStorefrontSettings.visibleOnStorefront
                                        });
                                    }
                                    } />
                                    <Text>Display FAQs on Storefront</Text>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="chk-group">
                                    <Switch checked={selectedStorefrontSettings.generateRichResultsMarkup} onChange={(e) => {
                                        setSelectedStorefrontSettings({
                                            ...selectedStorefrontSettings,
                                            generateRichResultsMarkup: !selectedStorefrontSettings.generateRichResultsMarkup
                                        });
                                    }
                                    } />
                                    <Text>Generate Rich Results Markup</Text>
                                </div>
                            </FormGroup>
                        </>
                    }
                    <div className="buttons">
                        <Button onClick={cancelChanges} disabled={!changed || updating} type="button" actionType="destructive" variant="secondary" iconRight={<CloseIcon></CloseIcon>}>Cancel</Button>
                        <Button isLoading={updating} onClick={submitChanges} disabled={!changed} type="button" variant="secondary" iconRight={<CheckIcon></CheckIcon>}>Save</Button>
                    </div>
                    <Box marginTop="medium">
                        <div style={{ maxWidth: "400px" }}>
                            <small>*If you would like the FAQ to appear in your storefront, please send a message to the support team. They will configure the FAQ section for your storefront theme. </small>
                        </div>
                    </Box>
                </Form>
            </div>
            <Modal
                actions={[
                    { text: 'Ok', variant: 'subtle', onClick: () => setHelpDialogOpen(false) }
                ]}
                header="Help"
                isOpen={helpDialogOpen}
                onClose={() => setHelpDialogOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <Box marginBottom="medium">
                    <strong>Display on Storefront</strong><br />
                    If you would like the FAQ to appear in your storefront, please send a message to the support team. They will configure the FAQ section for your storefront theme.
                </Box>
                <Box marginBottom="medium">
                    <strong>Generate Rich Results Markup</strong><br />
                    Google uses structured data to understand the content on the page and show that content in a richer appearance in search results, which is called a rich result. If this setting is on, the app will also generate structured data for rich search results.
                </Box>
                <Box>
                    <strong>Auto-Pilot</strong><br />
                    <Text>If you switch the <strong>Auto-Pilot</strong> on, the app will automatically select products and start generating content based on your <strong>Auto-Pilot settings</strong>.</Text>
                    <Text>The alternative to Auto-Pilot is  <strong>"manual selection"</strong>:
                        <ul>
                            <li>You can manually trigger batch content generation by selecting multiple products and then clicking <strong>"Batch Generate Content"</strong> button.</li>
                            <li>You can also click on a single product name to open a dialog that gives you more <i>granular control</i> over content generation.</li>
                        </ul>
                    </Text>
                </Box>
            </Modal>
        </Panel>
    )
}
export default FAQSettings;
