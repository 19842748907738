import React, { useEffect, useState } from 'react';
import { Box, Panel, Flex, FlexItem, Tabs } from '@bigcommerce/big-design';
import { StoreClient } from '../services/StoreClient';
import SocialMediaPosts from '../components/social-media-writer/SocialMediaPosts';
import SocialMediaSettings from '../components/social-media-writer/SocialMediaSettings';

const SocialMediaWriter = (props) => {
    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const store = await StoreClient.getStore();
                setStore(store);
            }
            catch {
                // setError("Unable to authenticate BigCommerce store");
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {store &&
                <>
                    <Flex>
                        {/* <FlexItem flexOrder={2} flexGrow={1} flexShrink={4} margin="small">
                            <SocialMediaSettings store={store}></SocialMediaSettings>
                        </FlexItem> */}
                        <FlexItem flexOrder={1} flexGrow={4} flexShrink={1} margin="small">
                            <Panel>
                                <Tabs
                                    activeTab="tab-ai-generated-social-media-posts"
                                    aria-label="Social Media Tab Content"
                                    id="tab"
                                    items={[
                                        { id: "tab-ai-generated-social-media-posts", ariaControls: "ai-generated-social-media-posts", title: "AI-Generated Social Media Posts" },
                                    ]}
                                />
                                <Box marginTop="medium" border="none" backgroundColor="transparent"></Box>
                                <SocialMediaPosts></SocialMediaPosts>
                            </Panel>
                        </FlexItem>
                    </Flex>
                </>
            }
        </>
    );
}

export default SocialMediaWriter;
