import React from 'react';
import { H1, Panel, Text, Button, Badge } from '@bigcommerce/big-design';
import { CheckIcon } from '@bigcommerce/big-design-icons';

const Plan = (props) => {
  
    const handlePlanSelection = () => {
        const action = props.text === "Select" ? "select" : "restart";
        if (props.onSelected) {
            props.onSelected({ plan: props.plan, action: action });
        }
    }

    return (
        <Panel header={props.plan.name}>
            {props.paused &&
                <Badge label="paused" variant="warning"></Badge>
            }
            {props.active &&
                <Badge label="active" variant="success"></Badge>
            }
            <H1>{props.plan.cost}</H1>
            {props.plan.features.map((feature, index) =>
                <Text key={index}><CheckIcon color="success" size="medium"></CheckIcon> {feature}</Text>
            )}
            <Button isLoading={props.processing} disabled={props.disabled} onClick={() => handlePlanSelection()}>{props.text}</Button>
        </Panel>
    );
}

export default Plan;
